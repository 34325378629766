@charset "UTF-8";
/*!
 * Bootstrap v4.5.0 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
a, .btn-link {
  color: #8FC323; }
  a:hover, .btn-link:hover {
    color: #6f981b;
    text-decoration: underline; }

.text-main a {
  color: #222222;
  text-decoration: underline; }
  .text-main a:hover {
    text-decoration: none; }

.tel a,
.text-main .tel a {
  text-decoration: none;
  color: #222222; }

.text-accent-01 a {
  color: #8FC323; }

.text-accent-02 a {
  color: #0075BF; }

.text-accent-03 a {
  color: #ECF9FF; }

.text-color-01 a {
  color: #F5F5F5; }

.text-color-02 a {
  color: #CCCCCC; }

.text-color-03 a {
  color: #9CCC65; }

.btn-instagram {
  color: #fff !important;
  background: linear-gradient(-183deg, #a512e6 0%, #faa55d 100%);
  border: none !important;
  transition: box-shadow 0.3s ease; }
  .btn-instagram:hover {
    color: #fff;
    box-shadow: 0px 0px 3rem 0px rgba(0, 0, 0, 0.3) inset; }

.bg-grad-01 {
  background: linear-gradient(96deg, #0089ff 0%, #00baff 48%, #7be1ff 100%); }

.bg-grad-02 {
  background: linear-gradient(180deg, #0089ff 0%, #00baff 48%, #7be1ff 100%); }

.btn {
  border-radius: 50rem !important; }
  .btn a {
    font-weight: bold;
    letter-spacing: .1em; }
  .btn.btn-white {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1); }
    .btn.btn-white a {
      color: #222222 !important; }
    .btn.btn-white:hover {
      background-color: #222222; }
      .btn.btn-white:hover a {
        color: #fff !important; }
  .btn.btn-trial {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1); }

.btn-white.__arr a {
  position: relative;
  padding-left: 25px;
  padding-right: 35px; }
  .btn-white.__arr a::after {
    transition: all .2s;
    content: "";
    position: absolute;
    right: 15px;
    top: calc(50% - 4px);
    width: 10px;
    height: 10px;
    border-top: 2px solid #222222;
    border-right: 2px solid #222222;
    transform: rotate(45deg); }
  .btn-white.__arr a:hover::after {
    border-top-color: #fff;
    border-right-color: #fff; }

.bg-accent-01 .btn.btn-white a {
  color: #8FC323 !important; }

.bg-accent-01 .btn.btn-white:hover a {
  color: #fff !important; }

.arrow-link-before-main a::before {
  content: '';
  position: relative;
  top: 50%;
  margin-top: -4px;
  display: inline-block;
  width: 8px;
  height: 8px;
  min-width: 8px;
  margin-right: 0.5rem;
  border-top: 2px solid #222222;
  border-right: 2px solid #222222;
  transform: rotate(45deg); }

.arrow-link-before-accent-01 a::before {
  content: '';
  position: relative;
  top: 50%;
  margin-top: -4px;
  display: inline-block;
  width: 8px;
  height: 8px;
  min-width: 8px;
  margin-right: 0.5rem;
  border-top: 2px solid #8FC323;
  border-right: 2px solid #8FC323;
  transform: rotate(45deg); }

.arrow-link-before-accent-02 a::before {
  content: '';
  position: relative;
  top: 50%;
  margin-top: -4px;
  display: inline-block;
  width: 8px;
  height: 8px;
  min-width: 8px;
  margin-right: 0.5rem;
  border-top: 2px solid #0075BF;
  border-right: 2px solid #0075BF;
  transform: rotate(45deg); }

.arrow-link-before-accent-03 a::before {
  content: '';
  position: relative;
  top: 50%;
  margin-top: -4px;
  display: inline-block;
  width: 8px;
  height: 8px;
  min-width: 8px;
  margin-right: 0.5rem;
  border-top: 2px solid #ECF9FF;
  border-right: 2px solid #ECF9FF;
  transform: rotate(45deg); }

.arrow-link-after-main a::after {
  content: '';
  position: relative;
  top: 50%;
  margin-top: -4px;
  display: inline-block;
  width: 8px;
  height: 8px;
  min-width: 8px;
  margin-right: 0.5rem;
  border-top: 2px solid #222222;
  border-right: 2px solid #222222;
  transform: rotate(45deg); }

.arrow-link-after-accent-01 a::after {
  content: '';
  position: relative;
  top: 50%;
  margin-top: -4px;
  display: inline-block;
  width: 8px;
  height: 8px;
  min-width: 8px;
  margin-right: 0.5rem;
  border-top: 2px solid #8FC323;
  border-right: 2px solid #8FC323;
  transform: rotate(45deg); }

.arrow-link-after-accent-02 a::after {
  content: '';
  position: relative;
  top: 50%;
  margin-top: -4px;
  display: inline-block;
  width: 8px;
  height: 8px;
  min-width: 8px;
  margin-right: 0.5rem;
  border-top: 2px solid #0075BF;
  border-right: 2px solid #0075BF;
  transform: rotate(45deg); }

.arrow-link-after-accent-03 a::after {
  content: '';
  position: relative;
  top: 50%;
  margin-top: -4px;
  display: inline-block;
  width: 8px;
  height: 8px;
  min-width: 8px;
  margin-right: 0.5rem;
  border-top: 2px solid #ECF9FF;
  border-right: 2px solid #ECF9FF;
  transform: rotate(45deg); }

.btn-arrow-link a {
  display: flex;
  align-items: center;
  justify-content: left; }
  .btn-arrow-link a::before {
    content: '';
    position: relative;
    top: 50%;
    margin-top: 0px;
    display: inline-block;
    width: 8px;
    height: 8px;
    min-width: 8px;
    margin-right: 0.5rem;
    border-top: 2px solid #8FC323;
    border-right: 2px solid #8FC323;
    transform: rotate(45deg);
    position: static; }

.btn-arrow-link.justify-content-center a {
  justify-content: center; }

.arrow-link a {
  display: inline-block;
  padding-left: 1rem;
  text-indent: -1rem; }
  .arrow-link a::before {
    content: '';
    position: relative;
    top: 50%;
    margin-top: -2px;
    display: inline-block;
    width: 8px;
    height: 8px;
    min-width: 8px;
    margin-right: 0.5rem;
    border-top: 2px solid #222222;
    border-right: 2px solid #222222;
    transform: rotate(45deg);
    vertical-align: middle; }

.btn.btn-accent-01 a:hover {
  color: #8FC323 !important; }

.btn.btn-accent-02 a:hover {
  color: #0075BF !important; }

.btn.btn-arrow-before-main a::before {
  content: '';
  position: relative;
  top: 50%;
  margin-top: -4px;
  display: inline-block;
  width: 8px;
  height: 8px;
  min-width: 8px;
  margin-right: 0.5rem;
  border-top: 2px solid #222222;
  border-right: 2px solid #222222;
  transform: rotate(45deg);
  margin-left: 0.5rem;
  left: -0.5rem; }

.btn.btn-arrow-before-main.small a::before {
  margin-right: 0.5rem;
  left: -0.25rem; }

.btn.btn-arrow-after-main a {
  position: relative;
  padding-right: 46px;
  padding-left: 46px; }
  .btn.btn-arrow-after-main a::after {
    content: '';
    position: relative;
    top: 50%;
    margin-top: -4px;
    display: inline-block;
    width: 8px;
    height: 8px;
    min-width: 8px;
    margin-right: 0.5rem;
    border-top: 2px solid #222222;
    border-right: 2px solid #222222;
    transform: rotate(45deg);
    margin-right: -0.5rem;
    position: absolute;
    right: 24px; }

.btn.btn-arrow-after-main.small a::after {
  margin-right: -0.5rem;
  left: 0.25rem; }

.btn.btn-arrow-after-main.btn-white:hover {
  background-color: #222222; }
  .btn.btn-arrow-after-main.btn-white:hover a {
    color: #ffffff !important; }
    .btn.btn-arrow-after-main.btn-white:hover a::after {
      border-color: #ffffff; }

.btn.btn-arrow-after-white a {
  position: relative;
  padding-right: 26px;
  padding-left: 26px; }
  .btn.btn-arrow-after-white a::after {
    content: '';
    position: relative;
    top: 50%;
    margin-top: -4px;
    display: inline-block;
    width: 8px;
    height: 8px;
    min-width: 8px;
    margin-right: 0.5rem;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(45deg);
    margin-right: -0.5rem;
    position: absolute;
    right: 24px; }

.btn.btn-arrow-after-white.small a::after {
  margin-right: -0.5rem;
  left: 0.25rem; }

.btn.btn-arrow-after-white.btn-accent-01 a:hover::after {
  border-color: #8FC323 !important; }

.btn.btn-arrow-after-white.btn-accent-02 a:hover::after {
  border-color: #0075BF !important; }

.btn.btn-arrow-before-01 a::before {
  content: '';
  position: relative;
  top: 50%;
  margin-top: -4px;
  display: inline-block;
  width: 8px;
  height: 8px;
  min-width: 8px;
  margin-right: 0.5rem;
  border-top: 2px solid #8FC323;
  border-right: 2px solid #8FC323;
  transform: rotate(45deg);
  margin-left: -0.5rem;
  right: 0.5rem; }

.btn.btn-arrow-before-01 a:hover::after {
  border-color: #fff; }

.btn.btn-arrow-before-02 a::before {
  content: '';
  position: relative;
  top: 50%;
  margin-top: -4px;
  display: inline-block;
  width: 8px;
  height: 8px;
  min-width: 8px;
  margin-right: 0.5rem;
  border-top: 2px solid #0075BF;
  border-right: 2px solid #0075BF;
  transform: rotate(45deg);
  margin-left: -0.5rem;
  right: 0.5rem; }

.btn.btn-arrow-before-02 a:hover::after {
  border-color: #fff; }

.btn.btn-arrow-after-01 a::after {
  content: '';
  position: relative;
  top: 50%;
  margin-top: -4px;
  display: inline-block;
  width: 8px;
  height: 8px;
  min-width: 8px;
  margin-right: 0.5rem;
  border-top: 2px solid #8FC323;
  border-right: 2px solid #8FC323;
  transform: rotate(45deg);
  margin-right: -0.5rem;
  left: 0.5rem; }

.btn.btn-arrow-after-01 a:hover::after {
  border-color: #fff; }

.btn.btn-arrow-after-02 a::after {
  content: '';
  position: relative;
  top: 50%;
  margin-top: -4px;
  display: inline-block;
  width: 8px;
  height: 8px;
  min-width: 8px;
  margin-right: 0.5rem;
  border-top: 2px solid #0075BF;
  border-right: 2px solid #0075BF;
  transform: rotate(45deg);
  margin-right: -0.5rem;
  left: 0.5rem; }

.btn.btn-arrow-after-02 a:hover::after {
  border-color: #fff; }

.btn.btn-anchor-link {
  border-radius: 100vh;
  background-color: #fff; }
  .btn.btn-anchor-link a {
    padding-left: 12%;
    padding-right: 12%; }
    .btn.btn-anchor-link a::after {
      content: '';
      position: relative;
      top: 50%;
      margin-top: -4px;
      display: inline-block;
      width: 8px;
      height: 8px;
      min-width: 8px;
      margin-left: 0.5rem;
      border-top: 2px solid #222222;
      border-right: 2px solid #222222;
      transform: rotate(135deg);
      top: calc(50% - 2px);
      right: 10%;
      position: absolute; }
  .btn.btn-anchor-link:hover a::after {
    border-color: #fff !important; }

body {
  font-family: "Noto Sans JP", sans-serif;
  color: #333; }

ul {
  padding-inline-start: 20px; }

.shadow {
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.2s ease; }
  .shadow:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175); }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-main {
  color: #fff;
  background-color: #222222;
  border-color: #222222; }
  .btn-main:hover {
    color: #fff;
    background-color: #020202;
    border-color: black; }
  .btn-main:focus, .btn-main.focus {
    color: #fff;
    background-color: #020202;
    border-color: black;
    box-shadow: 0 0 0 0.2rem rgba(67, 67, 67, 0.5); }
  .btn-main.disabled, .btn-main:disabled {
    color: #fff;
    background-color: #222222;
    border-color: #222222; }
  .btn-main:not(:disabled):not(.disabled):active, .btn-main:not(:disabled):not(.disabled).active,
  .show > .btn-main.dropdown-toggle {
    color: #fff;
    background-color: #090909;
    border-color: #020202; }
    .btn-main:not(:disabled):not(.disabled):active:focus, .btn-main:not(:disabled):not(.disabled).active:focus,
    .show > .btn-main.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(67, 67, 67, 0.5); }

.btn-accent-01 {
  color: #fff;
  background-color: #8FC323;
  border-color: #8FC323; }
  .btn-accent-01:hover {
    color: #fff;
    background-color: #678d19;
    border-color: #5f8217; }
  .btn-accent-01:focus, .btn-accent-01.focus {
    color: #fff;
    background-color: #678d19;
    border-color: #5f8217;
    box-shadow: 0 0 0 0.2rem rgba(160, 204, 68, 0.5); }
  .btn-accent-01.disabled, .btn-accent-01:disabled {
    color: #fff;
    background-color: #8FC323;
    border-color: #8FC323; }
  .btn-accent-01:not(:disabled):not(.disabled):active, .btn-accent-01:not(:disabled):not(.disabled).active,
  .show > .btn-accent-01.dropdown-toggle {
    color: #fff;
    background-color: #6f981b;
    border-color: #678d19; }
    .btn-accent-01:not(:disabled):not(.disabled):active:focus, .btn-accent-01:not(:disabled):not(.disabled).active:focus,
    .show > .btn-accent-01.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(160, 204, 68, 0.5); }

.btn-accent-02 {
  color: #fff;
  background-color: #0075BF;
  border-color: #0075BF; }
  .btn-accent-02:hover {
    color: #fff;
    background-color: #004e7f;
    border-color: #004673; }
  .btn-accent-02:focus, .btn-accent-02.focus {
    color: #fff;
    background-color: #004e7f;
    border-color: #004673;
    box-shadow: 0 0 0 0.2rem rgba(38, 138, 201, 0.5); }
  .btn-accent-02.disabled, .btn-accent-02:disabled {
    color: #fff;
    background-color: #0075BF;
    border-color: #0075BF; }
  .btn-accent-02:not(:disabled):not(.disabled):active, .btn-accent-02:not(:disabled):not(.disabled).active,
  .show > .btn-accent-02.dropdown-toggle {
    color: #fff;
    background-color: #00568c;
    border-color: #004e7f; }
    .btn-accent-02:not(:disabled):not(.disabled):active:focus, .btn-accent-02:not(:disabled):not(.disabled).active:focus,
    .show > .btn-accent-02.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 138, 201, 0.5); }

.btn-accent-03 {
  color: #212529;
  background-color: #ECF9FF;
  border-color: #ECF9FF; }
  .btn-accent-03:hover {
    color: #212529;
    background-color: #ace5ff;
    border-color: #a0e1ff; }
  .btn-accent-03:focus, .btn-accent-03.focus {
    color: #212529;
    background-color: #ace5ff;
    border-color: #a0e1ff;
    box-shadow: 0 0 0 0.2rem rgba(206, 217, 223, 0.5); }
  .btn-accent-03.disabled, .btn-accent-03:disabled {
    color: #212529;
    background-color: #ECF9FF;
    border-color: #ECF9FF; }
  .btn-accent-03:not(:disabled):not(.disabled):active, .btn-accent-03:not(:disabled):not(.disabled).active,
  .show > .btn-accent-03.dropdown-toggle {
    color: #212529;
    background-color: #b9e9ff;
    border-color: #ace5ff; }
    .btn-accent-03:not(:disabled):not(.disabled):active:focus, .btn-accent-03:not(:disabled):not(.disabled).active:focus,
    .show > .btn-accent-03.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(206, 217, 223, 0.5); }

.btn-color-01 {
  color: #212529;
  background-color: #F5F5F5;
  border-color: #F5F5F5; }
  .btn-color-01:hover {
    color: #212529;
    background-color: #d5d5d5;
    border-color: #cfcfcf; }
  .btn-color-01:focus, .btn-color-01.focus {
    color: #212529;
    background-color: #d5d5d5;
    border-color: #cfcfcf;
    box-shadow: 0 0 0 0.2rem rgba(213, 214, 214, 0.5); }
  .btn-color-01.disabled, .btn-color-01:disabled {
    color: #212529;
    background-color: #F5F5F5;
    border-color: #F5F5F5; }
  .btn-color-01:not(:disabled):not(.disabled):active, .btn-color-01:not(:disabled):not(.disabled).active,
  .show > .btn-color-01.dropdown-toggle {
    color: #212529;
    background-color: gainsboro;
    border-color: #d5d5d5; }
    .btn-color-01:not(:disabled):not(.disabled):active:focus, .btn-color-01:not(:disabled):not(.disabled).active:focus,
    .show > .btn-color-01.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(213, 214, 214, 0.5); }

.btn-color-02 {
  color: #212529;
  background-color: #CCCCCC;
  border-color: #CCCCCC; }
  .btn-color-02:hover {
    color: #fff;
    background-color: #acacac;
    border-color: #a6a6a6; }
  .btn-color-02:focus, .btn-color-02.focus {
    color: #fff;
    background-color: #acacac;
    border-color: #a6a6a6;
    box-shadow: 0 0 0 0.2rem rgba(178, 179, 180, 0.5); }
  .btn-color-02.disabled, .btn-color-02:disabled {
    color: #212529;
    background-color: #CCCCCC;
    border-color: #CCCCCC; }
  .btn-color-02:not(:disabled):not(.disabled):active, .btn-color-02:not(:disabled):not(.disabled).active,
  .show > .btn-color-02.dropdown-toggle {
    color: #fff;
    background-color: #b3b3b3;
    border-color: #acacac; }
    .btn-color-02:not(:disabled):not(.disabled):active:focus, .btn-color-02:not(:disabled):not(.disabled).active:focus,
    .show > .btn-color-02.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(178, 179, 180, 0.5); }

.btn-color-03 {
  color: #fff;
  background-color: #9CCC65;
  border-color: #9CCC65; }
  .btn-color-03:hover {
    color: #fff;
    background-color: #7db53c;
    border-color: #76ac39; }
  .btn-color-03:focus, .btn-color-03.focus {
    color: #fff;
    background-color: #7db53c;
    border-color: #76ac39;
    box-shadow: 0 0 0 0.2rem rgba(171, 212, 124, 0.5); }
  .btn-color-03.disabled, .btn-color-03:disabled {
    color: #fff;
    background-color: #9CCC65;
    border-color: #9CCC65; }
  .btn-color-03:not(:disabled):not(.disabled):active, .btn-color-03:not(:disabled):not(.disabled).active,
  .show > .btn-color-03.dropdown-toggle {
    color: #fff;
    background-color: #83bf3f;
    border-color: #7db53c; }
    .btn-color-03:not(:disabled):not(.disabled):active:focus, .btn-color-03:not(:disabled):not(.disabled).active:focus,
    .show > .btn-color-03.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(171, 212, 124, 0.5); }

.btn-gray-333 {
  color: #fff;
  background-color: #333;
  border-color: #333; }
  .btn-gray-333:hover {
    color: #fff;
    background-color: #131313;
    border-color: #0d0d0d; }
  .btn-gray-333:focus, .btn-gray-333.focus {
    color: #fff;
    background-color: #131313;
    border-color: #0d0d0d;
    box-shadow: 0 0 0 0.2rem rgba(82, 82, 82, 0.5); }
  .btn-gray-333.disabled, .btn-gray-333:disabled {
    color: #fff;
    background-color: #333;
    border-color: #333; }
  .btn-gray-333:not(:disabled):not(.disabled):active, .btn-gray-333:not(:disabled):not(.disabled).active,
  .show > .btn-gray-333.dropdown-toggle {
    color: #fff;
    background-color: #1a1a1a;
    border-color: #131313; }
    .btn-gray-333:not(:disabled):not(.disabled):active:focus, .btn-gray-333:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-333.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 82, 82, 0.5); }

.btn-gray-100 {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-gray-100:hover {
    color: #212529;
    background-color: #d3d9df;
    border-color: #cbd3da; }
  .btn-gray-100:focus, .btn-gray-100.focus {
    color: #212529;
    background-color: #d3d9df;
    border-color: #cbd3da;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-gray-100.disabled, .btn-gray-100:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-gray-100:not(:disabled):not(.disabled):active, .btn-gray-100:not(:disabled):not(.disabled).active,
  .show > .btn-gray-100.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-gray-100:not(:disabled):not(.disabled):active:focus, .btn-gray-100:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-100.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-gray-200 {
  color: #212529;
  background-color: #e9ecef;
  border-color: #e9ecef; }
  .btn-gray-200:hover {
    color: #212529;
    background-color: #c4ccd4;
    border-color: #bdc6cf; }
  .btn-gray-200:focus, .btn-gray-200.focus {
    color: #212529;
    background-color: #c4ccd4;
    border-color: #bdc6cf;
    box-shadow: 0 0 0 0.2rem rgba(203, 206, 209, 0.5); }
  .btn-gray-200.disabled, .btn-gray-200:disabled {
    color: #212529;
    background-color: #e9ecef;
    border-color: #e9ecef; }
  .btn-gray-200:not(:disabled):not(.disabled):active, .btn-gray-200:not(:disabled):not(.disabled).active,
  .show > .btn-gray-200.dropdown-toggle {
    color: #212529;
    background-color: #cbd3da;
    border-color: #c4ccd4; }
    .btn-gray-200:not(:disabled):not(.disabled):active:focus, .btn-gray-200:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-200.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(203, 206, 209, 0.5); }

.btn-gray-300 {
  color: #212529;
  background-color: #dee2e6;
  border-color: #dee2e6; }
  .btn-gray-300:hover {
    color: #fff;
    background-color: #bac2cb;
    border-color: #b2bcc5; }
  .btn-gray-300:focus, .btn-gray-300.focus {
    color: #fff;
    background-color: #bac2cb;
    border-color: #b2bcc5;
    box-shadow: 0 0 0 0.2rem rgba(194, 198, 202, 0.5); }
  .btn-gray-300.disabled, .btn-gray-300:disabled {
    color: #212529;
    background-color: #dee2e6;
    border-color: #dee2e6; }
  .btn-gray-300:not(:disabled):not(.disabled):active, .btn-gray-300:not(:disabled):not(.disabled).active,
  .show > .btn-gray-300.dropdown-toggle {
    color: #fff;
    background-color: #c1c9d0;
    border-color: #bac2cb; }
    .btn-gray-300:not(:disabled):not(.disabled):active:focus, .btn-gray-300:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-300.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(194, 198, 202, 0.5); }

.btn-gray-400 {
  color: #212529;
  background-color: #ced4da;
  border-color: #ced4da; }
  .btn-gray-400:hover {
    color: #fff;
    background-color: #aab4bf;
    border-color: #a2aeb9; }
  .btn-gray-400:focus, .btn-gray-400.focus {
    color: #fff;
    background-color: #aab4bf;
    border-color: #a2aeb9;
    box-shadow: 0 0 0 0.2rem rgba(180, 186, 191, 0.5); }
  .btn-gray-400.disabled, .btn-gray-400:disabled {
    color: #212529;
    background-color: #ced4da;
    border-color: #ced4da; }
  .btn-gray-400:not(:disabled):not(.disabled):active, .btn-gray-400:not(:disabled):not(.disabled).active,
  .show > .btn-gray-400.dropdown-toggle {
    color: #fff;
    background-color: #b1bbc4;
    border-color: #aab4bf; }
    .btn-gray-400:not(:disabled):not(.disabled):active:focus, .btn-gray-400:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-400.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(180, 186, 191, 0.5); }

.btn-gray-500 {
  color: #fff;
  background-color: #adb5bd;
  border-color: #adb5bd; }
  .btn-gray-500:hover {
    color: #fff;
    background-color: #8a95a1;
    border-color: #838f9b; }
  .btn-gray-500:focus, .btn-gray-500.focus {
    color: #fff;
    background-color: #8a95a1;
    border-color: #838f9b;
    box-shadow: 0 0 0 0.2rem rgba(185, 192, 199, 0.5); }
  .btn-gray-500.disabled, .btn-gray-500:disabled {
    color: #fff;
    background-color: #adb5bd;
    border-color: #adb5bd; }
  .btn-gray-500:not(:disabled):not(.disabled):active, .btn-gray-500:not(:disabled):not(.disabled).active,
  .show > .btn-gray-500.dropdown-toggle {
    color: #fff;
    background-color: #919ca6;
    border-color: #8a95a1; }
    .btn-gray-500:not(:disabled):not(.disabled):active:focus, .btn-gray-500:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-500.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(185, 192, 199, 0.5); }

.btn-gray-600 {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-gray-600:hover {
    color: #fff;
    background-color: #4e555b;
    border-color: #494f54; }
  .btn-gray-600:focus, .btn-gray-600.focus {
    color: #fff;
    background-color: #4e555b;
    border-color: #494f54;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-gray-600.disabled, .btn-gray-600:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-gray-600:not(:disabled):not(.disabled):active, .btn-gray-600:not(:disabled):not(.disabled).active,
  .show > .btn-gray-600.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-gray-600:not(:disabled):not(.disabled):active:focus, .btn-gray-600:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-600.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-gray-700 {
  color: #fff;
  background-color: #495057;
  border-color: #495057; }
  .btn-gray-700:hover {
    color: #fff;
    background-color: #2c3034;
    border-color: #262a2d; }
  .btn-gray-700:focus, .btn-gray-700.focus {
    color: #fff;
    background-color: #2c3034;
    border-color: #262a2d;
    box-shadow: 0 0 0 0.2rem rgba(100, 106, 112, 0.5); }
  .btn-gray-700.disabled, .btn-gray-700:disabled {
    color: #fff;
    background-color: #495057;
    border-color: #495057; }
  .btn-gray-700:not(:disabled):not(.disabled):active, .btn-gray-700:not(:disabled):not(.disabled).active,
  .show > .btn-gray-700.dropdown-toggle {
    color: #fff;
    background-color: #32373b;
    border-color: #2c3034; }
    .btn-gray-700:not(:disabled):not(.disabled):active:focus, .btn-gray-700:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-700.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(100, 106, 112, 0.5); }

.btn-gray-800 {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-gray-800:hover {
    color: #fff;
    background-color: #171a1d;
    border-color: #121416; }
  .btn-gray-800:focus, .btn-gray-800.focus {
    color: #fff;
    background-color: #171a1d;
    border-color: #121416;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-gray-800.disabled, .btn-gray-800:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-gray-800:not(:disabled):not(.disabled):active, .btn-gray-800:not(:disabled):not(.disabled).active,
  .show > .btn-gray-800.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-gray-800:not(:disabled):not(.disabled):active:focus, .btn-gray-800:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-800.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-gray-900 {
  color: #fff;
  background-color: #212529;
  border-color: #212529; }
  .btn-gray-900:hover {
    color: #fff;
    background-color: #050506;
    border-color: black; }
  .btn-gray-900:focus, .btn-gray-900.focus {
    color: #fff;
    background-color: #050506;
    border-color: black;
    box-shadow: 0 0 0 0.2rem rgba(66, 70, 73, 0.5); }
  .btn-gray-900.disabled, .btn-gray-900:disabled {
    color: #fff;
    background-color: #212529;
    border-color: #212529; }
  .btn-gray-900:not(:disabled):not(.disabled):active, .btn-gray-900:not(:disabled):not(.disabled).active,
  .show > .btn-gray-900.dropdown-toggle {
    color: #fff;
    background-color: #0a0c0d;
    border-color: #050506; }
    .btn-gray-900:not(:disabled):not(.disabled):active:focus, .btn-gray-900:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-900.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(66, 70, 73, 0.5); }

.btn-twitter {
  color: #fff;
  background-color: #1da1f2;
  border-color: #1da1f2; }
  .btn-twitter:hover {
    color: #fff;
    background-color: #0b7ec4;
    border-color: #0b76b8; }
  .btn-twitter:focus, .btn-twitter.focus {
    color: #fff;
    background-color: #0b7ec4;
    border-color: #0b76b8;
    box-shadow: 0 0 0 0.2rem rgba(63, 175, 244, 0.5); }
  .btn-twitter.disabled, .btn-twitter:disabled {
    color: #fff;
    background-color: #1da1f2;
    border-color: #1da1f2; }
  .btn-twitter:not(:disabled):not(.disabled):active, .btn-twitter:not(:disabled):not(.disabled).active,
  .show > .btn-twitter.dropdown-toggle {
    color: #fff;
    background-color: #0c85d0;
    border-color: #0b7ec4; }
    .btn-twitter:not(:disabled):not(.disabled):active:focus, .btn-twitter:not(:disabled):not(.disabled).active:focus,
    .show > .btn-twitter.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(63, 175, 244, 0.5); }

.btn-white {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff; }
  .btn-white:hover {
    color: #212529;
    background-color: #dfdfdf;
    border-color: #d9d9d9; }
  .btn-white:focus, .btn-white.focus {
    color: #212529;
    background-color: #dfdfdf;
    border-color: #d9d9d9;
    box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }
  .btn-white.disabled, .btn-white:disabled {
    color: #212529;
    background-color: #ffffff;
    border-color: #ffffff; }
  .btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active,
  .show > .btn-white.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-white:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }

.btn-outline-main {
  color: #222222;
  border-color: #222222; }
  .btn-outline-main:hover {
    color: #fff;
    background-color: #222222;
    border-color: #222222; }
  .btn-outline-main:focus, .btn-outline-main.focus {
    box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.5); }
  .btn-outline-main.disabled, .btn-outline-main:disabled {
    color: #222222;
    background-color: transparent; }
  .btn-outline-main:not(:disabled):not(.disabled):active, .btn-outline-main:not(:disabled):not(.disabled).active,
  .show > .btn-outline-main.dropdown-toggle {
    color: #fff;
    background-color: #222222;
    border-color: #222222; }
    .btn-outline-main:not(:disabled):not(.disabled):active:focus, .btn-outline-main:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-main.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.5); }

.btn-outline-accent-01 {
  color: #8FC323;
  border-color: #8FC323; }
  .btn-outline-accent-01:hover {
    color: #fff;
    background-color: #8FC323;
    border-color: #8FC323; }
  .btn-outline-accent-01:focus, .btn-outline-accent-01.focus {
    box-shadow: 0 0 0 0.2rem rgba(143, 195, 35, 0.5); }
  .btn-outline-accent-01.disabled, .btn-outline-accent-01:disabled {
    color: #8FC323;
    background-color: transparent; }
  .btn-outline-accent-01:not(:disabled):not(.disabled):active, .btn-outline-accent-01:not(:disabled):not(.disabled).active,
  .show > .btn-outline-accent-01.dropdown-toggle {
    color: #fff;
    background-color: #8FC323;
    border-color: #8FC323; }
    .btn-outline-accent-01:not(:disabled):not(.disabled):active:focus, .btn-outline-accent-01:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-accent-01.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(143, 195, 35, 0.5); }

.btn-outline-accent-02 {
  color: #0075BF;
  border-color: #0075BF; }
  .btn-outline-accent-02:hover {
    color: #fff;
    background-color: #0075BF;
    border-color: #0075BF; }
  .btn-outline-accent-02:focus, .btn-outline-accent-02.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 117, 191, 0.5); }
  .btn-outline-accent-02.disabled, .btn-outline-accent-02:disabled {
    color: #0075BF;
    background-color: transparent; }
  .btn-outline-accent-02:not(:disabled):not(.disabled):active, .btn-outline-accent-02:not(:disabled):not(.disabled).active,
  .show > .btn-outline-accent-02.dropdown-toggle {
    color: #fff;
    background-color: #0075BF;
    border-color: #0075BF; }
    .btn-outline-accent-02:not(:disabled):not(.disabled):active:focus, .btn-outline-accent-02:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-accent-02.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 117, 191, 0.5); }

.btn-outline-accent-03 {
  color: #ECF9FF;
  border-color: #ECF9FF; }
  .btn-outline-accent-03:hover {
    color: #212529;
    background-color: #ECF9FF;
    border-color: #ECF9FF; }
  .btn-outline-accent-03:focus, .btn-outline-accent-03.focus {
    box-shadow: 0 0 0 0.2rem rgba(236, 249, 255, 0.5); }
  .btn-outline-accent-03.disabled, .btn-outline-accent-03:disabled {
    color: #ECF9FF;
    background-color: transparent; }
  .btn-outline-accent-03:not(:disabled):not(.disabled):active, .btn-outline-accent-03:not(:disabled):not(.disabled).active,
  .show > .btn-outline-accent-03.dropdown-toggle {
    color: #212529;
    background-color: #ECF9FF;
    border-color: #ECF9FF; }
    .btn-outline-accent-03:not(:disabled):not(.disabled):active:focus, .btn-outline-accent-03:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-accent-03.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(236, 249, 255, 0.5); }

.btn-outline-color-01 {
  color: #F5F5F5;
  border-color: #F5F5F5; }
  .btn-outline-color-01:hover {
    color: #212529;
    background-color: #F5F5F5;
    border-color: #F5F5F5; }
  .btn-outline-color-01:focus, .btn-outline-color-01.focus {
    box-shadow: 0 0 0 0.2rem rgba(245, 245, 245, 0.5); }
  .btn-outline-color-01.disabled, .btn-outline-color-01:disabled {
    color: #F5F5F5;
    background-color: transparent; }
  .btn-outline-color-01:not(:disabled):not(.disabled):active, .btn-outline-color-01:not(:disabled):not(.disabled).active,
  .show > .btn-outline-color-01.dropdown-toggle {
    color: #212529;
    background-color: #F5F5F5;
    border-color: #F5F5F5; }
    .btn-outline-color-01:not(:disabled):not(.disabled):active:focus, .btn-outline-color-01:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-color-01.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(245, 245, 245, 0.5); }

.btn-outline-color-02 {
  color: #CCCCCC;
  border-color: #CCCCCC; }
  .btn-outline-color-02:hover {
    color: #212529;
    background-color: #CCCCCC;
    border-color: #CCCCCC; }
  .btn-outline-color-02:focus, .btn-outline-color-02.focus {
    box-shadow: 0 0 0 0.2rem rgba(204, 204, 204, 0.5); }
  .btn-outline-color-02.disabled, .btn-outline-color-02:disabled {
    color: #CCCCCC;
    background-color: transparent; }
  .btn-outline-color-02:not(:disabled):not(.disabled):active, .btn-outline-color-02:not(:disabled):not(.disabled).active,
  .show > .btn-outline-color-02.dropdown-toggle {
    color: #212529;
    background-color: #CCCCCC;
    border-color: #CCCCCC; }
    .btn-outline-color-02:not(:disabled):not(.disabled):active:focus, .btn-outline-color-02:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-color-02.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(204, 204, 204, 0.5); }

.btn-outline-color-03 {
  color: #9CCC65;
  border-color: #9CCC65; }
  .btn-outline-color-03:hover {
    color: #fff;
    background-color: #9CCC65;
    border-color: #9CCC65; }
  .btn-outline-color-03:focus, .btn-outline-color-03.focus {
    box-shadow: 0 0 0 0.2rem rgba(156, 204, 101, 0.5); }
  .btn-outline-color-03.disabled, .btn-outline-color-03:disabled {
    color: #9CCC65;
    background-color: transparent; }
  .btn-outline-color-03:not(:disabled):not(.disabled):active, .btn-outline-color-03:not(:disabled):not(.disabled).active,
  .show > .btn-outline-color-03.dropdown-toggle {
    color: #fff;
    background-color: #9CCC65;
    border-color: #9CCC65; }
    .btn-outline-color-03:not(:disabled):not(.disabled):active:focus, .btn-outline-color-03:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-color-03.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(156, 204, 101, 0.5); }

.btn-outline-gray-333 {
  color: #333;
  border-color: #333; }
  .btn-outline-gray-333:hover {
    color: #fff;
    background-color: #333;
    border-color: #333; }
  .btn-outline-gray-333:focus, .btn-outline-gray-333.focus {
    box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5); }
  .btn-outline-gray-333.disabled, .btn-outline-gray-333:disabled {
    color: #333;
    background-color: transparent; }
  .btn-outline-gray-333:not(:disabled):not(.disabled):active, .btn-outline-gray-333:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-333.dropdown-toggle {
    color: #fff;
    background-color: #333;
    border-color: #333; }
    .btn-outline-gray-333:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-333:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-333.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5); }

.btn-outline-gray-100 {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-gray-100:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-gray-100:focus, .btn-outline-gray-100.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-gray-100.disabled, .btn-outline-gray-100:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-gray-100:not(:disabled):not(.disabled):active, .btn-outline-gray-100:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-100.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-gray-100:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-100:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-100.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-gray-200 {
  color: #e9ecef;
  border-color: #e9ecef; }
  .btn-outline-gray-200:hover {
    color: #212529;
    background-color: #e9ecef;
    border-color: #e9ecef; }
  .btn-outline-gray-200:focus, .btn-outline-gray-200.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 236, 239, 0.5); }
  .btn-outline-gray-200.disabled, .btn-outline-gray-200:disabled {
    color: #e9ecef;
    background-color: transparent; }
  .btn-outline-gray-200:not(:disabled):not(.disabled):active, .btn-outline-gray-200:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-200.dropdown-toggle {
    color: #212529;
    background-color: #e9ecef;
    border-color: #e9ecef; }
    .btn-outline-gray-200:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-200:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-200.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(233, 236, 239, 0.5); }

.btn-outline-gray-300 {
  color: #dee2e6;
  border-color: #dee2e6; }
  .btn-outline-gray-300:hover {
    color: #212529;
    background-color: #dee2e6;
    border-color: #dee2e6; }
  .btn-outline-gray-300:focus, .btn-outline-gray-300.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 226, 230, 0.5); }
  .btn-outline-gray-300.disabled, .btn-outline-gray-300:disabled {
    color: #dee2e6;
    background-color: transparent; }
  .btn-outline-gray-300:not(:disabled):not(.disabled):active, .btn-outline-gray-300:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-300.dropdown-toggle {
    color: #212529;
    background-color: #dee2e6;
    border-color: #dee2e6; }
    .btn-outline-gray-300:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-300:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-300.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 226, 230, 0.5); }

.btn-outline-gray-400 {
  color: #ced4da;
  border-color: #ced4da; }
  .btn-outline-gray-400:hover {
    color: #212529;
    background-color: #ced4da;
    border-color: #ced4da; }
  .btn-outline-gray-400:focus, .btn-outline-gray-400.focus {
    box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5); }
  .btn-outline-gray-400.disabled, .btn-outline-gray-400:disabled {
    color: #ced4da;
    background-color: transparent; }
  .btn-outline-gray-400:not(:disabled):not(.disabled):active, .btn-outline-gray-400:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-400.dropdown-toggle {
    color: #212529;
    background-color: #ced4da;
    border-color: #ced4da; }
    .btn-outline-gray-400:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-400:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-400.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5); }

.btn-outline-gray-500 {
  color: #adb5bd;
  border-color: #adb5bd; }
  .btn-outline-gray-500:hover {
    color: #fff;
    background-color: #adb5bd;
    border-color: #adb5bd; }
  .btn-outline-gray-500:focus, .btn-outline-gray-500.focus {
    box-shadow: 0 0 0 0.2rem rgba(173, 181, 189, 0.5); }
  .btn-outline-gray-500.disabled, .btn-outline-gray-500:disabled {
    color: #adb5bd;
    background-color: transparent; }
  .btn-outline-gray-500:not(:disabled):not(.disabled):active, .btn-outline-gray-500:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-500.dropdown-toggle {
    color: #fff;
    background-color: #adb5bd;
    border-color: #adb5bd; }
    .btn-outline-gray-500:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-500:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-500.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(173, 181, 189, 0.5); }

.btn-outline-gray-600 {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-gray-600:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-gray-600:focus, .btn-outline-gray-600.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-gray-600.disabled, .btn-outline-gray-600:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-gray-600:not(:disabled):not(.disabled):active, .btn-outline-gray-600:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-600.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-gray-600:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-600:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-600.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-gray-700 {
  color: #495057;
  border-color: #495057; }
  .btn-outline-gray-700:hover {
    color: #fff;
    background-color: #495057;
    border-color: #495057; }
  .btn-outline-gray-700:focus, .btn-outline-gray-700.focus {
    box-shadow: 0 0 0 0.2rem rgba(73, 80, 87, 0.5); }
  .btn-outline-gray-700.disabled, .btn-outline-gray-700:disabled {
    color: #495057;
    background-color: transparent; }
  .btn-outline-gray-700:not(:disabled):not(.disabled):active, .btn-outline-gray-700:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-700.dropdown-toggle {
    color: #fff;
    background-color: #495057;
    border-color: #495057; }
    .btn-outline-gray-700:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-700:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-700.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(73, 80, 87, 0.5); }

.btn-outline-gray-800 {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-gray-800:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-gray-800:focus, .btn-outline-gray-800.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-gray-800.disabled, .btn-outline-gray-800:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-gray-800:not(:disabled):not(.disabled):active, .btn-outline-gray-800:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-800.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-gray-800:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-800:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-800.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-gray-900 {
  color: #212529;
  border-color: #212529; }
  .btn-outline-gray-900:hover {
    color: #fff;
    background-color: #212529;
    border-color: #212529; }
  .btn-outline-gray-900:focus, .btn-outline-gray-900.focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 37, 41, 0.5); }
  .btn-outline-gray-900.disabled, .btn-outline-gray-900:disabled {
    color: #212529;
    background-color: transparent; }
  .btn-outline-gray-900:not(:disabled):not(.disabled):active, .btn-outline-gray-900:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-900.dropdown-toggle {
    color: #fff;
    background-color: #212529;
    border-color: #212529; }
    .btn-outline-gray-900:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-900:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-900.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(33, 37, 41, 0.5); }

.btn-outline-twitter {
  color: #1da1f2;
  border-color: #1da1f2; }
  .btn-outline-twitter:hover {
    color: #fff;
    background-color: #1da1f2;
    border-color: #1da1f2; }
  .btn-outline-twitter:focus, .btn-outline-twitter.focus {
    box-shadow: 0 0 0 0.2rem rgba(29, 161, 242, 0.5); }
  .btn-outline-twitter.disabled, .btn-outline-twitter:disabled {
    color: #1da1f2;
    background-color: transparent; }
  .btn-outline-twitter:not(:disabled):not(.disabled):active, .btn-outline-twitter:not(:disabled):not(.disabled).active,
  .show > .btn-outline-twitter.dropdown-toggle {
    color: #fff;
    background-color: #1da1f2;
    border-color: #1da1f2; }
    .btn-outline-twitter:not(:disabled):not(.disabled):active:focus, .btn-outline-twitter:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-twitter.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(29, 161, 242, 0.5); }

.btn-outline-white {
  color: #ffffff;
  border-color: #ffffff; }
  .btn-outline-white:hover {
    color: #212529;
    background-color: #ffffff;
    border-color: #ffffff; }
  .btn-outline-white:focus, .btn-outline-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-outline-white.disabled, .btn-outline-white:disabled {
    color: #ffffff;
    background-color: transparent; }
  .btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled).active,
  .show > .btn-outline-white.dropdown-toggle {
    color: #212529;
    background-color: #ffffff;
    border-color: #ffffff; }
    .btn-outline-white:not(:disabled):not(.disabled):active:focus, .btn-outline-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-link {
  font-weight: 400;
  color: #222222;
  text-decoration: none; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1150px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1150px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-main {
  color: #222222 !important; }

a.text-main:hover, a.text-main:focus {
  color: black !important; }

.text-accent-01 {
  color: #8FC323 !important; }

a.text-accent-01:hover, a.text-accent-01:focus {
  color: #5f8217 !important; }

.text-accent-02 {
  color: #0075BF !important; }

a.text-accent-02:hover, a.text-accent-02:focus {
  color: #004673 !important; }

.text-accent-03 {
  color: #ECF9FF !important; }

a.text-accent-03:hover, a.text-accent-03:focus {
  color: #a0e1ff !important; }

.text-color-01 {
  color: #F5F5F5 !important; }

a.text-color-01:hover, a.text-color-01:focus {
  color: #cfcfcf !important; }

.text-color-02 {
  color: #CCCCCC !important; }

a.text-color-02:hover, a.text-color-02:focus {
  color: #a6a6a6 !important; }

.text-color-03 {
  color: #9CCC65 !important; }

a.text-color-03:hover, a.text-color-03:focus {
  color: #76ac39 !important; }

.text-gray-333 {
  color: #333 !important; }

a.text-gray-333:hover, a.text-gray-333:focus {
  color: #0d0d0d !important; }

.text-gray-100 {
  color: #f8f9fa !important; }

a.text-gray-100:hover, a.text-gray-100:focus {
  color: #cbd3da !important; }

.text-gray-200 {
  color: #e9ecef !important; }

a.text-gray-200:hover, a.text-gray-200:focus {
  color: #bdc6cf !important; }

.text-gray-300 {
  color: #dee2e6 !important; }

a.text-gray-300:hover, a.text-gray-300:focus {
  color: #b2bcc5 !important; }

.text-gray-400 {
  color: #ced4da !important; }

a.text-gray-400:hover, a.text-gray-400:focus {
  color: #a2aeb9 !important; }

.text-gray-500 {
  color: #adb5bd !important; }

a.text-gray-500:hover, a.text-gray-500:focus {
  color: #838f9b !important; }

.text-gray-600 {
  color: #6c757d !important; }

a.text-gray-600:hover, a.text-gray-600:focus {
  color: #494f54 !important; }

.text-gray-700 {
  color: #495057 !important; }

a.text-gray-700:hover, a.text-gray-700:focus {
  color: #262a2d !important; }

.text-gray-800 {
  color: #343a40 !important; }

a.text-gray-800:hover, a.text-gray-800:focus {
  color: #121416 !important; }

.text-gray-900 {
  color: #212529 !important; }

a.text-gray-900:hover, a.text-gray-900:focus {
  color: black !important; }

.text-twitter {
  color: #1da1f2 !important; }

a.text-twitter:hover, a.text-twitter:focus {
  color: #0b76b8 !important; }

.text-white {
  color: #ffffff !important; }

a.text-white:hover, a.text-white:focus {
  color: #d9d9d9 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.bg-main {
  background-color: #222222 !important; }

a.bg-main:hover, a.bg-main:focus,
button.bg-main:hover,
button.bg-main:focus {
  background-color: #090909 !important; }

.bg-accent-01 {
  background-color: #8FC323 !important; }

a.bg-accent-01:hover, a.bg-accent-01:focus,
button.bg-accent-01:hover,
button.bg-accent-01:focus {
  background-color: #6f981b !important; }

.bg-accent-02 {
  background-color: #0075BF !important; }

a.bg-accent-02:hover, a.bg-accent-02:focus,
button.bg-accent-02:hover,
button.bg-accent-02:focus {
  background-color: #00568c !important; }

.bg-accent-03 {
  background-color: #ECF9FF !important; }

a.bg-accent-03:hover, a.bg-accent-03:focus,
button.bg-accent-03:hover,
button.bg-accent-03:focus {
  background-color: #b9e9ff !important; }

.bg-color-01 {
  background-color: #F5F5F5 !important; }

a.bg-color-01:hover, a.bg-color-01:focus,
button.bg-color-01:hover,
button.bg-color-01:focus {
  background-color: gainsboro !important; }

.bg-color-02 {
  background-color: #CCCCCC !important; }

a.bg-color-02:hover, a.bg-color-02:focus,
button.bg-color-02:hover,
button.bg-color-02:focus {
  background-color: #b3b3b3 !important; }

.bg-color-03 {
  background-color: #9CCC65 !important; }

a.bg-color-03:hover, a.bg-color-03:focus,
button.bg-color-03:hover,
button.bg-color-03:focus {
  background-color: #83bf3f !important; }

.bg-gray-333 {
  background-color: #333 !important; }

a.bg-gray-333:hover, a.bg-gray-333:focus,
button.bg-gray-333:hover,
button.bg-gray-333:focus {
  background-color: #1a1a1a !important; }

.bg-gray-100 {
  background-color: #f8f9fa !important; }

a.bg-gray-100:hover, a.bg-gray-100:focus,
button.bg-gray-100:hover,
button.bg-gray-100:focus {
  background-color: #dae0e5 !important; }

.bg-gray-200 {
  background-color: #e9ecef !important; }

a.bg-gray-200:hover, a.bg-gray-200:focus,
button.bg-gray-200:hover,
button.bg-gray-200:focus {
  background-color: #cbd3da !important; }

.bg-gray-300, .bm_navbar, .bm_footer {
  background-color: #dee2e6 !important; }

a.bg-gray-300:hover, a.bm_navbar:hover, a.bm_footer:hover, a.bg-gray-300:focus, a.bm_navbar:focus, a.bm_footer:focus,
button.bg-gray-300:hover,
button.bm_navbar:hover,
button.bm_footer:hover,
button.bg-gray-300:focus,
button.bm_navbar:focus,
button.bm_footer:focus {
  background-color: #c1c9d0 !important; }

.bg-gray-400 {
  background-color: #ced4da !important; }

a.bg-gray-400:hover, a.bg-gray-400:focus,
button.bg-gray-400:hover,
button.bg-gray-400:focus {
  background-color: #b1bbc4 !important; }

.bg-gray-500 {
  background-color: #adb5bd !important; }

a.bg-gray-500:hover, a.bg-gray-500:focus,
button.bg-gray-500:hover,
button.bg-gray-500:focus {
  background-color: #919ca6 !important; }

.bg-gray-600, .bm_header {
  background-color: #6c757d !important; }

a.bg-gray-600:hover, a.bm_header:hover, a.bg-gray-600:focus, a.bm_header:focus,
button.bg-gray-600:hover,
button.bm_header:hover,
button.bg-gray-600:focus,
button.bm_header:focus {
  background-color: #545b62 !important; }

.bg-gray-700 {
  background-color: #495057 !important; }

a.bg-gray-700:hover, a.bg-gray-700:focus,
button.bg-gray-700:hover,
button.bg-gray-700:focus {
  background-color: #32373b !important; }

.bg-gray-800, .bm_navbar .bm_navbar-toggle {
  background-color: #343a40 !important; }

a.bg-gray-800:hover, .bm_navbar a.bm_navbar-toggle:hover, a.bg-gray-800:focus, .bm_navbar a.bm_navbar-toggle:focus,
button.bg-gray-800:hover,
.bm_navbar button.bm_navbar-toggle:hover,
button.bg-gray-800:focus,
.bm_navbar button.bm_navbar-toggle:focus {
  background-color: #1d2124 !important; }

.bg-gray-900 {
  background-color: #212529 !important; }

a.bg-gray-900:hover, a.bg-gray-900:focus,
button.bg-gray-900:hover,
button.bg-gray-900:focus {
  background-color: #0a0c0d !important; }

.bg-twitter {
  background-color: #1da1f2 !important; }

a.bg-twitter:hover, a.bg-twitter:focus,
button.bg-twitter:hover,
button.bg-twitter:focus {
  background-color: #0c85d0 !important; }

.bg-white {
  background-color: #ffffff !important; }

a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-main {
  border-color: #222222 !important; }

.border-accent-01 {
  border-color: #8FC323 !important; }

.border-accent-02 {
  border-color: #0075BF !important; }

.border-accent-03 {
  border-color: #ECF9FF !important; }

.border-color-01 {
  border-color: #F5F5F5 !important; }

.border-color-02 {
  border-color: #CCCCCC !important; }

.border-color-03 {
  border-color: #9CCC65 !important; }

.border-gray-333 {
  border-color: #333 !important; }

.border-gray-100 {
  border-color: #f8f9fa !important; }

.border-gray-200 {
  border-color: #e9ecef !important; }

.border-gray-300 {
  border-color: #dee2e6 !important; }

.border-gray-400 {
  border-color: #ced4da !important; }

.border-gray-500 {
  border-color: #adb5bd !important; }

.border-gray-600 {
  border-color: #6c757d !important; }

.border-gray-700 {
  border-color: #495057 !important; }

.border-gray-800 {
  border-color: #343a40 !important; }

.border-gray-900 {
  border-color: #212529 !important; }

.border-twitter {
  border-color: #1da1f2 !important; }

.border-white {
  border-color: #ffffff !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.w-10 {
  width: 10% !important; }

.w-20 {
  width: 20% !important; }

.w-25 {
  width: 25% !important; }

.w-30 {
  width: 30% !important; }

.w-40 {
  width: 40% !important; }

.w-50 {
  width: 50% !important; }

.w-60 {
  width: 60% !important; }

.w-70 {
  width: 70% !important; }

.w-75 {
  width: 75% !important; }

.w-80 {
  width: 80% !important; }

.w-90 {
  width: 90% !important; }

.w-100, .bm_navbar .bm_navbar-toggle {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-10 {
  height: 10% !important; }

.h-20 {
  height: 20% !important; }

.h-25 {
  height: 25% !important; }

.h-30 {
  height: 30% !important; }

.h-40 {
  height: 40% !important; }

.h-50 {
  height: 50% !important; }

.h-60 {
  height: 60% !important; }

.h-70 {
  height: 70% !important; }

.h-75 {
  height: 75% !important; }

.h-80 {
  height: 80% !important; }

.h-90 {
  height: 90% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.m-6 {
  margin: 4rem !important; }

.mt-6,
.my-6 {
  margin-top: 4rem !important; }

.mr-6,
.mx-6 {
  margin-right: 4rem !important; }

.mb-6,
.my-6 {
  margin-bottom: 4rem !important; }

.ml-6,
.mx-6 {
  margin-left: 4rem !important; }

.m-7 {
  margin: 5rem !important; }

.mt-7,
.my-7 {
  margin-top: 5rem !important; }

.mr-7,
.mx-7 {
  margin-right: 5rem !important; }

.mb-7,
.my-7 {
  margin-bottom: 5rem !important; }

.ml-7,
.mx-7 {
  margin-left: 5rem !important; }

.m-8 {
  margin: 6.25rem !important; }

.mt-8,
.my-8 {
  margin-top: 6.25rem !important; }

.mr-8,
.mx-8 {
  margin-right: 6.25rem !important; }

.mb-8,
.my-8 {
  margin-bottom: 6.25rem !important; }

.ml-8,
.mx-8 {
  margin-left: 6.25rem !important; }

.m-9 {
  margin: 9.25rem !important; }

.mt-9,
.my-9 {
  margin-top: 9.25rem !important; }

.mr-9,
.mx-9 {
  margin-right: 9.25rem !important; }

.mb-9,
.my-9 {
  margin-bottom: 9.25rem !important; }

.ml-9,
.mx-9 {
  margin-left: 9.25rem !important; }

.m-10 {
  margin: 12.5rem !important; }

.mt-10,
.my-10 {
  margin-top: 12.5rem !important; }

.mr-10,
.mx-10 {
  margin-right: 12.5rem !important; }

.mb-10,
.my-10 {
  margin-bottom: 12.5rem !important; }

.ml-10,
.mx-10 {
  margin-left: 12.5rem !important; }

.p-6 {
  padding: 4rem !important; }

.pt-6,
.py-6 {
  padding-top: 4rem !important; }

.pr-6,
.px-6 {
  padding-right: 4rem !important; }

.pb-6,
.py-6 {
  padding-bottom: 4rem !important; }

.pl-6,
.px-6 {
  padding-left: 4rem !important; }

.p-7 {
  padding: 5rem !important; }

.pt-7,
.py-7 {
  padding-top: 5rem !important; }

.pr-7,
.px-7 {
  padding-right: 5rem !important; }

.pb-7,
.py-7 {
  padding-bottom: 5rem !important; }

.pl-7,
.px-7 {
  padding-left: 5rem !important; }

.p-8 {
  padding: 6.25rem !important; }

.pt-8,
.py-8 {
  padding-top: 6.25rem !important; }

.pr-8,
.px-8 {
  padding-right: 6.25rem !important; }

.pb-8,
.py-8 {
  padding-bottom: 6.25rem !important; }

.pl-8,
.px-8 {
  padding-left: 6.25rem !important; }

.p-9 {
  padding: 9.25rem !important; }

.pt-9,
.py-9 {
  padding-top: 9.25rem !important; }

.pr-9,
.px-9 {
  padding-right: 9.25rem !important; }

.pb-9,
.py-9 {
  padding-bottom: 9.25rem !important; }

.pl-9,
.px-9 {
  padding-left: 9.25rem !important; }

.p-10 {
  padding: 12.5rem !important; }

.pt-10,
.py-10 {
  padding-top: 12.5rem !important; }

.pr-10,
.px-10 {
  padding-right: 12.5rem !important; }

.pb-10,
.py-10 {
  padding-bottom: 12.5rem !important; }

.pl-10,
.px-10 {
  padding-left: 12.5rem !important; }

.m-n6 {
  margin: -4rem !important; }

.mt-n6,
.my-n6 {
  margin-top: -4rem !important; }

.mr-n6,
.mx-n6 {
  margin-right: -4rem !important; }

.mb-n6,
.my-n6 {
  margin-bottom: -4rem !important; }

.ml-n6,
.mx-n6 {
  margin-left: -4rem !important; }

.m-n7 {
  margin: -4rem !important; }

.mt-n7,
.my-n7 {
  margin-top: -4rem !important; }

.mr-n7,
.mx-n7 {
  margin-right: -4rem !important; }

.mb-n7,
.my-n7 {
  margin-bottom: -4rem !important; }

.ml-n7,
.mx-n7 {
  margin-left: -4rem !important; }

.m-n8 {
  margin: -4rem !important; }

.mt-n8,
.my-n8 {
  margin-top: -4rem !important; }

.mr-n8,
.mx-n8 {
  margin-right: -4rem !important; }

.mb-n8,
.my-n8 {
  margin-bottom: -4rem !important; }

.ml-n8,
.mx-n8 {
  margin-left: -4rem !important; }

.m-n9 {
  margin: -4rem !important; }

.mt-n9,
.my-n9 {
  margin-top: -4rem !important; }

.mr-n9,
.mx-n9 {
  margin-right: -4rem !important; }

.mb-n9,
.my-n9 {
  margin-bottom: -4rem !important; }

.ml-n9,
.mx-n9 {
  margin-left: -4rem !important; }

.m-n10 {
  margin: -4rem !important; }

.mt-n10,
.my-n10 {
  margin-top: -4rem !important; }

.mr-n10,
.mx-n10 {
  margin-right: -4rem !important; }

.mb-n10,
.my-n10 {
  margin-bottom: -4rem !important; }

.ml-n10,
.mx-n10 {
  margin-left: -4rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-6 {
    margin: 4rem !important; }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 4rem !important; }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 4rem !important; }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 4rem !important; }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 4rem !important; }
  .m-sm-7 {
    margin: 5rem !important; }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 5rem !important; }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 5rem !important; }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 5rem !important; }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 5rem !important; }
  .m-sm-8 {
    margin: 6.25rem !important; }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 6.25rem !important; }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 6.25rem !important; }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 6.25rem !important; }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 6.25rem !important; }
  .m-sm-9 {
    margin: 9.25rem !important; }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 9.25rem !important; }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 9.25rem !important; }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 9.25rem !important; }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 9.25rem !important; }
  .m-sm-10 {
    margin: 12.5rem !important; }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 12.5rem !important; }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 12.5rem !important; }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 12.5rem !important; }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 12.5rem !important; }
  .p-sm-6 {
    padding: 4rem !important; }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 4rem !important; }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 4rem !important; }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 4rem !important; }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 4rem !important; }
  .p-sm-7 {
    padding: 5rem !important; }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 5rem !important; }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 5rem !important; }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 5rem !important; }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 5rem !important; }
  .p-sm-8 {
    padding: 6.25rem !important; }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 6.25rem !important; }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 6.25rem !important; }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 6.25rem !important; }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 6.25rem !important; }
  .p-sm-9 {
    padding: 9.25rem !important; }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 9.25rem !important; }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 9.25rem !important; }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 9.25rem !important; }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 9.25rem !important; }
  .p-sm-10 {
    padding: 12.5rem !important; }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 12.5rem !important; }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 12.5rem !important; }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 12.5rem !important; }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 12.5rem !important; }
  .m-sm-n6 {
    margin: -4rem !important; }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -4rem !important; }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -4rem !important; }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -4rem !important; }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -4rem !important; }
  .m-sm-n7 {
    margin: -4rem !important; }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -4rem !important; }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -4rem !important; }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -4rem !important; }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -4rem !important; }
  .m-sm-n8 {
    margin: -4rem !important; }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -4rem !important; }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -4rem !important; }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -4rem !important; }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -4rem !important; }
  .m-sm-n9 {
    margin: -4rem !important; }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -4rem !important; }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -4rem !important; }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -4rem !important; }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -4rem !important; }
  .m-sm-n10 {
    margin: -4rem !important; }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -4rem !important; }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -4rem !important; }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -4rem !important; }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -4rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-6 {
    margin: 4rem !important; }
  .mt-md-6,
  .my-md-6 {
    margin-top: 4rem !important; }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 4rem !important; }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 4rem !important; }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 4rem !important; }
  .m-md-7 {
    margin: 5rem !important; }
  .mt-md-7,
  .my-md-7 {
    margin-top: 5rem !important; }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 5rem !important; }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 5rem !important; }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 5rem !important; }
  .m-md-8 {
    margin: 6.25rem !important; }
  .mt-md-8,
  .my-md-8 {
    margin-top: 6.25rem !important; }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 6.25rem !important; }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 6.25rem !important; }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 6.25rem !important; }
  .m-md-9 {
    margin: 9.25rem !important; }
  .mt-md-9,
  .my-md-9 {
    margin-top: 9.25rem !important; }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 9.25rem !important; }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 9.25rem !important; }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 9.25rem !important; }
  .m-md-10 {
    margin: 12.5rem !important; }
  .mt-md-10,
  .my-md-10 {
    margin-top: 12.5rem !important; }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 12.5rem !important; }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 12.5rem !important; }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 12.5rem !important; }
  .p-md-6 {
    padding: 4rem !important; }
  .pt-md-6,
  .py-md-6 {
    padding-top: 4rem !important; }
  .pr-md-6,
  .px-md-6 {
    padding-right: 4rem !important; }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 4rem !important; }
  .pl-md-6,
  .px-md-6 {
    padding-left: 4rem !important; }
  .p-md-7 {
    padding: 5rem !important; }
  .pt-md-7,
  .py-md-7 {
    padding-top: 5rem !important; }
  .pr-md-7,
  .px-md-7 {
    padding-right: 5rem !important; }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 5rem !important; }
  .pl-md-7,
  .px-md-7 {
    padding-left: 5rem !important; }
  .p-md-8 {
    padding: 6.25rem !important; }
  .pt-md-8,
  .py-md-8 {
    padding-top: 6.25rem !important; }
  .pr-md-8,
  .px-md-8 {
    padding-right: 6.25rem !important; }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 6.25rem !important; }
  .pl-md-8,
  .px-md-8 {
    padding-left: 6.25rem !important; }
  .p-md-9 {
    padding: 9.25rem !important; }
  .pt-md-9,
  .py-md-9 {
    padding-top: 9.25rem !important; }
  .pr-md-9,
  .px-md-9 {
    padding-right: 9.25rem !important; }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 9.25rem !important; }
  .pl-md-9,
  .px-md-9 {
    padding-left: 9.25rem !important; }
  .p-md-10 {
    padding: 12.5rem !important; }
  .pt-md-10,
  .py-md-10 {
    padding-top: 12.5rem !important; }
  .pr-md-10,
  .px-md-10 {
    padding-right: 12.5rem !important; }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 12.5rem !important; }
  .pl-md-10,
  .px-md-10 {
    padding-left: 12.5rem !important; }
  .m-md-n6 {
    margin: -4rem !important; }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -4rem !important; }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -4rem !important; }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -4rem !important; }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -4rem !important; }
  .m-md-n7 {
    margin: -4rem !important; }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -4rem !important; }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -4rem !important; }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -4rem !important; }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -4rem !important; }
  .m-md-n8 {
    margin: -4rem !important; }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -4rem !important; }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -4rem !important; }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -4rem !important; }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -4rem !important; }
  .m-md-n9 {
    margin: -4rem !important; }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -4rem !important; }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -4rem !important; }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -4rem !important; }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -4rem !important; }
  .m-md-n10 {
    margin: -4rem !important; }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -4rem !important; }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -4rem !important; }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -4rem !important; }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -4rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-6 {
    margin: 4rem !important; }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 4rem !important; }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 4rem !important; }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 4rem !important; }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 4rem !important; }
  .m-lg-7 {
    margin: 5rem !important; }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 5rem !important; }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 5rem !important; }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 5rem !important; }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 5rem !important; }
  .m-lg-8 {
    margin: 6.25rem !important; }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 6.25rem !important; }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 6.25rem !important; }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 6.25rem !important; }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 6.25rem !important; }
  .m-lg-9 {
    margin: 9.25rem !important; }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 9.25rem !important; }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 9.25rem !important; }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 9.25rem !important; }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 9.25rem !important; }
  .m-lg-10 {
    margin: 12.5rem !important; }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 12.5rem !important; }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 12.5rem !important; }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 12.5rem !important; }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 12.5rem !important; }
  .p-lg-6 {
    padding: 4rem !important; }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 4rem !important; }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 4rem !important; }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 4rem !important; }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 4rem !important; }
  .p-lg-7 {
    padding: 5rem !important; }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 5rem !important; }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 5rem !important; }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 5rem !important; }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 5rem !important; }
  .p-lg-8 {
    padding: 6.25rem !important; }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 6.25rem !important; }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 6.25rem !important; }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 6.25rem !important; }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 6.25rem !important; }
  .p-lg-9 {
    padding: 9.25rem !important; }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 9.25rem !important; }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 9.25rem !important; }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 9.25rem !important; }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 9.25rem !important; }
  .p-lg-10 {
    padding: 12.5rem !important; }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 12.5rem !important; }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 12.5rem !important; }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 12.5rem !important; }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 12.5rem !important; }
  .m-lg-n6 {
    margin: -4rem !important; }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -4rem !important; }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -4rem !important; }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -4rem !important; }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -4rem !important; }
  .m-lg-n7 {
    margin: -4rem !important; }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -4rem !important; }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -4rem !important; }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -4rem !important; }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -4rem !important; }
  .m-lg-n8 {
    margin: -4rem !important; }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -4rem !important; }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -4rem !important; }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -4rem !important; }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -4rem !important; }
  .m-lg-n9 {
    margin: -4rem !important; }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -4rem !important; }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -4rem !important; }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -4rem !important; }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -4rem !important; }
  .m-lg-n10 {
    margin: -4rem !important; }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -4rem !important; }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -4rem !important; }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -4rem !important; }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -4rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-6 {
    margin: 4rem !important; }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 4rem !important; }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 4rem !important; }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 4rem !important; }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 4rem !important; }
  .m-xl-7 {
    margin: 5rem !important; }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 5rem !important; }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 5rem !important; }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 5rem !important; }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 5rem !important; }
  .m-xl-8 {
    margin: 6.25rem !important; }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 6.25rem !important; }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 6.25rem !important; }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 6.25rem !important; }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 6.25rem !important; }
  .m-xl-9 {
    margin: 9.25rem !important; }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 9.25rem !important; }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 9.25rem !important; }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 9.25rem !important; }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 9.25rem !important; }
  .m-xl-10 {
    margin: 12.5rem !important; }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 12.5rem !important; }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 12.5rem !important; }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 12.5rem !important; }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 12.5rem !important; }
  .p-xl-6 {
    padding: 4rem !important; }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 4rem !important; }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 4rem !important; }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 4rem !important; }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 4rem !important; }
  .p-xl-7 {
    padding: 5rem !important; }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 5rem !important; }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 5rem !important; }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 5rem !important; }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 5rem !important; }
  .p-xl-8 {
    padding: 6.25rem !important; }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 6.25rem !important; }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 6.25rem !important; }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 6.25rem !important; }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 6.25rem !important; }
  .p-xl-9 {
    padding: 9.25rem !important; }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 9.25rem !important; }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 9.25rem !important; }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 9.25rem !important; }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 9.25rem !important; }
  .p-xl-10 {
    padding: 12.5rem !important; }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 12.5rem !important; }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 12.5rem !important; }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 12.5rem !important; }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 12.5rem !important; }
  .m-xl-n6 {
    margin: -4rem !important; }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -4rem !important; }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -4rem !important; }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -4rem !important; }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -4rem !important; }
  .m-xl-n7 {
    margin: -4rem !important; }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -4rem !important; }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -4rem !important; }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -4rem !important; }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -4rem !important; }
  .m-xl-n8 {
    margin: -4rem !important; }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -4rem !important; }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -4rem !important; }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -4rem !important; }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -4rem !important; }
  .m-xl-n9 {
    margin: -4rem !important; }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -4rem !important; }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -4rem !important; }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -4rem !important; }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -4rem !important; }
  .m-xl-n10 {
    margin: -4rem !important; }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -4rem !important; }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -4rem !important; }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -4rem !important; }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -4rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.pr-0 {
  padding-right: 0 !important; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-main {
  color: #fff;
  background-color: #222222; }
  a.badge-main:hover, a.badge-main:focus {
    color: #fff;
    background-color: #090909; }
  a.badge-main:focus, a.badge-main.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.5); }

.badge-accent-01 {
  color: #fff;
  background-color: #8FC323; }
  a.badge-accent-01:hover, a.badge-accent-01:focus {
    color: #fff;
    background-color: #6f981b; }
  a.badge-accent-01:focus, a.badge-accent-01.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(143, 195, 35, 0.5); }

.badge-accent-02 {
  color: #fff;
  background-color: #0075BF; }
  a.badge-accent-02:hover, a.badge-accent-02:focus {
    color: #fff;
    background-color: #00568c; }
  a.badge-accent-02:focus, a.badge-accent-02.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 117, 191, 0.5); }

.badge-accent-03 {
  color: #212529;
  background-color: #ECF9FF; }
  a.badge-accent-03:hover, a.badge-accent-03:focus {
    color: #212529;
    background-color: #b9e9ff; }
  a.badge-accent-03:focus, a.badge-accent-03.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(236, 249, 255, 0.5); }

.badge-color-01 {
  color: #212529;
  background-color: #F5F5F5; }
  a.badge-color-01:hover, a.badge-color-01:focus {
    color: #212529;
    background-color: gainsboro; }
  a.badge-color-01:focus, a.badge-color-01.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(245, 245, 245, 0.5); }

.badge-color-02 {
  color: #212529;
  background-color: #CCCCCC; }
  a.badge-color-02:hover, a.badge-color-02:focus {
    color: #212529;
    background-color: #b3b3b3; }
  a.badge-color-02:focus, a.badge-color-02.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(204, 204, 204, 0.5); }

.badge-color-03 {
  color: #fff;
  background-color: #9CCC65; }
  a.badge-color-03:hover, a.badge-color-03:focus {
    color: #fff;
    background-color: #83bf3f; }
  a.badge-color-03:focus, a.badge-color-03.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(156, 204, 101, 0.5); }

.badge-gray-333 {
  color: #fff;
  background-color: #333; }
  a.badge-gray-333:hover, a.badge-gray-333:focus {
    color: #fff;
    background-color: #1a1a1a; }
  a.badge-gray-333:focus, a.badge-gray-333.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5); }

.badge-gray-100 {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-gray-100:hover, a.badge-gray-100:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-gray-100:focus, a.badge-gray-100.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-gray-200 {
  color: #212529;
  background-color: #e9ecef; }
  a.badge-gray-200:hover, a.badge-gray-200:focus {
    color: #212529;
    background-color: #cbd3da; }
  a.badge-gray-200:focus, a.badge-gray-200.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(233, 236, 239, 0.5); }

.badge-gray-300 {
  color: #212529;
  background-color: #dee2e6; }
  a.badge-gray-300:hover, a.badge-gray-300:focus {
    color: #212529;
    background-color: #c1c9d0; }
  a.badge-gray-300:focus, a.badge-gray-300.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(222, 226, 230, 0.5); }

.badge-gray-400 {
  color: #212529;
  background-color: #ced4da; }
  a.badge-gray-400:hover, a.badge-gray-400:focus {
    color: #212529;
    background-color: #b1bbc4; }
  a.badge-gray-400:focus, a.badge-gray-400.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5); }

.badge-gray-500 {
  color: #fff;
  background-color: #adb5bd; }
  a.badge-gray-500:hover, a.badge-gray-500:focus {
    color: #fff;
    background-color: #919ca6; }
  a.badge-gray-500:focus, a.badge-gray-500.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(173, 181, 189, 0.5); }

.badge-gray-600 {
  color: #fff;
  background-color: #6c757d; }
  a.badge-gray-600:hover, a.badge-gray-600:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-gray-600:focus, a.badge-gray-600.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-gray-700 {
  color: #fff;
  background-color: #495057; }
  a.badge-gray-700:hover, a.badge-gray-700:focus {
    color: #fff;
    background-color: #32373b; }
  a.badge-gray-700:focus, a.badge-gray-700.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(73, 80, 87, 0.5); }

.badge-gray-800 {
  color: #fff;
  background-color: #343a40; }
  a.badge-gray-800:hover, a.badge-gray-800:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-gray-800:focus, a.badge-gray-800.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.badge-gray-900 {
  color: #fff;
  background-color: #212529; }
  a.badge-gray-900:hover, a.badge-gray-900:focus {
    color: #fff;
    background-color: #0a0c0d; }
  a.badge-gray-900:focus, a.badge-gray-900.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(33, 37, 41, 0.5); }

.badge-twitter {
  color: #fff;
  background-color: #1da1f2; }
  a.badge-twitter:hover, a.badge-twitter:focus {
    color: #fff;
    background-color: #0c85d0; }
  a.badge-twitter:focus, a.badge-twitter.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(29, 161, 242, 0.5); }

.badge-white {
  color: #212529;
  background-color: #ffffff; }
  a.badge-white:hover, a.badge-white:focus {
    color: #212529;
    background-color: #e6e6e6; }
  a.badge-white:focus, a.badge-white.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-main {
  color: #fff;
  background-color: #222222;
  border-color: #222222; }
  .btn-main:hover {
    color: #222222;
    border-color: #222222;
    background-color: #fff; }
  .btn-main:focus, .btn-main.focus {
    color: #fff;
    background-color: #222222;
    border-color: #222222;
    box-shadow: 0 0 0 0.2rem rgba(67, 67, 67, 0.5); }
  .btn-main.disabled, .btn-main:disabled {
    color: #fff;
    background-color: #222222;
    border-color: #222222; }
  .btn-main:not(:disabled):not(.disabled):active, .btn-main:not(:disabled):not(.disabled).active,
  .show > .btn-main.dropdown-toggle {
    color: #fff;
    background-color: #222222;
    border-color: #222222; }
    .btn-main:not(:disabled):not(.disabled):active:focus, .btn-main:not(:disabled):not(.disabled).active:focus,
    .show > .btn-main.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(67, 67, 67, 0.5); }

.btn-accent-01 {
  color: #fff;
  background-color: #8FC323;
  border-color: #8FC323; }
  .btn-accent-01:hover {
    color: #8FC323;
    border-color: #8FC323;
    background-color: #fff; }
  .btn-accent-01:focus, .btn-accent-01.focus {
    color: #fff;
    background-color: #8FC323;
    border-color: #8FC323;
    box-shadow: 0 0 0 0.2rem rgba(160, 204, 68, 0.5); }
  .btn-accent-01.disabled, .btn-accent-01:disabled {
    color: #fff;
    background-color: #8FC323;
    border-color: #8FC323; }
  .btn-accent-01:not(:disabled):not(.disabled):active, .btn-accent-01:not(:disabled):not(.disabled).active,
  .show > .btn-accent-01.dropdown-toggle {
    color: #fff;
    background-color: #8FC323;
    border-color: #8FC323; }
    .btn-accent-01:not(:disabled):not(.disabled):active:focus, .btn-accent-01:not(:disabled):not(.disabled).active:focus,
    .show > .btn-accent-01.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(160, 204, 68, 0.5); }

.btn-accent-02 {
  color: #fff;
  background-color: #0075BF;
  border-color: #0075BF; }
  .btn-accent-02:hover {
    color: #0075BF;
    border-color: #0075BF;
    background-color: #fff; }
  .btn-accent-02:focus, .btn-accent-02.focus {
    color: #fff;
    background-color: #0075BF;
    border-color: #0075BF;
    box-shadow: 0 0 0 0.2rem rgba(38, 138, 201, 0.5); }
  .btn-accent-02.disabled, .btn-accent-02:disabled {
    color: #fff;
    background-color: #0075BF;
    border-color: #0075BF; }
  .btn-accent-02:not(:disabled):not(.disabled):active, .btn-accent-02:not(:disabled):not(.disabled).active,
  .show > .btn-accent-02.dropdown-toggle {
    color: #fff;
    background-color: #0075BF;
    border-color: #0075BF; }
    .btn-accent-02:not(:disabled):not(.disabled):active:focus, .btn-accent-02:not(:disabled):not(.disabled).active:focus,
    .show > .btn-accent-02.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 138, 201, 0.5); }

.btn-accent-03 {
  color: #212529;
  background-color: #ECF9FF;
  border-color: #ECF9FF; }
  .btn-accent-03:hover {
    color: #ECF9FF;
    border-color: #ECF9FF;
    background-color: #fff; }
  .btn-accent-03:focus, .btn-accent-03.focus {
    color: #212529;
    background-color: #ECF9FF;
    border-color: #ECF9FF;
    box-shadow: 0 0 0 0.2rem rgba(206, 217, 223, 0.5); }
  .btn-accent-03.disabled, .btn-accent-03:disabled {
    color: #212529;
    background-color: #ECF9FF;
    border-color: #ECF9FF; }
  .btn-accent-03:not(:disabled):not(.disabled):active, .btn-accent-03:not(:disabled):not(.disabled).active,
  .show > .btn-accent-03.dropdown-toggle {
    color: #212529;
    background-color: #ECF9FF;
    border-color: #ECF9FF; }
    .btn-accent-03:not(:disabled):not(.disabled):active:focus, .btn-accent-03:not(:disabled):not(.disabled).active:focus,
    .show > .btn-accent-03.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(206, 217, 223, 0.5); }

.btn-color-01 {
  color: #212529;
  background-color: #F5F5F5;
  border-color: #F5F5F5; }
  .btn-color-01:hover {
    color: #F5F5F5;
    border-color: #F5F5F5;
    background-color: #fff; }
  .btn-color-01:focus, .btn-color-01.focus {
    color: #212529;
    background-color: #F5F5F5;
    border-color: #F5F5F5;
    box-shadow: 0 0 0 0.2rem rgba(213, 214, 214, 0.5); }
  .btn-color-01.disabled, .btn-color-01:disabled {
    color: #212529;
    background-color: #F5F5F5;
    border-color: #F5F5F5; }
  .btn-color-01:not(:disabled):not(.disabled):active, .btn-color-01:not(:disabled):not(.disabled).active,
  .show > .btn-color-01.dropdown-toggle {
    color: #212529;
    background-color: #F5F5F5;
    border-color: #F5F5F5; }
    .btn-color-01:not(:disabled):not(.disabled):active:focus, .btn-color-01:not(:disabled):not(.disabled).active:focus,
    .show > .btn-color-01.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(213, 214, 214, 0.5); }

.btn-color-02 {
  color: #212529;
  background-color: #CCCCCC;
  border-color: #CCCCCC; }
  .btn-color-02:hover {
    color: #CCCCCC;
    border-color: #CCCCCC;
    background-color: #fff; }
  .btn-color-02:focus, .btn-color-02.focus {
    color: #212529;
    background-color: #CCCCCC;
    border-color: #CCCCCC;
    box-shadow: 0 0 0 0.2rem rgba(178, 179, 180, 0.5); }
  .btn-color-02.disabled, .btn-color-02:disabled {
    color: #212529;
    background-color: #CCCCCC;
    border-color: #CCCCCC; }
  .btn-color-02:not(:disabled):not(.disabled):active, .btn-color-02:not(:disabled):not(.disabled).active,
  .show > .btn-color-02.dropdown-toggle {
    color: #212529;
    background-color: #CCCCCC;
    border-color: #CCCCCC; }
    .btn-color-02:not(:disabled):not(.disabled):active:focus, .btn-color-02:not(:disabled):not(.disabled).active:focus,
    .show > .btn-color-02.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(178, 179, 180, 0.5); }

.btn-color-03 {
  color: #fff;
  background-color: #9CCC65;
  border-color: #9CCC65; }
  .btn-color-03:hover {
    color: #9CCC65;
    border-color: #9CCC65;
    background-color: #fff; }
  .btn-color-03:focus, .btn-color-03.focus {
    color: #fff;
    background-color: #9CCC65;
    border-color: #9CCC65;
    box-shadow: 0 0 0 0.2rem rgba(171, 212, 124, 0.5); }
  .btn-color-03.disabled, .btn-color-03:disabled {
    color: #fff;
    background-color: #9CCC65;
    border-color: #9CCC65; }
  .btn-color-03:not(:disabled):not(.disabled):active, .btn-color-03:not(:disabled):not(.disabled).active,
  .show > .btn-color-03.dropdown-toggle {
    color: #fff;
    background-color: #9CCC65;
    border-color: #9CCC65; }
    .btn-color-03:not(:disabled):not(.disabled):active:focus, .btn-color-03:not(:disabled):not(.disabled).active:focus,
    .show > .btn-color-03.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(171, 212, 124, 0.5); }

.btn-gray-333 {
  color: #fff;
  background-color: #333;
  border-color: #333; }
  .btn-gray-333:hover {
    color: #333;
    border-color: #333;
    background-color: #fff; }
  .btn-gray-333:focus, .btn-gray-333.focus {
    color: #fff;
    background-color: #333;
    border-color: #333;
    box-shadow: 0 0 0 0.2rem rgba(82, 82, 82, 0.5); }
  .btn-gray-333.disabled, .btn-gray-333:disabled {
    color: #fff;
    background-color: #333;
    border-color: #333; }
  .btn-gray-333:not(:disabled):not(.disabled):active, .btn-gray-333:not(:disabled):not(.disabled).active,
  .show > .btn-gray-333.dropdown-toggle {
    color: #fff;
    background-color: #333;
    border-color: #333; }
    .btn-gray-333:not(:disabled):not(.disabled):active:focus, .btn-gray-333:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-333.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 82, 82, 0.5); }

.btn-gray-100 {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-gray-100:hover {
    color: #f8f9fa;
    border-color: #f8f9fa;
    background-color: #fff; }
  .btn-gray-100:focus, .btn-gray-100.focus {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-gray-100.disabled, .btn-gray-100:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-gray-100:not(:disabled):not(.disabled):active, .btn-gray-100:not(:disabled):not(.disabled).active,
  .show > .btn-gray-100.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-gray-100:not(:disabled):not(.disabled):active:focus, .btn-gray-100:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-100.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-gray-200 {
  color: #212529;
  background-color: #e9ecef;
  border-color: #e9ecef; }
  .btn-gray-200:hover {
    color: #e9ecef;
    border-color: #e9ecef;
    background-color: #fff; }
  .btn-gray-200:focus, .btn-gray-200.focus {
    color: #212529;
    background-color: #e9ecef;
    border-color: #e9ecef;
    box-shadow: 0 0 0 0.2rem rgba(203, 206, 209, 0.5); }
  .btn-gray-200.disabled, .btn-gray-200:disabled {
    color: #212529;
    background-color: #e9ecef;
    border-color: #e9ecef; }
  .btn-gray-200:not(:disabled):not(.disabled):active, .btn-gray-200:not(:disabled):not(.disabled).active,
  .show > .btn-gray-200.dropdown-toggle {
    color: #212529;
    background-color: #e9ecef;
    border-color: #e9ecef; }
    .btn-gray-200:not(:disabled):not(.disabled):active:focus, .btn-gray-200:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-200.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(203, 206, 209, 0.5); }

.btn-gray-300 {
  color: #212529;
  background-color: #dee2e6;
  border-color: #dee2e6; }
  .btn-gray-300:hover {
    color: #dee2e6;
    border-color: #dee2e6;
    background-color: #fff; }
  .btn-gray-300:focus, .btn-gray-300.focus {
    color: #212529;
    background-color: #dee2e6;
    border-color: #dee2e6;
    box-shadow: 0 0 0 0.2rem rgba(194, 198, 202, 0.5); }
  .btn-gray-300.disabled, .btn-gray-300:disabled {
    color: #212529;
    background-color: #dee2e6;
    border-color: #dee2e6; }
  .btn-gray-300:not(:disabled):not(.disabled):active, .btn-gray-300:not(:disabled):not(.disabled).active,
  .show > .btn-gray-300.dropdown-toggle {
    color: #212529;
    background-color: #dee2e6;
    border-color: #dee2e6; }
    .btn-gray-300:not(:disabled):not(.disabled):active:focus, .btn-gray-300:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-300.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(194, 198, 202, 0.5); }

.btn-gray-400 {
  color: #212529;
  background-color: #ced4da;
  border-color: #ced4da; }
  .btn-gray-400:hover {
    color: #ced4da;
    border-color: #ced4da;
    background-color: #fff; }
  .btn-gray-400:focus, .btn-gray-400.focus {
    color: #212529;
    background-color: #ced4da;
    border-color: #ced4da;
    box-shadow: 0 0 0 0.2rem rgba(180, 186, 191, 0.5); }
  .btn-gray-400.disabled, .btn-gray-400:disabled {
    color: #212529;
    background-color: #ced4da;
    border-color: #ced4da; }
  .btn-gray-400:not(:disabled):not(.disabled):active, .btn-gray-400:not(:disabled):not(.disabled).active,
  .show > .btn-gray-400.dropdown-toggle {
    color: #212529;
    background-color: #ced4da;
    border-color: #ced4da; }
    .btn-gray-400:not(:disabled):not(.disabled):active:focus, .btn-gray-400:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-400.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(180, 186, 191, 0.5); }

.btn-gray-500 {
  color: #fff;
  background-color: #adb5bd;
  border-color: #adb5bd; }
  .btn-gray-500:hover {
    color: #adb5bd;
    border-color: #adb5bd;
    background-color: #fff; }
  .btn-gray-500:focus, .btn-gray-500.focus {
    color: #fff;
    background-color: #adb5bd;
    border-color: #adb5bd;
    box-shadow: 0 0 0 0.2rem rgba(185, 192, 199, 0.5); }
  .btn-gray-500.disabled, .btn-gray-500:disabled {
    color: #fff;
    background-color: #adb5bd;
    border-color: #adb5bd; }
  .btn-gray-500:not(:disabled):not(.disabled):active, .btn-gray-500:not(:disabled):not(.disabled).active,
  .show > .btn-gray-500.dropdown-toggle {
    color: #fff;
    background-color: #adb5bd;
    border-color: #adb5bd; }
    .btn-gray-500:not(:disabled):not(.disabled):active:focus, .btn-gray-500:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-500.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(185, 192, 199, 0.5); }

.btn-gray-600 {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-gray-600:hover {
    color: #6c757d;
    border-color: #6c757d;
    background-color: #fff; }
  .btn-gray-600:focus, .btn-gray-600.focus {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-gray-600.disabled, .btn-gray-600:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-gray-600:not(:disabled):not(.disabled):active, .btn-gray-600:not(:disabled):not(.disabled).active,
  .show > .btn-gray-600.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-gray-600:not(:disabled):not(.disabled):active:focus, .btn-gray-600:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-600.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-gray-700 {
  color: #fff;
  background-color: #495057;
  border-color: #495057; }
  .btn-gray-700:hover {
    color: #495057;
    border-color: #495057;
    background-color: #fff; }
  .btn-gray-700:focus, .btn-gray-700.focus {
    color: #fff;
    background-color: #495057;
    border-color: #495057;
    box-shadow: 0 0 0 0.2rem rgba(100, 106, 112, 0.5); }
  .btn-gray-700.disabled, .btn-gray-700:disabled {
    color: #fff;
    background-color: #495057;
    border-color: #495057; }
  .btn-gray-700:not(:disabled):not(.disabled):active, .btn-gray-700:not(:disabled):not(.disabled).active,
  .show > .btn-gray-700.dropdown-toggle {
    color: #fff;
    background-color: #495057;
    border-color: #495057; }
    .btn-gray-700:not(:disabled):not(.disabled):active:focus, .btn-gray-700:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-700.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(100, 106, 112, 0.5); }

.btn-gray-800 {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-gray-800:hover {
    color: #343a40;
    border-color: #343a40;
    background-color: #fff; }
  .btn-gray-800:focus, .btn-gray-800.focus {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-gray-800.disabled, .btn-gray-800:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-gray-800:not(:disabled):not(.disabled):active, .btn-gray-800:not(:disabled):not(.disabled).active,
  .show > .btn-gray-800.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-gray-800:not(:disabled):not(.disabled):active:focus, .btn-gray-800:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-800.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-gray-900 {
  color: #fff;
  background-color: #212529;
  border-color: #212529; }
  .btn-gray-900:hover {
    color: #212529;
    border-color: #212529;
    background-color: #fff; }
  .btn-gray-900:focus, .btn-gray-900.focus {
    color: #fff;
    background-color: #212529;
    border-color: #212529;
    box-shadow: 0 0 0 0.2rem rgba(66, 70, 73, 0.5); }
  .btn-gray-900.disabled, .btn-gray-900:disabled {
    color: #fff;
    background-color: #212529;
    border-color: #212529; }
  .btn-gray-900:not(:disabled):not(.disabled):active, .btn-gray-900:not(:disabled):not(.disabled).active,
  .show > .btn-gray-900.dropdown-toggle {
    color: #fff;
    background-color: #212529;
    border-color: #212529; }
    .btn-gray-900:not(:disabled):not(.disabled):active:focus, .btn-gray-900:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-900.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(66, 70, 73, 0.5); }

.btn-twitter {
  color: #fff;
  background-color: #1da1f2;
  border-color: #1da1f2; }
  .btn-twitter:hover {
    color: #1da1f2;
    border-color: #1da1f2;
    background-color: #fff; }
  .btn-twitter:focus, .btn-twitter.focus {
    color: #fff;
    background-color: #1da1f2;
    border-color: #1da1f2;
    box-shadow: 0 0 0 0.2rem rgba(63, 175, 244, 0.5); }
  .btn-twitter.disabled, .btn-twitter:disabled {
    color: #fff;
    background-color: #1da1f2;
    border-color: #1da1f2; }
  .btn-twitter:not(:disabled):not(.disabled):active, .btn-twitter:not(:disabled):not(.disabled).active,
  .show > .btn-twitter.dropdown-toggle {
    color: #fff;
    background-color: #1da1f2;
    border-color: #1da1f2; }
    .btn-twitter:not(:disabled):not(.disabled):active:focus, .btn-twitter:not(:disabled):not(.disabled).active:focus,
    .show > .btn-twitter.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(63, 175, 244, 0.5); }

.btn-white {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff; }
  .btn-white:hover {
    color: #ffffff;
    border-color: #ffffff;
    background-color: #fff; }
  .btn-white:focus, .btn-white.focus {
    color: #212529;
    background-color: #ffffff;
    border-color: #ffffff;
    box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }
  .btn-white.disabled, .btn-white:disabled {
    color: #212529;
    background-color: #ffffff;
    border-color: #ffffff; }
  .btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active,
  .show > .btn-white.dropdown-toggle {
    color: #212529;
    background-color: #ffffff;
    border-color: #ffffff; }
    .btn-white:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }

#contact-form section p {
  line-height: 2rem; }

#contact-form .dcms_error_area p {
  line-height: 2rem !important; }
  #contact-form .dcms_error_area p.error_text {
    display: block; }

#contact-form .dcms_error_area + div {
  float: left;
  margin-bottom: 1rem;
  width: 100%;
  height: 0; }

#contact-form input[type='text'] {
  width: 100%; }
  #contact-form input[type='text'].family_name, #contact-form input[type='text'].first_name {
    width: 45%; }
    @media (max-width: 991.98px) {
      #contact-form input[type='text'].family_name, #contact-form input[type='text'].first_name {
        width: 100%; } }
  #contact-form input[type='text'].family_name_yomi, #contact-form input[type='text'].first_name_yomi {
    width: 43%; }
    @media (max-width: 991.98px) {
      #contact-form input[type='text'].family_name_yomi, #contact-form input[type='text'].first_name_yomi {
        width: 100%; } }

#contact-form textarea {
  max-width: 100%;
  width: 100%; }

#contact-form #table_address tr td {
  padding: 8px 0; }
  #contact-form #table_address tr td:first-child {
    min-width: 6rem;
    line-height: 1.4; }

#dcms_layoutPageBlockPasteArea p {
  line-height: 1.75;
  color: #333; }

#dcms_layoutPageBlockPasteArea .text-white p {
  color: #fff; }

#dcms_global_header .dropdown-toggle::after {
  display: none; }

h1, .h1 {
  margin-bottom: 1rem; }

h2, .h2 {
  margin-bottom: 1.25rem;
  line-height: 1.5 !important; }
  @media (min-width: 576px) {
    h2, .h2 {
      margin-bottom: 1.5rem; } }
  @media (min-width: 992px) {
    h2, .h2 {
      margin-bottom: 1.5rem; } }

h3, .h3 {
  margin-bottom: 1.25rem;
  line-height: 1.5 !important; }

h4, .h4 {
  margin-bottom: 1rem;
  line-height: 1.5 !important; }

h5, .h5 {
  margin-bottom: 1rem;
  line-height: 1.5 !important; }

h6, .h6 {
  margin-bottom: 1rem;
  line-height: 1.5; }

small, .small {
  font-size: 90%; }

@media (min-width: 768px) {
  h1, .h1 {
    font-size: 3.1rem; }
  h2, .h2 {
    font-size: 2rem; }
  h3, .h3 {
    font-size: 1.75rem; }
  h4, .h4 {
    font-size: 1.5rem; }
  h5, .h5 {
    font-size: 1.25rem; }
  h6, .h6 {
    font-size: 1rem; } }

@media (max-width: 991.98px) {
  h1, .h1 {
    font-size: 2.79rem; }
  h2, .h2 {
    font-size: 1.8rem; }
  h3, .h3 {
    font-size: 1.575rem; }
  h4, .h4 {
    font-size: 1.35rem; }
  h5, .h5 {
    font-size: 1.1875rem; }
  h6, .h6 {
    font-size: 1rem; } }

@media (max-width: 767.98px) {
  h1, .h1 {
    font-size: 1.86rem; }
  h2, .h2 {
    font-size: 1.4rem; }
  h3, .h3 {
    font-size: 1.3125rem; }
  h4, .h4 {
    font-size: 1.275rem; }
  h5, .h5 {
    font-size: 1.1875rem; }
  h6, .h6 {
    font-size: 1rem; } }

.dlb_title-copy {
  font-size: 3.1rem; }
  @media (min-width: 768px) {
    .dlb_title-copy {
      font-size: 3.1rem; } }
  @media (max-width: 991.98px) {
    .dlb_title-copy {
      font-size: 2.17rem; } }
  @media (max-width: 767.98px) {
    .dlb_title-copy {
      font-size: 1.86rem; } }

.dlb_title-sub-01 {
  border-left-color: #222222; }
  .dlb_title-sub-01::after {
    background-color: #ddd; }

.dlb_title-sub-02 {
  border-bottom-color: #222222;
  border-left-color: #222222; }

.dlb_title-sub-03 {
  border-left-color: #222222; }

.dlb_title-sub-04::before {
  background-color: #222222; }

.dlb_title-sub-05 {
  border-bottom-color: #222222; }

.dlb_title-sub-06::after {
  background-color: #222222; }

.dlb_title-sub-07 {
  background-color: #222222;
  color: #fff; }

.dlb_title-blog {
  color: #222222; }
  @media (min-width: 768px) {
    .dlb_title-blog {
      font-size: 2rem; } }
  @media (max-width: 991.98px) {
    .dlb_title-blog {
      font-size: 1.8rem; } }
  @media (max-width: 767.98px) {
    .dlb_title-blog {
      font-size: 1.4rem; } }

.title-03 {
  position: relative;
  border-bottom: 1px solid #ddd;
  padding-left: 19px;
  padding-bottom: 22px;
  margin-bottom: 2rem;
  font-size: 1.625rem;
  padding-top: 2px;
  color: #777; }
  .title-03::after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0;
    display: block;
    width: 5px;
    height: 35px;
    background-color: #222222; }

.border_custom-01 {
  background-color: #0075BF;
  padding-left: 1rem; }
  .border_custom-01 .d-table-cell {
    background-color: #fff;
    border: 1px solid #ccc;
    border-left: none; }

.badge {
  padding: 0.3em 0.4em; }

.stretched-link::after {
  content: none !important; }

.stretched-link a {
  color: inherit !important;
  text-decoration: none; }
  .stretched-link a::after {
    position: absolute;
    top: 0;
    right: 15px;
    bottom: 0;
    left: 15px;
    z-index: 1;
    pointer-events: auto;
    content: '';
    background-color: rgba(0, 0, 0, 0); }

.js-matchHeight .js-matchHeight-middle {
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center; }

.container {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem; }

section {
  overflow: visible; }

.img-fluid {
  margin-bottom: 0; }
  .img-fluid img {
    width: 100%;
    max-width: 100%;
    height: auto; }

.figure img {
  margin-bottom: 0.5rem;
  line-height: 1; }

div.btn {
  padding: 0; }
  div.btn a {
    display: block;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 24px;
    padding-right: 24px;
    background-color: transparent;
    border: none;
    color: inherit;
    line-height: 1.6;
    text-decoration: inherit; }
  div.btn.btn-lg a {
    padding-top: 16px;
    padding-bottom: 16px; }
  div.btn.d-table a {
    display: table-cell;
    vertical-align: middle; }

.rounded-sm,
.rounded,
.rounded-top,
.rounded-right,
.rounded-bottom,
.rounded-left,
.rounded-lg,
.rounded-circle,
.rounded-pill {
  overflow: hidden; }

.__rounded,
.__img-rounded img {
  border-radius: 10px; }

.__rounded100,
.__img-rounded100 img {
  border-radius: 100vh; }

.x-scroll {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

.collapsed img {
  transform: rotate(180deg); }

ol {
  margin-bottom: 0; }

.disable-auto-tel a[href^='tel:'] {
  color: inherit;
  text-decoration: none;
  pointer-events: none; }

.bm_title-01 {
  margin-bottom: 24px;
  text-align: center; }

.bm_title-02 {
  margin-bottom: 16px;
  text-align: center; }

.bm_navbar {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem; }
  .bm_navbar + .bs_header-spacer {
    height: 52px; }
  .bm_navbar .bm_logo {
    padding: 0; }
    .bm_navbar .bm_logo img {
      max-height: 35px;
      width: auto;
      vertical-align: top; }
  .bm_navbar .bm_navbar-toggle ul {
    padding: 1.5rem;
    text-align: center; }
  .bm_navbar .bm_navbar-toggle a {
    display: block;
    padding: 0.5rem;
    color: #ffffff; }

.bm_header {
  text-align: center; }

.bm_footer {
  padding-top: 1rem;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-bottom: 3rem;
  text-align: center; }
  .bm_footer .bm_footer-list {
    list-style: none; }
    .bm_footer .bm_footer-list li {
      display: inline; }
      .bm_footer .bm_footer-list li a {
        display: inline-block;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        border-left: 1px solid #aaa; }
      .bm_footer .bm_footer-list li:first-child a {
        border-left: none; }
  .bm_footer .bm_copy {
    display: block;
    margin-bottom: 1rem; }

body {
  display: flex;
  flex-direction: column; }

#wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column; }

#dcms_layoutPageBlockPasteArea,
#container {
  flex: 1 0 auto; }

.navbar-toggler {
  padding: 0; }
  .navbar-toggler.navbar-toggler-icon {
    width: 2.85em;
    height: 2.2em;
    vertical-align: middle;
    background: no-repeat center center;
    background-size: 30px 30px;
    background-position: 50% 0; }
    .navbar-dark .navbar-toggler.navbar-toggler-icon {
      background-image: url("/dcms_media/other/sp-nav-btn.svg"); }
    .navbar-light .navbar-toggler.navbar-toggler-icon {
      background-image: url("/dcms_media/other/sp-nav-btn.svg"); }
    .navbar-toggler.navbar-toggler-icon::after {
      content: 'メニュー';
      display: block;
      font-size: 10px;
      font-weight: bold;
      margin-top: 24px;
      color: #333; }

.bs_text-link a {
  text-indent: -16px; }

.bs_text-link a::before {
  content: '';
  position: relative;
  top: -2px;
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 0.5rem;
  border-top: 2px solid #9CCC65;
  border-right: 2px solid #9CCC65;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.text-white .bs_text-link a::before {
  border-top-color: #fff;
  border-right-color: #fff; }

.bs_text-link.btn-link {
  text-align: left; }

.header-tel {
  white-space: nowrap;
  display: flex;
  align-items: center; }
  @media (max-width: 991.98px) {
    .header-tel {
      width: 100%;
      text-align: center;
      justify-content: center;
      margin-bottom: 10px; } }
  @media screen and (min-width: 992px) and (max-width: 1320px) {
    .header-tel {
      padding-right: 0.5rem; }
      .header-tel .nav-btn {
        text-align: right; } }
  .header-tel .btn {
    cursor: default !important; }
  .header-tel .tel {
    display: inline-block;
    padding-left: 24px;
    background-position: 0 center;
    background-repeat: no-repeat;
    background-image: url(/dcms_media/image/common/telephone-fill.png);
    font-weight: bold;
    font-size: 20px;
    line-height: 20px; }
    .header-tel .tel a {
      padding: 0;
      cursor: default !important; }
    @media (max-width: 991.98px) {
      .header-tel .tel {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 4px;
        padding-left: 24px; } }
  .header-tel .small {
    display: block;
    font-size: 10px;
    color: #777; }
    @media (max-width: 991.98px) {
      .header-tel .small {
        font-size: .9rem; } }

.icon-mail a:before {
  content: "";
  background: url(/dcms_media/image/common/icon-mail.png) 0 0 no-repeat;
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-right: 6px;
  vertical-align: middle; }

.icon-mail:hover a:before {
  background: url(/dcms_media/image/common/icon-mail-on.png) 0 0 no-repeat; }

.icon-download a:before {
  content: '';
  background: url(/dcms_media/image/common/icon-download.png) 0 0 no-repeat;
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-right: 6px;
  vertical-align: middle; }

.dropdown-menu {
  top: 112px;
  left: 0;
  width: 100%; }

.dropdown:hover .nav-link {
  color: #0075BF !important; }
  .dropdown:hover .nav-link::after {
    width: 50% !important; }

.dropdown:hover > .dropdown-menu {
  display: block;
  border: none; }
  @media (max-width: 991.98px) {
    .dropdown:hover > .dropdown-menu {
      display: none; } }

.dropdown-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 25px 0; }
  .dropdown-header .__title {
    font-size: 1.875rem;
    margin-right: 1em; }
  .dropdown-header .btn {
    margin-left: auto; }

.dropdown-body {
  background-color: rgba(34, 34, 34, 0.9);
  padding: 30px 0 50px; }
  .dropdown-body .rounded {
    border-radius: 10px; }
    .dropdown-body .rounded img {
      border-radius: 10px; }
  .dropdown-body .__dropdown-grid5 {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px; }
  .dropdown-body .__dropdown-grid32 {
    display: grid;
    grid-template-columns: 60% 40%;
    gap: 20px;
    grid-template-areas: "box3 box2"; }
  .dropdown-body .box3 {
    grid-area: box3; }
  .dropdown-body .box2 {
    grid-area: box2; }
  .dropdown-body .__dropdown-grid3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px; }
  .dropdown-body .__dropdown-grid2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px; }

.bs_header-01 {
  padding: 0;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
  background-color: #fff; }
  .bs_header-01 .navbar-brand {
    padding: 0;
    margin-right: 0; }
    .bs_header-01 .navbar-brand img {
      width: auto;
      height: 80px;
      transition: opacity 0.2s ease; }
      @media (max-width: 1199.98px) {
        .bs_header-01 .navbar-brand img {
          height: 41px; } }
    .bs_header-01 .navbar-brand:hover img {
      opacity: 0.8; }
  .bs_header-01.navbar-light .navbar-toggler {
    border: none; }
  .bs_header-01.navbar-light .navbar-nav a.nav-link {
    color: #333;
    font-weight: 600;
    display: flex;
    align-items: center;
    position: relative; }
    .bs_header-01.navbar-light .navbar-nav a.nav-link::after {
      content: "";
      width: 0;
      height: 2px;
      background-color: #fff;
      position: absolute;
      bottom: 20px;
      left: 25%; }
    .bs_header-01.navbar-light .navbar-nav a.nav-link:hover, .bs_header-01.navbar-light .navbar-nav a.nav-link:active, .bs_header-01.navbar-light .navbar-nav a.nav-link:focus {
      color: #0075BF !important; }
      .bs_header-01.navbar-light .navbar-nav a.nav-link:hover::after, .bs_header-01.navbar-light .navbar-nav a.nav-link:active::after, .bs_header-01.navbar-light .navbar-nav a.nav-link:focus::after {
        width: 50%; }
    @media (min-width: 992px) {
      .bs_header-01.navbar-light .navbar-nav a.nav-link {
        height: 72px; } }
  @media (max-width: 991.98px) {
    .bs_header-01 .__spinfo {
      display: flex;
      flex-direction: column; }
      .bs_header-01 .__spinfo .header-tel {
        order: 1; }
      .bs_header-01 .__spinfo .icon-book {
        order: 3;
        margin-top: 10px; }
      .bs_header-01 .__spinfo .icon-mail {
        order: 2; } }
  .bs_header-01 .dropdown-item:hover, .bs_header-01 .dropdown-item:focus {
    color: #0075BF !important; }
  .bs_header-01 .dropdown-item:active {
    color: #0075BF !important;
    background-color: transparent; }
  @media (max-width: 991.98px) {
    .bs_header-01 .nav-btn img {
      display: none; } }
  @media (max-width: 991.98px) {
    .bs_header-01 .nav-btn img {
      display: none; } }
  .bs_header-01 + .bs_header-spacer {
    height: 72px; }
    @media (max-width: 991.98px) {
      .bs_header-01 + .bs_header-spacer {
        height: 44px; } }
  .bs_header-01.bs_header-01-drop .navbar-nav {
    min-height: 87px; }
  @media (min-width: 992px) {
    .bs_header-01.bs_header-01-drop.navbar-light .navbar-nav a.nav-link {
      height: 112px; } }
  .bs_header-01.bs_header-01-drop + .bs_header-spacer {
    height: 87px; }
    @media (max-width: 991.98px) {
      .bs_header-01.bs_header-01-drop + .bs_header-spacer {
        height: 50px; } }
    @media screen and (min-width: 992px) {
      .bs_header-01.bs_header-01-drop + .bs_header-spacer {
        height: 112px; } }
  @media screen and (min-width: 992px) {
    .bs_header-01.bs_header-01-drop #navbar-01 {
      flex-flow: column; }
      .bs_header-01.bs_header-01-drop #navbar-01 .navbar-nav {
        order: 2;
        margin-right: 0;
        padding: 0 !important;
        min-height: 61px; }
        .bs_header-01.bs_header-01-drop #navbar-01 .navbar-nav a.nav-link {
          height: 61px; }
      .bs_header-01.bs_header-01-drop #navbar-01 > div {
        padding-top: 8px !important;
        margin-left: auto; } }
  @media (min-width: 1200px) {
    .bs_header-01 .container-xl {
      max-width: 1300px; } }
  @media (max-width: 991.98px) {
    .bs_header-01 .navbar-nav {
      flex-direction: row;
      flex-wrap: wrap;
      padding-bottom: 0 !important; }
    .bs_header-01 .nav-item {
      flex: 1 0 40%;
      max-width: 50%; } }
  @media (max-width: 767.98px) {
    .bs_header-01 .nav-item {
      flex: 0 0 100%;
      max-width: none; } }

@media (max-width: 767.98px) {
  .bs_footer-01 .bs_footer-icon img {
    width: auto;
    height: 38px; } }

.bs_footer-01 .bs_footer-logo {
  flex: 0 0 auto;
  width: auto; }
  .bs_footer-01 .bs_footer-logo:hover img {
    opacity: 0.8; }
  .bs_footer-01 .bs_footer-logo img {
    transition: opacity 0.2s ease; }

@media (min-width: 992px) {
  .bs_footer-01 .bs_copyright {
    font-size: .9rem; } }

.bs_header-02 {
  padding: 0;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1); }
  .bs_header-02 .navbar-brand {
    padding: 0;
    margin-right: 0; }
    .bs_header-02 .navbar-brand img {
      width: auto;
      height: 50px;
      transition: opacity 0.2s ease; }
    .bs_header-02 .navbar-brand:hover img {
      opacity: 0.8; }
  @media (max-width: 991.98px) {
    .bs_header-02 .nav-btn img {
      display: none; } }
  .bs_header-02 + .bs_header-spacer {
    height: 72px; }
    @media (max-width: 991.98px) {
      .bs_header-02 + .bs_header-spacer {
        height: 50px; } }
  @media (min-width: 1200px) {
    .bs_header-02 .container-xl {
      max-width: 1340px; } }
  @media (max-width: 991.98px) {
    .bs_header-02 .navbar-nav {
      flex-direction: row;
      flex-wrap: wrap; }
    .bs_header-02 .nav-item {
      flex: 1 0 40%;
      max-width: 50%; } }
  @media (max-width: 767.98px) {
    .bs_header-02 .nav-item {
      flex: 0 0 100%;
      max-width: none; } }

.bs_footer-02 .bs_footer-contact .bs_footer_contact-title {
  display: flex;
  justify-content: center;
  align-items: center; }

@media (max-width: 767.98px) {
  .bs_footer-02 .bs_footer-icon img {
    width: auto;
    height: 48px; } }

.bs_footer-02 .bs_footer-logo:hover img {
  opacity: 0.8; }

.bs_footer-02 .bs_footer-logo img {
  transition: opacity 0.2s ease; }

.bs_header-03 {
  padding: 1rem; }
  .bs_header-03 .container {
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
    border-radius: 6px; }
  @media (min-width: 1200px) {
    .bs_header-03 .container {
      max-width: 1120px; } }
  .bs_header-03 .navbar-brand {
    padding: 0;
    margin-right: 0; }
    .bs_header-03 .navbar-brand img {
      width: auto;
      height: 50px;
      transition: opacity 0.2s ease; }
    .bs_header-03 .navbar-brand:hover img {
      opacity: 0.8; }
  .bs_header-03 + .bs_header-spacer {
    height: 82px; }
    @media (min-width: 768px) {
      .bs_header-03 + .bs_header-spacer {
        height: 82px; } }
    @media (min-width: 992px) {
      .bs_header-03 + .bs_header-spacer {
        height: 92px; } }
  .bs_header-03 .navbar-toggler {
    position: relative;
    z-index: 1000; }
  .bs_header-03 .navbar-collapse .navbar-collapse-brand {
    margin-left: 1rem; }
    .bs_header-03 .navbar-collapse .navbar-collapse-brand img {
      width: auto;
      height: 50px; }
  .bs_header-03 .navbar-collapse.collapse.show ul {
    padding: 1rem; }
  .bs_header-03 .navbar-collapse.collapsing {
    background-color: #fff; }
    .bs_header-03 .navbar-collapse.collapsing ul {
      padding: 1rem; }
  .bs_header-03 .nav-overflow {
    max-width: 12rem;
    display: inline-block;
    width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: inherit; }
    @media (max-width: 1199.98px) {
      .bs_header-03 .nav-overflow {
        max-width: 8rem; } }
    @media (max-width: 991.98px) {
      .bs_header-03 .nav-overflow {
        max-width: 20rem; } }
    @media (max-width: 767.98px) {
      .bs_header-03 .nav-overflow {
        max-width: 14rem; } }
  @media (max-width: 991.98px) {
    .bs_header-03 .navbar-nav {
      flex-direction: row;
      flex-wrap: wrap; }
    .bs_header-03 .nav-item {
      flex: 1 0 40%;
      max-width: 50%; } }
  @media (max-width: 767.98px) {
    .bs_header-03 .nav-item {
      flex: 0 0 100%;
      max-width: none; } }

@media (min-width: 768px) {
  .bs_footer-03 .bs_footer-contact > div {
    padding-bottom: 43px; }
  .bs_footer-03 .bs_footer-contact .bs_footer-btn {
    position: absolute;
    bottom: 0;
    left: 15px;
    right: 15px;
    width: calc(100% - 30px); }
    .bs_footer-03 .bs_footer-contact .bs_footer-btn a {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center; } }

@media (max-width: 767.98px) {
  .bs_footer-03 .bs_footer-icon img {
    width: auto;
    height: 48px; } }

.bs_footer-03 .bs_footer-logo:hover img {
  opacity: 0.8; }

.bs_footer-03 .bs_footer-logo img {
  transition: opacity 0.2s ease; }

.bs_footer-03 .page_top {
  position: absolute;
  top: -56px;
  left: 50%;
  width: 122px;
  height: 56px;
  transform: translateX(-50%);
  text-align: center;
  text-decoration: none;
  overflow: hidden; }
  .bs_footer-03 .page_top a {
    display: block;
    height: 100%;
    padding-top: 30px; }
  .bs_footer-03 .page_top:before {
    position: absolute;
    top: 16px;
    left: 11px;
    content: '';
    display: block;
    width: 100px;
    height: 100px;
    border-radius: 15px;
    background-color: #CCCCCC;
    transform: rotate(45deg);
    z-index: -1; }

.dlb_contents_wrap-double .dlb_contents_wrap-title {
  padding: 5rem 0 10rem; }

.dlb_contents_wrap-double .dlb_contents_wrap-data {
  margin-top: 0;
  border-radius: 20px; }
  .dlb_contents_wrap-double .dlb_contents_wrap-data .accodion-link {
    position: relative;
    border: none;
    outline: none;
    background-color: transparent;
    background-position: 0 0;
    background-repeat: no-repeat;
    width: 100%;
    text-align: left;
    margin: 20px 0 6px;
    padding: 0 0 0 40px;
    color: #333; }
    .dlb_contents_wrap-double .dlb_contents_wrap-data .accodion-link:hover, .dlb_contents_wrap-double .dlb_contents_wrap-data .accodion-link:active, .dlb_contents_wrap-double .dlb_contents_wrap-data .accodion-link:focus {
      outline: none; }
    .dlb_contents_wrap-double .dlb_contents_wrap-data .accodion-link::before {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      display: inline-block;
      width: 24px;
      height: 24px;
      background-color: #222222;
      border: 1px solid #222222;
      transition: 0.3s ease;
      border-radius: 50%;
      vertical-align: bottom; }
    .dlb_contents_wrap-double .dlb_contents_wrap-data .accodion-link:after {
      content: '';
      position: relative;
      top: 50%;
      margin-top: -2px;
      display: inline-block;
      width: 8px;
      height: 8px;
      min-width: 8px;
      margin-right: 0.5rem;
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
      transform: rotate(-45deg);
      position: absolute;
      top: 11px;
      left: 8px;
      margin-right: 0;
      transition: 0.3s ease;
      transform-origin: center center 0; }
    .dlb_contents_wrap-double .dlb_contents_wrap-data .accodion-link.collapsed::before {
      background-color: #fff;
      border: 1px solid #0075BF; }
    .dlb_contents_wrap-double .dlb_contents_wrap-data .accodion-link.collapsed:after {
      transform: rotate(-225deg);
      top: 8px;
      left: 8px;
      border-color: #222222; }

.dlb_firstview-left {
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 3rem; }
  .dlb_firstview-left .container {
    margin-top: 0;
    margin-bottom: 0; }
  .dlb_firstview-left .img-fluid {
    max-height: 100%;
    padding-bottom: 2rem; }
    .dlb_firstview-left .img-fluid img {
      width: auto; }

.dlb_firstview-center .container {
  margin-top: 0;
  margin-bottom: 0; }

.dlb_contact_column .btn-contact a:before {
  content: '';
  display: inline-block;
  width: 15px;
  height: 16px;
  margin-right: 10px; }

.dlb_contact_column .btn-icon-download a:before {
  content: '';
  display: inline-block;
  width: 25px;
  height: 25px;
  position: relative;
  top: 6px;
  margin-top: -6px;
  margin-right: 10px; }

.dlb_firstview-text:before {
  background-color: #F5F5F5; }

.dlb_firstview-text .bg-img {
  background-color: #CCCCCC; }

.dlb_contents_boxlink-icon .dlb_contents_boxlink-item {
  background-color: #F5F5F5; }
  .dlb_contents_boxlink-icon .dlb_contents_boxlink-item:nth-child(8n+1) {
    background-color: gainsboro; }
  .dlb_contents_boxlink-icon .dlb_contents_boxlink-item:nth-child(8n+3) {
    background-color: gainsboro; }
  .dlb_contents_boxlink-icon .dlb_contents_boxlink-item:nth-child(8n+6) {
    background-color: gainsboro; }
  .dlb_contents_boxlink-icon .dlb_contents_boxlink-item:nth-child(8n+8) {
    background-color: gainsboro; }
  @media (max-width: 767.98px) {
    .dlb_contents_boxlink-icon .dlb_contents_boxlink-item:nth-child(8n+3) {
      background-color: #F5F5F5; }
    .dlb_contents_boxlink-icon .dlb_contents_boxlink-item:nth-child(8n+4) {
      background-color: gainsboro; }
    .dlb_contents_boxlink-icon .dlb_contents_boxlink-item:nth-child(8n+5) {
      background-color: gainsboro; }
    .dlb_contents_boxlink-icon .dlb_contents_boxlink-item:nth-child(8n+6) {
      background-color: #F5F5F5; } }

.dlb_contents_box_column-2 .dlb_contents_box-title {
  border-bottom: 1px solid #222222;
  color: #222222;
  padding-bottom: 16px; }
  @media (min-width: 576px) {
    .dlb_contents_box_column-2 .dlb_contents_box-title {
      border-bottom: none;
      border-right: 1px solid #222222;
      padding-bottom: 0; } }

.dlb_contents_box_bg .dlb_contents_box_bg-item {
  background-color: #F5F5F5; }
  .dlb_contents_box_bg .dlb_contents_box_bg-item:nth-child(4n+1) {
    background-color: gainsboro; }
  .dlb_contents_box_bg .dlb_contents_box_bg-item:nth-child(4n+4) {
    background-color: gainsboro; }
  @media (max-width: 991.98px) {
    .dlb_contents_box_bg .dlb_contents_box_bg-item:nth-child(4n+3) {
      background-color: gainsboro; }
    .dlb_contents_box_bg .dlb_contents_box_bg-item:nth-child(4n+4) {
      background-color: #F5F5F5; } }
  @media (max-width: 767.98px) {
    .dlb_contents_box_bg .dlb_contents_box_bg-item:nth-child(4n+3) {
      background-color: gainsboro; }
    .dlb_contents_box_bg .dlb_contents_box_bg-item:nth-child(4n+4) {
      background-color: #F5F5F5; } }

.dlb_contents_overflow .dlb_contents_overflow-right:before {
  background-color: #F5F5F5; }

@media (max-width: 767.98px) {
  .dlb_contents_overflow .dlb_contents_overflow-right .dlb_contents_overflow-data {
    background-color: #F5F5F5; } }

.dlb_contents_overflow .dlb_contents_overflow-left:before {
  background-color: #F5F5F5; }

@media (max-width: 767.98px) {
  .dlb_contents_overflow .dlb_contents_overflow-left .dlb_contents_overflow-data {
    background-color: #F5F5F5; } }

.dlb_flow-1 .dlb_flow-item + .dlb_flow-item::before {
  border-color: transparent transparent transparent #0075BF; }

.dlb_flow-2 .dlb_flow-item + .dlb_flow-item .dlb_flow-title::before {
  border-color: #0075BF transparent transparent transparent; }

.dlb_flow-2 .dlb_flow-item .dlb_flow-data {
  border-color: #CCCCCC !important; }

.dlb_flow-3 .dlb_flow-item .dlb_flow-title::before {
  background: linear-gradient(to bottom left, #222222 49%, transparent 50%) top left/50% 100% no-repeat, linear-gradient(to bottom right, #222222 49%, transparent 50%) top right/50% 100% no-repeat !important; }

.dlb_flow-4 .dlb_flow-item + .dlb_flow-item .dlb_flow-title::before {
  border-color: #0075BF transparent transparent transparent; }

@media (min-width: 576px) {
  .dlb_flow-4 .dlb_flow-item .dlb_flow-data {
    border-color: #CCCCCC !important; } }

.dlb_flow-5 .dlb_flow-item + .dlb_flow-item::before {
  border-color: #0075BF transparent transparent transparent; }

.dlb_flow-5 .dlb_flow-label {
  background-color: #CCCCCC; }
  .dlb_flow-5 .dlb_flow-label:before {
    border-color: transparent #999999 transparent transparent; }

.dlb_news_pickup .dlb_news_pickup-figure figcaption {
  background-color: #9CCC65;
  color: #fff; }

.dlb_table_stack .dlb_table_stack-data {
  border-color: #F5F5F5; }

.dlb_contact-area .col {
  border-color: #9CCC65; }
  @media (max-width: 991.98px) {
    .dlb_contact-area .col + .col {
      border-left-color: #9CCC65; } }

.dlb_contact-area .dlb_contact-list li {
  border-bottom-color: #9CCC65; }

.dlb_contact-area .dlb_contact-text {
  background-color: #F5F5F5; }
  .dlb_contact-area .dlb_contact-text:after {
    border-color: transparent transparent transparent #F5F5F5; }

.dlb_text_wrap .dlb_text_wrap-title {
  bottom: 20px; }
  @media (max-width: 767.98px) {
    .dlb_text_wrap .dlb_text_wrap-title {
      bottom: 8px; } }

@media (min-width: 768px) {
  .dlb_box-arrow {
    position: relative; }
    .dlb_box-arrow::after {
      position: absolute;
      top: 50%;
      right: -20px;
      margin-top: -20px;
      z-index: 100;
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 20px 0px 20px 20px;
      border-color: transparent transparent transparent #0075BF; } }

.dlb_news .dlb_news-list .list-group-item {
  background: none; }

@media (max-width: 575.98px) {
  .dlb_news .rss_date {
    padding: 4px 0; } }

.dlb_news .rss_title .rss_news {
  flex: 0 0 130px;
  overflow: hidden;
  text-align: center; }
  @media (max-width: 575.98px) {
    .dlb_news .rss_title .rss_news {
      position: absolute;
      top: -32px;
      left: 100px;
      width: 130px; } }

@media (max-width: 767.98px) {
  .dlb_news .rss_title {
    flex-flow: column;
    justify-items: start; } }

.dlb_news .rss_title.stretched-link a:after {
  left: 0;
  right: 0; }

.dlb_blog_column-4 .dlb_blog-data .dlb_title-h3 {
  max-height: 4rem; }

article {
  padding: 0; }

.list-indent li {
  padding-left: 41px;
  text-indent: -41px;
  color: #777; }

.list-indent.text-white li {
  color: inherit; }

.list-indent-01 li {
  padding-left: 0.6rem;
  text-indent: -0.6rem;
  color: #777; }

.list-indent-01.text-white li {
  color: inherit; }

.text-white .list-indent li,
.text-white .list-indent-01 li {
  color: inherit; }

.privacy-title {
  padding-left: 1.1em;
  text-indent: -1.1em; }
  @media (max-width: 767.98px) {
    .privacy-title {
      padding-left: 1em;
      text-indent: -1em; } }

.js_open,
.js_morelink {
  display: none; }

#locator_t_under,
#locator,
#locator_template_c2 {
  margin-top: 0; }

.blog_search {
  margin-bottom: 24px;
  width: 100%;
  border-width: 2px;
  border-style: solid; }
  .blog_search input {
    display: block;
    padding: 0 4%;
    border-radius: 0;
    height: 48px;
    border: none; }
    .blog_search input.search_txt {
      float: left;
      line-height: 36px;
      width: 82%; }
    .blog_search input.submit_btn {
      background-repeat: no-repeat;
      background-position: center;
      background-size: 24px auto;
      border: medium none;
      cursor: pointer;
      float: right;
      padding: 0;
      text-indent: -9999px;
      width: 18%; }

.side {
  margin-bottom: 16px; }
  .side li {
    list-style: none; }
  .side .title {
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    margin-bottom: 0;
    padding: 10px; }
  .side .dlb_blog-item {
    position: relative; }
  .side ul,
  .side .reco {
    padding-left: 0px; }
  .side .reco {
    margin-top: 20px; }
    .side .reco li {
      margin-bottom: 10px; }
      .side .reco li .reco_thumbnail {
        float: left;
        width: 35%;
        margin-bottom: 0; }
        .side .reco li .reco_thumbnail img {
          max-width: 100%;
          height: auto;
          width: auto;
          display: block;
          margin: auto; }
      .side .reco li .info {
        float: right;
        width: 62%;
        font-size: 0.875rem;
        font-weight: bold;
        line-height: 1.3; }
      .side .reco li a {
        display: block;
        color: #222222; }
        .side .reco li a:hover .info {
          text-decoration: underline; }
      @media only screen and (min-width: 691px) and (max-width: 979px) {
        .side .reco li {
          width: 48%;
          float: left;
          margin-right: 4%;
          margin-bottom: 0;
          margin-top: 15px; }
          .side .reco li:nth-child(2n) {
            margin-right: 0; } }
    @media only screen and (min-width: 691px) and (max-width: 979px) {
      .side .reco {
        margin-top: 5px;
        margin-bottom: 20px; } }
  .side .sweep {
    padding: 20px 15px; }
    .side .sweep li {
      display: inline-block;
      margin: 2px 2px 2px 0; }
  .side .archive li {
    display: block;
    margin: 6px 0 6px 0; }
    .side .archive li:first-child {
      margin-top: 0; }
    .side .archive li:last-child {
      margin-bottom: 0; }
    .side .archive li a {
      position: relative;
      display: block;
      font-size: 0.875rem;
      font-weight: bold;
      color: #222222; }
      .side .archive li a:before {
        width: 6px;
        height: 6px;
        display: none; }
    @media only screen and (min-width: 691px) and (max-width: 979px) {
      .side .archive li {
        display: inline-block;
        margin-right: 10px; } }
  .side .cate li a {
    display: block;
    font-size: 0.875rem;
    line-height: 1.2;
    padding: 3px 6px 4px;
    border-radius: 0.25rem;
    font-weight: bold; }
  .side .tag li {
    margin: 0 10px 6px 0; }
    .side .tag li a {
      display: block;
      font-size: 0.875rem;
      line-height: 1.2;
      padding: 3px 6px 4px;
      font-weight: bold;
      border-radius: 0.25rem; }
      .side .tag li a:hover {
        text-decoration: underline; }
  .side .btn {
    width: 100%;
    font-size: 0.875rem;
    position: relative;
    padding: 13px 2%;
    font-weight: bold; }
    .side .btn:hover {
      text-decoration: none; }
    .side .btn span {
      position: relative; }
      .side .btn span:before {
        width: 8px;
        height: 8px;
        margin-top: -4px; }
  .side .banner li {
    margin: 3px 0;
    list-style: none; }
    .side .banner li:first-child {
      margin-top: -3px; }
    .side .banner li img {
      max-width: 100%;
      height: auto;
      width: auto;
      margin: auto;
      display: block; }

.fb-page {
  margin-bottom: 1.5rem; }

#sitemap li {
  margin: 10px 0;
  padding: 0 0 0 15px;
  list-style-type: none;
  position: relative; }

#sitemap li::before {
  content: '';
  width: 8px;
  height: 8px;
  border-top: 2px solid #8FC323;
  border-right: 2px solid #8FC323;
  transform: rotate(45deg);
  position: absolute;
  top: 8px;
  left: 0; }

#sitemap ul {
  padding-left: 5px;
  margin-top: 15px; }

.text-inherit a {
  color: inherit; }

article .info time {
  font-size: 0.875rem; }

article#main > div:not(.catelist):not(.taglist) {
  margin-bottom: 0; }

.catelist a,
.taglist a {
  font-size: 0.875rem; }

.pager span,
.pager a {
  vertical-align: baseline;
  font-size: 1.125rem; }

.archivelist .list a {
  padding: 6px 15px 6px; }

.sns_share.foot {
  padding-left: 0; }

.text-white a {
  color: #fff !important; }

.text-black a {
  color: #000 !important; }

.overflow-unset {
  overflow: unset; }

#locator,
#locator_template_c2 {
  font-size: 10px;
  line-height: 1.4;
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: #f5f5f5;
  background-color: #f5f5f5;
  width: 100vw;
  width: 100%;
  scrollbar-width: thin; }
  #locator li,
  #locator_template_c2 li {
    display: inline-block;
    list-style: none;
    margin-right: 8px;
    position: relative; }
    #locator li::after,
    #locator_template_c2 li::after {
      content: '>';
      margin-left: 8px;
      font-size: .875rem;
      font-weight: 500; }
    #locator li:last-child::after,
    #locator_template_c2 li:last-child::after {
      display: none; }
    #locator li a,
    #locator_template_c2 li a {
      color: #333;
      font-size: .875rem;
      font-weight: 500; }
    #locator li span,
    #locator_template_c2 li span {
      font-size: .875rem;
      font-weight: 500; }
  #locator .inner,
  #locator_template_c2 .inner {
    width: 94%;
    margin-right: auto;
    margin-left: auto;
    max-width: 1120px;
    padding-left: 0;
    overflow: auto;
    white-space: nowrap;
    padding: 12px 0; }

.container #form_contact_main {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: 1120px;
  margin-bottom: 3rem; }
  .container #form_contact_main dl {
    padding: 16px 0;
    margin-bottom: 0;
    display: flex; }
    @media (max-width: 767.98px) {
      .container #form_contact_main dl {
        flex-wrap: wrap; } }
  .container #form_contact_main dt {
    float: none;
    flex: 0 0 250px; }
    @media (max-width: 767.98px) {
      .container #form_contact_main dt {
        flex: 1 0 100%; } }
    .container #form_contact_main dt span {
      float: left;
      padding: 0 8px;
      margin-right: 8px;
      font-size: 12px;
      background: #8FC323;
      color: #fff; }
      @media (max-width: 767.98px) {
        .container #form_contact_main dt span {
          margin-bottom: 8px; } }
      .container #form_contact_main dt span.any {
        background-color: #777; }
    .container #form_contact_main dt::after {
      display: block;
      clear: both;
      content: ""; }
  .container #form_contact_main dd {
    width: auto !important;
    float: none !important;
    flex: auto;
    margin-bottom: 0; }
    .container #form_contact_main dd p {
      display: inline-block; }
  .container #form_contact_main input[type='text'] {
    font-size: 1rem;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    color: #777;
    min-height: 39px; }
  .container #form_contact_main select {
    padding: 0.5rem;
    font-size: 1rem;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    color: #777; }
  .container #form_contact_main textarea {
    font-size: 1rem;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    color: #777; }
  .container #form_contact_main label {
    margin-bottom: 0; }
  @media (max-width: 767.98px) {
    .container #form_contact_main #submit_button input {
      width: 44%; } }

#subnav.sb_col2_accordion {
  margin-bottom: 20px; }
  #subnav.sb_col2_accordion ul li {
    list-style: none; }
    #subnav.sb_col2_accordion ul li a {
      width: 100%;
      display: block;
      border-width: 0 0 1px 0;
      border-style: dotted;
      padding: 10px; }
      #subnav.sb_col2_accordion ul li a:hover {
        text-decoration: none; }
    #subnav.sb_col2_accordion ul li ul {
      border-bottom: none; }
      #subnav.sb_col2_accordion ul li ul li a {
        padding: 5px 5px 5px 25px; }
  #subnav.sb_col2_accordion .sp_btn {
    display: none; }

#subnav_title.sb_col2_accordion a {
  display: block;
  text-align: center;
  padding: 8px 40px 8px 40px;
  font-size: 18px; }
  #subnav_title.sb_col2_accordion a:hover {
    text-decoration: none; }

@media only screen and (max-width: 979px) {
  #subnav.sb_col2_accordion #subnav_title {
    position: relative; }
  #subnav.sb_col2_accordion .sp_btn {
    display: block;
    position: absolute;
    top: calc(50% - 15px);
    right: 10px;
    z-index: 2;
    width: 30px;
    height: 30px;
    border: 1px solid #cccccc; }
  #subnav.sb_col2_accordion .sp_btn::before,
  #subnav.sb_col2_accordion .sp_btn .in::after {
    position: absolute;
    top: calc(50% - 1px);
    left: 50%;
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    border-top: 2px solid #fff;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%); }
  #subnav.sb_col2_accordion .sp_btn .in:after {
    top: 8px;
    left: 4px;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg); }
  #subnav.sb_col2_accordion .sp_btn.active .in:after {
    display: none; }
  #subnav.sb_col2_accordion #subnav_list {
    display: none; }
  #subnav_title.sb_col2_accordion a {
    padding: 12px 40px 12px 40px; } }

#subnav.sb_col2_accordion ul li a {
  padding: 8px 16px; }

#subnav.sb_col2_accordion ul li.current ul li a {
  padding: 8px 16px; }

#subnav.sb_col2_accordion ul li.current ul li.current ul li a {
  padding: 8px 16px 8px 32px; }

#subnav.sb_col2_accordion ul li.current ul li.current ul li.current ul li a {
  padding: 8px 16px 8px 32px; }

#subnav.sb_col2_accordion ul li.current ul li.current ul li.current ul li.current ul li a {
  padding: 8px 16px 8px 48px; }

.sns_share.head {
  padding-left: 0; }

#nextprev {
  padding-left: 0; }

#locator_t_under {
  font-size: 10px;
  line-height: 1.4;
  padding: 12px 0;
  border-width: 1px 0 1px 0;
  border-style: solid;
  width: 100vw;
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
  margin-bottom: 40px;
  margin-bottom: 0;
  width: 100%; }

#locator_t_up {
  font-size: 10px;
  line-height: 1.4;
  padding: 12px 0;
  border-width: 1px 0 1px 0;
  border-style: solid;
  width: 100vw;
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw); }

#locator_t_under ol {
  display: flex;
  align-items: center; }

#locator_t_under li {
  list-style: none;
  position: relative; }

#locator_t_under li + li::before {
  content: '>';
  margin-left: 10px;
  margin-right: 10px; }

#subnav.sb_col2 ul li a {
  border-color: #bbbbbb; }

#subnav.sb_col2 ul li a:hover {
  color: #222222;
  background-color: #f9f9f9; }

#subnav.sb_col2 ul li.current a {
  border-color: #bbbbbb;
  color: #222222;
  background-color: #f9f9f9; }

#subnav.sb_col2 ul li.current ul {
  border-color: #bbbbbb; }

#subnav_title.sb_col2 a {
  color: #ffffff; }

#subnav.sb_col2_accordion ul li a {
  border-color: #bbbbbb; }

#subnav.sb_col2_accordion ul li a:hover {
  color: #222222;
  background-color: #f9f9f9; }

#subnav.sb_col2_accordion ul li.current a {
  border-color: #bbbbbb;
  color: #222222;
  background-color: #f9f9f9; }

#subnav.sb_col2_accordion ul li.current ul {
  border-color: #bbbbbb; }

#subnav_title.sb_col2_accordion a {
  color: #ffffff; }

#subnav.sb_col1_1 ul li a:hover {
  background-color: #f7f7f7; }

#subnav.sb_col1_1 ul li a::after {
  color: #222222; }

#subnav.sb_col1_1 ul li a {
  border-color: #f3f3f3; }

#subnav.sb_col1_1 ul li.current a {
  background-color: #f7f7f7; }
  #subnav.sb_col1_1 ul li.current a::after {
    color: #222222; }

#subnav_title.sb_col1_1 a {
  background-color: #ffffff;
  color: #222222; }

#subnav_title.sb_col1_1 a::before {
  background-color: #dddddd; }

@media only screen and (max-width: 690px) {
  #subnav.col1_1 ul li a::before,
  #subnav.col1_1 ul li.current a::before {
    color: #222222; } }

/* column1 02 ***/
.sb_col1_2_wrap {
  background-color: #eeeeee; }

#subnav_title {
  border-color: #cccccc; }
  #subnav_title a:hover {
    color: #222222; }

#subnav_list li {
  border-color: #cccccc; }
  #subnav_list li.current a,
  #subnav_list li a:hover {
    color: #222222;
    text-decoration: none; }

/* common ***/
time {
  color: #555555; }

article#main {
  border: 0;
  padding: 0; }

article#main a {
  color: #222222;
  text-decoration: none; }
  article#main a:hover {
    text-decoration: underline; }

article#main .btn a,
article#main .btn_bg a,
article#main .catelist a {
  text-decoration: none; }

article#main .taglist a {
  text-decoration: none;
  color: #222222; }
  article#main .taglist a:hover {
    text-decoration: underline; }

/* article list ***/
article {
  padding: 16px;
  border: 1px solid #d2d2d2; }
  article + article {
    margin-top: 20px; }
    article + article.relationlist {
      margin-top: 0; }
  article .info time {
    color: #000000; }

.cover img {
  max-width: none;
  width: 100%; }

/* SNS ***/
.sns_share {
  /*&.head{	border-color: #f5f5f5;}*/ }
  .sns_share.foot {
    border-color: #dddddd; }
  .sns_share .line a {
    background-color: #00c300;
    color: #ffffff !important;
    text-decoration: none !important; }

/* next prev ***/
#nextprev li a:hover {
  color: #8FC323; }

/* relation ***/
#relation .title {
  font-weight: bold;
  position: relative;
  border-top: 4px solid #6c757d;
  border-bottom: 1px solid #dddddd;
  padding-top: 10px;
  padding-bottom: 10px;
  box-sizing: content-box;
  font-weight: 700; }
  #relation .title::before {
    content: "";
    position: absolute;
    width: 60px;
    height: 4px;
    background-color: #222222;
    left: 0;
    top: -4px; }

.relationlist time {
  color: #777777; }

/* article main title ***/
/*BMの見出しとあわせてください。*/
/*------------------------------------------------

  blog_navi.css

  -------------------------------------------------*/
/* category navi common header ***/
.catenavi {
  border-color: #eeeeee; }
  .catenavi .catenavi_in li a:after {
    background-color: #222222; }
  .catenavi .catenavi_in li a:hover {
    color: #222222; }

/* search ***/
.blog_search {
  border-color: #eeeeee; }
  .blog_search input.submit_btn {
    background-image: url(../image/common/icon_search.png);
    background-color: #222222; }

.side .title {
  background-color: #222222;
  color: #ffffff; }

.side .cate li a {
  color: #ffffff;
  background-color: #0075BF;
  transition: 0.2s ease; }
  .side .cate li a:hover {
    background-color: #00568c; }

.side .tag li a {
  color: #222222;
  transition: 0.2s ease; }

.side .btn {
  border: 1px solid #222222;
  border-radius: 100vh;
  color: #222222; }
  .side .btn:hover {
    background-color: #222222;
    color: #ffffff; }
    .side .btn:hover span:before {
      border-color: #ffffff; }

.side .sweep {
  border-color: #ccc; }

/* blog list category ***/
.taglist a {
  display: block;
  font-size: 0.875rem;
  line-height: 1.2;
  padding: 3px 6px 4px;
  border-radius: 0.25rem;
  font-weight: bold;
  transition: 0.2s ease;
  text-decoration: none;
  color: #222222; }
  .taglist a:hover {
    text-decoration: underline; }

.catelist a {
  display: block;
  font-size: 0.875rem;
  line-height: 1.2;
  padding: 3px 6px 4px;
  border-radius: 0.25rem;
  font-weight: bold;
  transition: 0.2s ease;
  color: #fff !important;
  background-color: #0075BF; }
  .catelist a:hover {
    background-color: #00568c; }
  .catelist a:before {
    color: #CCCCCC; }

/* archive year month tag category list ***/
.archivelist .list a {
  border-color: #F5F5F5;
  color: #555555; }
  .archivelist .list a:hover, .archivelist .list a.current {
    background-color: #ECF9FF;
    color: #ffffff; }

/* pager new old ***/
.newold a,
.pager a {
  color: #222222; }

.pager span {
  background-color: #ECF9FF;
  color: #ffffff; }

/* ぱんくず locator ***/
#locator_t_under,
#locator,
#locator_template_c2,
#locator_t_up {
  background-color: #f5f5f5;
  border-color: #F5F5F5; }

/* 特徴　強み　選ばれる理由 企業理念 など ***/
/* 企業理念 ***/
.rinen > p {
  background-color: #ffffff; }
  .rinen > p::after, .rinen > p::before {
    background-color: #999999; }

/*　faq QA　よくある質問 ***/
.qa_q {
  color: #fff;
  background-color: #222222; }

.qa_click_q {
  background-color: #f5f5f5; }

.qa_click_q::before {
  background-color: #222222; }

.qa_click_q::after {
  border-color: #ffffff; }

.qa_click_q.active::after {
  border-color: #ffffff; }

.qa_q_simple .init,
.qa_a_simple .init {
  color: #222222;
  border-color: #222222; }

.qa_a_simple .init {
  color: #ffffff;
  border-color: #8FC323;
  background-color: #8FC323; }

@media only screen and (max-width: 690px) {
  .qa_click_q::after {
    border-color: #222222; }
  .qa_click_q.active::after {
    border-color: #222222; } }

/*　サイトマップ ***/
#sitemap a {
  color: #333333; }

#sitemap li::before {
  color: #aaaaaa; }

/* page contact ***/
.colcontact {
  background-color: #F5F5F5; }

/* side contact ***/
.sidecontact_in {
  background-color: #CCCCCC; }

/* form contact ***/
.formflow li:after {
  border-color: #222222; }

.formflow li.prev {
  background-color: #dddddd; }

.formflow li.age {
  background-color: #222222; }

.formflow li.next {
  background-color: #8FC323; }

/* side search ***/
.sub_search .search_txt {
  border-color: #eeeeee; }

.sub_search .submit_btn {
  background-image: url("../image/common/icon_search.png"); }

.sub_search input.search_txt {
  background-color: #f5f5f5;
  color: #777777; }

.sub_search input.submit_btn {
  background-color: #555555;
  background-image: url("../image/common/icon_search.png"); }

.sm_b_block {
  background-color: rgba(162, 188, 14, 0.6); }

/* submain area C ***/
/* submain area D ***/
.col2_vm_v2 {
  background-color: #f5f5f5; }
  .col2_vm_v2 .flt_vm {
    background-color: #ffffff;
    box-shadow: 5px 5px 10px -3px rgba(0, 0, 0, 0.15); }

/* sub area C TOPari ***/
.sub_c_block:hover .sub_c_image::after {
  background-color: rgba(0, 0, 0, 0.7); }

.sub_c_caption {
  color: #ffffff; }

.sub_c_area .flt,
.sub_c_area .flr {
  border-color: #ffffff; }

@media only screen and (max-width: 979px) {
  .sub_c_caption {
    color: #000000; }
  .sub_c_block:hover .sub_c_image::after {
    background-color: rgba(0, 0, 0, 0); } }

@media only screen and (max-width: 690px) {
  .sub_c_area .flt {
    border-color: #ffffff; } }

/* sub area D ***/
.sub_d_area .col figcaption {
  background-color: #f9f9f9; }

.sub_d_area .col::before {
  border-color: rgba(255, 255, 255, 0); }

.sub_d_area .col.bl-hover::before {
  border-color: #222222; }

.sub_d_area figcaption .txt_link a {
  color: #000000; }

.sub_d_area_spyoko .col figcaption {
  background-color: #f9f9f9; }

.sub_d_area_spyoko .col::before {
  border-color: rgba(255, 255, 255, 0); }

.sub_d_area_spyoko .col.bl-hover::before {
  border-color: #222222; }

.sub_d_area_spyoko figcaption .txt_link a {
  color: #000000; }

/* sub area U ***/
/* sub area W ***/
/* interview ***/
.interview_txt {
  background-color: #ffffff;
  border-color: #222222; }

.interview_section > div.bl-hover .interview_txt {
  background-color: #222222;
  color: #ffffff;
  border-bottom-color: #282f3a; }
  .interview_section > div.bl-hover .interview_txt a {
    color: #ffffff; }

.interview_section > div.bl-hover .img::before {
  background-color: #222222; }

/* tab switch sub_i_area ***/
/* sub area K ***/
/* sub area L ***/
.sub_l_list li a:hover::before {
  border-color: #222222; }

/* sub area O ***/
.sub_o_area .col {
  border-color: #cccccc; }
  .sub_o_area .col::before {
    border-color: #222222; }

.sub_o_dl dt {
  color: #222222;
  border-color: #dddddd; }

@media only screen and (max-width: 690px) {
  .sub_o_dl dt {
    color: #222222; } }

/* sub area P ***/
.sub_p_area_y > .col2_m > .col,
.sub_p_area_t > .col2_m > .col {
  background-color: #f9f9f9;
  border-color: #cccccc; }
  .sub_p_area_y > .col2_m > .col::before,
  .sub_p_area_t > .col2_m > .col::before {
    border-color: #222222; }

/* sub area R ***/
/* sub area S ***/
.sub_s_area .col {
  background-color: rgba(34, 34, 34, 0.1); }

.sub_s_area .col:nth-child(2),
.sub_s_area .col:nth-child(3),
.sub_s_area .col:nth-child(6),
.sub_s_area .col:nth-child(7) {
  background-color: rgba(34, 34, 34, 0.3); }

@media only screen and (max-width: 690px) {
  .sub_s_area .col,
  .sub_s_area .col:nth-child(3),
  .sub_s_area .col:nth-child(7) {
    background-color: rgba(34, 34, 34, 0.1); }
  .sub_s_area .col:nth-child(2n) {
    background-color: rgba(34, 34, 34, 0.3); } }

.sub_s_num {
  background-color: #222222;
  color: #ffffff; }

/* point 01 ***/
.point01 .point_name {
  background-color: #222222;
  color: #ffffff; }

/*** submain area G 1 ***/
.sm_g_area {
  background-color: #f5f5f5; }

.sm_g_block {
  background-color: #ffffff;
  border-top: 5px solid;
  border-image: linear-gradient(to right, #ff8008, #ffc837);
  border-image-slice: 100%;
  border-image-slice: 100%;
  border-image-slice: 1; }

/*** submain area G 2 ***/
.sm_g_area2 {
  background-color: #f5f5f5; }

.sm_g_block2:before,
.sm_g_block2:after {
  background: linear-gradient(linear, left right, #f00, #00f); }

.sm_g_block2_in {
  background-color: rgba(255, 255, 255, 0.5);
  border-left: 1px solid #ff0000;
  border-right: 1px solid #0000ff; }

@media only screen and (max-width: 690px) {
  .sub_h_area .col,
  .sub_h_area .col:nth-child(3),
  .sub_h_area .col:nth-child(7) {
    background-color: rgba(34, 34, 34, 0.1); }
  .sub_h_area .col:nth-child(2n) {
    background-color: rgba(34, 34, 34, 0.3); } }

/*** submain area i ***/
.sm_i_area .col.bl-hover {
  background-color: #f7f7f7; }

.sm_i_area .col::before {
  border-color: #dddddd; }

.sm_i_area .col.bl-hover::before {
  border-color: #222222; }

/* ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
  ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

      top new corporate 2

  ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
  ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ */
.top_title_center_underbar_en,
.top_title_left_underbar_en,
.top_title_s_left_underbar_en {
  color: #222222; }

/*-- template item product btn -----------------------------------------*/
.templateitem_btn_area {
  background-color: #eef7fc; }

/* btn */
.templateitem_btn li a {
  border: 1px solid #999999;
  background-color: #ffffff; }
  .templateitem_btn li a::after {
    border-top: solid 3px #58afe2;
    border-right: solid 3px #58afe2; }
  .templateitem_btn li a:hover {
    border-color: #dddddd; }

/*-- template item product link -----------------------------------------*/
/* link */
.templateitem_link li a {
  background-color: #8a9395;
  border-right: solid 1px rgba(255, 255, 255, 0.5);
  border-bottom: solid 1px rgba(255, 255, 255, 0.5);
  color: #ffffff; }
  .templateitem_link li a::after {
    border-top: solid 2px #ffffff;
    border-right: solid 2px #ffffff; }
  .templateitem_link li a:hover {
    border-color: #dddddd; }

/*-- template item product contents title -----------------------------------------*/
.templateitemcon_title::after {
  background-color: #58afe2; }

.templateitemcon_lead {
  color: #374252; }

/*-- template item product contents 01 -----------------------------------------*/
.templateitemcon01_area {
  background-color: #f7f8fb; }

.templateitemcon01_catch {
  color: #374252; }

/*-- template item product contents 10 -----------------------------------------*/
.templateitemcon10_area {
  background-color: #c9d2d4; }

.templateitemcon10_title {
  color: #4e5759; }

.templateitemcon10_box::after {
  background-color: rgba(0, 0, 0, 0.5); }

.templateitemcon10_txt {
  color: #ffffff; }
  .templateitemcon10_txt a {
    color: #ffffff; }

.templateitemcon10_box:hover::after {
  background-color: rgba(0, 0, 0, 0.2); }

/*-- template item product contents 11 -----------------------------------------*/
.templateitemcon11_box::after {
  background-color: rgba(0, 0, 0, 0.6); }

.templateitemcon11_title {
  color: #ffffff; }

.templateitemcon11_txt {
  color: #ffffff; }

.templateitemcon11_btn a {
  color: #ffffff;
  border: 1px solid #ffffff; }
  .templateitemcon11_btn a::after {
    border-top: solid 2px #ffffff;
    border-right: solid 2px #ffffff; }
  .templateitemcon11_btn a:hover {
    background-color: #ffffff;
    color: #374252; }
    .templateitemcon11_btn a:hover::after {
      border-color: #374252; }

/*-- header current -----------------------------------------*/
.is-main_current > a {
  color: #222222; }

.navbar .navbar-nav .nav-item.is-main_current > a {
  color: #222222; }

/*-- 2023/06 テンプレートのページ追加改修 -----------------------------------------*/
.tmpl_01_kv_slide {
  min-height: 700px;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 0; }
  @media only screen and (max-width: 979px) {
    .tmpl_01_kv_slide {
      min-height: 600px; } }

.tmpl_01_kv_bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1; }

.tmpl_01_kv .swiper-pagination {
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, -50%); }

.tmpl_01_kv .swiper-wrapper {
  width: 100%;
  overflow: hidden; }

.tmpl_01_kv .swiper-slide {
  width: 100%;
  height: 100%; }

.tmpl_01_kv .swiper-pagination-bullet {
  width: 12px !important;
  height: 12px !important;
  margin: 0 0 0 10px !important;
  background-color: #fff;
  opacity: 1; }
  .tmpl_01_kv .swiper-pagination-bullet-active {
    background-color: #606060; }

.tmpl_01_kv .swiper-pagination-bullet::first-child {
  margin: 0 !important; }

@media only screen and (max-width: 979px) {
  .tmpl_01_kv .btn {
    margin-bottom: 10px;
    margin-right: 0 !important;
    width: 100%; } }

.tmpl_01_new .bs_text-link a::before {
  border-top: 1px solid #222222;
  border-right: 1px solid #222222; }

.tmpl_01_newlist dl {
  display: flex;
  border-left: 0;
  border-right: 0;
  border-bottom: solid 1px #dee2e6; }

.tmpl_01_newlist dl {
  flex-wrap: wrap; }
  @media (max-width: 767.98px) {
    .tmpl_01_newlist dl {
      position: relative;
      padding-top: 0 !important;
      padding-bottom: 0 !important; } }

.tmpl_01_newlist dl:first-child {
  border-top: solid 1px #dee2e6; }

.tmpl_01_newlist dl .rss_date {
  padding: 15px 0 0;
  max-width: 110px;
  color: #6c757d;
  text-align: left;
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto; }

@media (min-width: 576px) {
  .tmpl_01_newlist dl .rss_date {
    width: 15%; } }

@media (max-width: 767.98px) {
  .tmpl_01_newlist dl .rss_date {
    flex-wrap: wrap;
    flex: 0 0 6rem; } }

.tmpl_01_newlist dl .rss_title {
  display: flex;
  align-items: flex-start; }
  @media (min-width: 576px) {
    .tmpl_01_newlist dl .rss_title {
      width: 85%; } }

.tmpl_01_newlist dl .rss_title a {
  color: #222222; }

.tmpl_01_newlist dl .rss_title a:hover {
  -webkit-transition: all ease .5s;
  transition: all ease .5s;
  text-decoration: none;
  color: #0075BF; }

.tmpl_01_newlist dl .rss_title span {
  display: inline-block;
  margin-right: 20px;
  padding: 5px;
  min-width: 80px;
  color: #fff;
  text-align: center;
  background: #0075BF;
  border: solid 1px #0075BF;
  border-radius: 5px;
  line-height: 1; }

@media (max-width: 767.98px) {
  .tmpl_01_newlist dl .rss_title span {
    margin-right: 4%;
    min-width: 80px;
    position: absolute;
    top: 15px !important;
    left: 100px !important;
    font-size: 14px; } }

.tmpl_01_newlist dl .rss_title span:hover {
  color: #0075BF;
  border: solid 1px #0075BF;
  background: #fff;
  text-decoration: none;
  -webkit-transition: all ease .5s;
  transition: all ease .5s; }

.tmpl_01_newlist .rss_entire dl .rss_contents {
  padding-left: 7rem !important;
  width: 100%;
  display: block;
  margin-top: 0 !important; }

@media (max-width: 767.98px) {
  .tmpl_01_newlist .rss_entire dl .rss_contents {
    width: 100%;
    padding-left: 0 !important; } }

.tmpl_01_newlist dl dd {
  padding: 12px 0; }

.bs_footer-04 .bs_footer-icon > div:nth-child(2) {
  margin-left: 2.5em; }

@media (max-width: 767.98px) {
  .bs_footer-04 .bs_footer-logo {
    margin: auto; } }

@media (max-width: 1120px) {
  .bs_footer-04 .bs_footer-icon > div:nth-child(2) {
    font-size: .875rem;
    margin-left: 2em; } }

@media (max-width: 767.98px) {
  .bs_footer-04 .bs_footer-icon > div:nth-child(2) {
    width: 100%;
    margin: 10px auto 0;
    text-align: center;
    font-size: 1rem;
    justify-content: center; } }

.bs_footer-04 .bs_footer-icon > div:nth-child(3) {
  margin-left: auto; }

@media (max-width: 1199.98px) {
  .bs_footer-04 .bs_footer-icon > div:nth-child(3) {
    font-size: .875rem; } }

@media (max-width: 979px) {
  .bs_footer-04 .bs_footer-icon > div:nth-child(3) {
    width: 100%;
    margin: 10px auto 0;
    font-size: 1rem; } }

.bs_footer-04 .bs_footer-logo {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto; }

.tmpl_01_page-hero {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-image: url("/dcms_media/image/pagetitle_bg.jpg");
  background-size: cover;
  background-position: 20% center;
  text-align: center;
  background-repeat: no-repeat;
  margin-bottom: 40px;
  font-weight: 700; }
  @media (min-width: 768px) {
    .tmpl_01_page-hero {
      margin-bottom: 60px;
      min-height: 160px;
      background-position: left center; } }
  @media (min-width: 992px) {
    .tmpl_01_page-hero {
      margin-bottom: 80px;
      min-height: 220px; } }
  .tmpl_01_page-hero.__showcase, .tmpl_01_page-hero.__edit {
    background-image: url("/dcms_media/image/pagetitle_bg-showcase.jpg"); }
  .tmpl_01_page-hero.__liveconnect, .tmpl_01_page-hero.__system {
    background-image: url("/dcms_media/image/pagetitle_bg-live-connect.jpg"); }
  .tmpl_01_page-hero.__flumo {
    background-image: url("/dcms_media/image/pagetitle_bg-flumo.jpg"); }
  .tmpl_01_page-hero.__relaytruck {
    background-image: url("/dcms_media/image/pagetitle_bg-relay-truck.jpg"); }
  .tmpl_01_page-hero.__studio, .tmpl_01_page-hero.__encode {
    background-image: url("/dcms_media/image/pagetitle_bg-studio.jpg"); }
  .tmpl_01_page-hero.__yourlive, .tmpl_01_page-hero.__hosting {
    background-image: url("/dcms_media/image/pagetitle_bg-yourlive.jpg"); }

.tmpl_01_page-hero_text {
  margin-bottom: 0; }

.dlb_list-decimal ol {
  padding: 0;
  list-style: none;
  counter-reset: number 0; }

.dlb_list-decimal ol > li {
  counter-increment: number 1;
  position: relative;
  padding-left: 20px; }

.dlb_list-decimal ol > li::before {
  content: counter(number) ".";
  margin-right: 8px;
  position: absolute;
  left: 0;
  top: 0; }

.tmpl_01_form #form_contact_main {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: 1150px;
  margin-bottom: 3rem;
  margin-top: 50px; }
  .tmpl_01_form #form_contact_main dl {
    padding: 16px 0;
    margin-bottom: 0;
    display: flex; }
    @media (max-width: 991.98px) {
      .tmpl_01_form #form_contact_main dl {
        flex-wrap: wrap; } }
  .tmpl_01_form #form_contact_main dt {
    float: none;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 250px;
    flex: 0 0 250px; }
    @media (max-width: 991.98px) {
      .tmpl_01_form #form_contact_main dt {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 100%;
        flex: 1 0 100%; } }
    .tmpl_01_form #form_contact_main dt span {
      float: left;
      padding: 0 8px;
      margin-right: 8px;
      font-size: 12px;
      background: #ea3c3c;
      color: #fff; }
      @media (max-width: 991.98px) {
        .tmpl_01_form #form_contact_main dt span {
          margin-bottom: 8px; } }
      .tmpl_01_form #form_contact_main dt span.any {
        background-color: #777; }
    .tmpl_01_form #form_contact_main dt::after {
      display: block;
      clear: both;
      content: ""; }
  .tmpl_01_form #form_contact_main dd {
    width: auto !important;
    float: none !important;
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
    margin-bottom: 0; }
    .tmpl_01_form #form_contact_main dd p {
      display: inline-block; }
      .tmpl_01_form #form_contact_main dd p + p {
        margin-left: 10px; }
  .tmpl_01_form #form_contact_main input[type="text"] {
    font-size: 1rem;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    color: #777;
    min-height: 39px; }
  .tmpl_01_form #form_contact_main select {
    padding: 0.5rem;
    font-size: 1rem;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    color: #777;
    width: 100%; }
  .tmpl_01_form #form_contact_main textarea {
    font-size: 1rem;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    color: #777; }
  .tmpl_01_form #form_contact_main label {
    margin-bottom: 0; }
  @media (max-width: 991.98px) {
    .tmpl_01_form #form_contact_main #submit_button input {
      width: 44%; } }
  .tmpl_01_form #form_contact_main #submit_button .btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
    border: 1px solid #6c757d; }
    .tmpl_01_form #form_contact_main #submit_button .btn-secondary:hover {
      color: #6c757d;
      background-color: #fff; }

.tmpl_01_form #form_contact_main dl.__last {
  border-top: 0;
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px !important; }

.icon-bookmark a:before {
  content: '';
  background: url(/dcms_media/image/common/icon-bookmark.png) 0 0 no-repeat;
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-right: 6px;
  vertical-align: middle;
  background-size: contain; }

.icon-bookmark:hover a:before {
  background: url(/dcms_media/image/common/icon-bookmark-on.png) 0 0 no-repeat; }

.icon-book a:before {
  content: '';
  background: url(/dcms_media/other/icon-book-white.svg) 0 center no-repeat;
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-right: 6px;
  vertical-align: middle;
  background-size: contain; }

.icon-book:hover {
  background-color: #fff; }
  .icon-book:hover a {
    color: #8FC323; }
    .icon-book:hover a:before {
      background-image: url(/dcms_media/other/icon-book-green.svg); }

.icon-mail a:before {
  content: '';
  background: url(/dcms_media/other/icon-mail-white.svg) 0 center no-repeat;
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-right: 6px;
  vertical-align: middle;
  background-size: contain; }

.icon-mail:hover {
  background-color: #fff; }
  .icon-mail:hover a {
    color: #0075BF; }
    .icon-mail:hover a:before {
      background-image: url(/dcms_media/other/icon-mail-blue.svg); }

.max-760 {
  max-width: 760px; }

#form_contact_main dt {
  font-weight: normal; }

@media (max-width: 979px) {
  .tmpl_01_strike .table-striped {
    min-width: 900px; } }

.bs_footer-logo a:hover {
  opacity: 0.8; }

.share_top-link {
  overflow: visible; }

.tmpl_01_fax {
  pointer-events: none;
  color: #333; }
  .tmpl_01_fax a {
    pointer-events: none;
    color: #333; }

/*-- 2023/06 テンプレートのページ追加改修 -----------------------------------------*/
/*-- 2023/09 サイト内検索改修 -----------------------------------------*/
.sub_search {
  max-width: 690px; }
  .sub_search input.search_txt {
    background-color: #fff;
    color: #777;
    border: 1px solid #ced4da;
    padding: 0.5em;
    width: calc(100% - 120px - 15px);
    height: 40px;
    line-height: 40px;
    border-radius: 5px;
    margin-right: 15px; }
    .sub_search input.search_txt::placeholder {
      color: #979797; }
    @media (max-width: 767.98px) {
      .sub_search input.search_txt {
        width: calc(100% - 120px - 5px);
        margin-right: 5px; } }
  .sub_search input.submit_btn {
    background-color: #F8F9FA;
    padding: 0.5em 10px;
    border: 1px solid #CED4DA;
    border-radius: 5px;
    background-image: none;
    color: #333 !important;
    height: 40px;
    width: 120px;
    float: right; }
    .sub_search input.submit_btn:hover {
      opacity: 0.7; }

/*-- 2023/09 サイト内検索改修 -----------------------------------------*/
/*-- 2023/10 QC指摘修正 -----------------------------------------*/
.table-responsive table {
  min-width: 100%; }
  @media (max-width: 991.98px) {
    .table-responsive table {
      width: 750px !important; } }

.dlb_news_pickup .rss_date {
  font-size: 14px; }
  @media (min-width: 576px) {
    .dlb_news_pickup .rss_date {
      width: 15%; } }
  @media (max-width: 767.98px) {
    .dlb_news_pickup .rss_date {
      padding: 10px 0 0 0 !important; } }
  @media (max-width: 767.98px) {
    .dlb_news_pickup .rss_date {
      text-align: left !important; } }
  @media (max-width: 1199.98px) {
    .dlb_news_pickup .rss_date {
      font-size: 13px; } }

@media (max-width: 991.98px) {
  .dlb_news_pickup .dlb_news-list dl {
    border-radius: 0; } }

.dlb_news_pickup .dlb_news-list .rss_title a {
  align-items: flex-start; }

@media (max-width: 767.98px) {
  .dlb_news_pickup .dlb_news-list .rss_title {
    min-width: 100%; } }

@media (min-width: 576px) {
  .dlb_news_pickup .dlb_news-list .rss_title {
    width: 85%; } }

@media (max-width: 767.98px) {
  .dlb_news_pickup .dlb_news-list .list-group-item .rss_title span.rss_news {
    top: -23px !important; } }

@media (max-width: 767.98px) {
  .dlb_news_blog .rss_date {
    padding: 15px 0 0 0 !important; } }

@media (max-width: 767.98px) {
  .dlb_news_blog .rss_date {
    text-align: left !important; } }

@media (max-width: 991.98px) {
  .dlb_news_blog .dlb_news-list dl {
    border-radius: 0; } }

@media (max-width: 767.98px) {
  .dlb_news_blog .dlb_news-list .rss_title {
    min-width: 100%; } }

.dlb_news_column .rss_title {
  margin-top: 5px; }

.dlb_news_column .dlb_news-list dl {
  flex-wrap: wrap; }

.dlb_news-list .rss_title a:hover {
  text-decoration: none;
  opacity: 0.7; }

.btn-dark {
  color: #fff !important;
  background-color: #212529 !important;
  border-color: #212529 !important; }
  .btn-dark:hover {
    color: #212529 !important;
    background-color: #fff !important; }

.btn-outline-dark {
  color: #212529 !important;
  border-color: #212529 !important; }
  .btn-outline-dark:hover {
    color: #fff !important;
    background-color: #212529 !important; }

.dlb_news_blog .dlb_news-blog .dlb_blog-item:hover {
  opacity: 0.7; }

.dlb_blog_column-4 .dlb_blog-data a:hover {
  opacity: 0.7; }

.dlb_blog_column_image-left .dlb_blog-item .dlb_blog-data a:hover {
  text-decoration: none; }
  .dlb_blog_column_image-left .dlb_blog-item .dlb_blog-data a:hover .dlb_title-h3 {
    color: #0075BF; }

.dlb_blog_column_image-left .dlb_blog-item .dlb_blog-data a > * {
  color: #333; }

.dlb_blog_column_image-left .dlb_blog-item .dlb_title-h3 {
  max-height: none !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-size: 1.125rem !important;
  margin-bottom: 10px !important;
  color: #333; }

@media (min-width: 992px) {
  .dlb_news_blog .dlb_news-list .dlb_blog-data div {
    position: relative !important;
    margin-left: 1rem;
    margin-bottom: 1rem; } }

.text-shadow {
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3), -2px 2px 8px rgba(0, 0, 0, 0.3), 2px -2px 8px rgba(0, 0, 0, 0.3), -2px -2px 8px rgba(0, 0, 0, 0.3); }

@media (max-width: 767.98px) {
  .dlb_table_stack table th, .dlb_table_stack table td {
    display: block;
    width: 100%; } }

.dlb_btn_card_column-4 .shadow:hover {
  box-shadow: 0 0.25rem 0.6rem rgba(0, 0, 0, 0.4) !important; }

.dlb_btn_link-text .shadow:hover {
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.4) !important; }

.dlb_btn_link-text .stretched-link {
  background-color: #fff; }
  .dlb_btn_link-text .stretched-link a {
    justify-content: space-between; }

@media only screen and (min-width: 576px) {
  .dlb_contents_float figure.float-sm-right {
    margin-left: 1.5rem !important; } }

.dlb_blog_column-4 .dlb_blog-data p {
  max-height: none !important; }

.dlb_btn_link_list .btn-white:hover {
  background-color: #222222; }
  .dlb_btn_link_list .btn-white:hover a {
    color: #ffffff; }

.dlb_news-list dl {
  display: flex;
  border-left: 0;
  border-right: 0;
  border-bottom: solid 1px #dee2e6; }

@media (max-width: 767.98px) {
  .dlb_news-list dl {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    position: relative;
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

.dlb_news-list dl:first-child {
  border-top: solid 1px #dee2e6; }

.dlb_news-list dl .rss_date {
  padding: 15px 0 0;
  max-width: 110px;
  text-align: left;
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto; }

@media (max-width: 767.98px) {
  .dlb_news-list dl .rss_date {
    flex-wrap: wrap;
    flex: 0 0 6rem; } }

.dlb_news-list dl .rss_title {
  display: flex;
  align-items: flex-start;
  position: relative; }

.dlb_news-list dl .rss_title a {
  color: #222222;
  display: flex;
  align-items: flex-start;
  color: #0075BF; }

.dlb_news-list dl .rss_title a:hover {
  transition: all ease .2s;
  text-decoration: underline;
  color: #0075BF; }

.dlb_news-list dl .rss_title span {
  display: inline-block;
  margin-right: 20px;
  padding: 5px;
  min-width: 80px;
  color: #fff;
  text-align: center;
  background: #0075BF;
  border: solid 1px #0075BF;
  border-radius: 5px;
  line-height: 1; }

@media (max-width: 767.98px) {
  .dlb_news-list dl .rss_title span {
    margin-right: 0;
    min-width: 80px;
    position: absolute;
    top: -25px !important;
    left: 90px !important;
    font-size: 14px; } }

.dlb_news-list dl .rss_title span:hover {
  color: #0075BF;
  border: solid 1px #0075BF;
  background: #fff;
  text-decoration: none;
  -webkit-transition: all ease .5s;
  transition: all ease .5s; }

.dlb_news-list .rss_entire dl .rss_contents {
  padding-left: 7rem !important;
  width: 80%;
  display: block;
  margin-top: 0 !important; }

@media (max-width: 767.98px) {
  .dlb_news-list .rss_entire dl .rss_contents {
    width: 100%;
    padding-left: 0 !important; } }

.dlb_news-list dl dd {
  padding: 12px 0; }

/*-- 2023/10 QC指摘修正 -----------------------------------------*/
.rss_paging_container {
  display: flex;
  align-items: center;
  justify-content: right;
  flex-wrap: wrap; }

.js-rss_paging_text {
  width: 100%;
  margin-bottom: 10px;
  letter-spacing: .1em; }

.rss_paging_container span {
  padding: 5px;
  display: block;
  margin: 0 5px;
  text-align: right; }

.rss_paging_container span.rss_paging_button {
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 1;
  color: #000000; }

.rss_paging_container span:not(.rss_paging_button_unclickable).rss_paging_button:hover,
.rss_paging_button_current_page {
  color: #fff !important;
  background-color: #000000; }

.js-rss_paging_text {
  margin-bottom: 10px; }

@media (max-width: 991.98px) {
  .img-fluid-max img {
    width: 100%;
    height: auto; } }

.dlb_list-decimal li,
.dlb_list-disc li {
  margin-top: 8px; }
  .dlb_list-decimal li:first-child,
  .dlb_list-disc li:first-child {
    margin-top: 0; }

.__footernav li a {
  font-size: .875rem;
  padding-left: 30px; }

@media (min-width: 992px) {
  .__footernav .__sho {
    flex: 0 0 23%;
    max-width: 23%; }
  .__footernav .__mid {
    flex: 0 0 27%;
    max-width: 27%; }
  .__footernav .__long {
    flex: 0 0 50%;
    max-width: 50%; } }

.__footernav .bs_text-link a {
  padding-right: 0; }

.__cta .btn a {
  padding: 15px 5px; }

.__cta .img-fluid-max img {
  max-width: 108px; }

@media (max-width: 1199.98px) {
  .__cta .__num a {
    font-size: 2.2em;
    font-weight: 700;
    line-height: 1;
    color: #333; } }

@media (min-width: 992px) {
  .__cta .__num span {
    font-size: 2em;
    font-weight: 700;
    line-height: 1;
    color: #333; } }

.__mb-0 {
  margin-bottom: -3rem; }
  @media (min-width: 992px) {
    .__mb-0 {
      margin-bottom: -5rem; } }

.__stab td:first-child {
  font-weight: 700;
  padding-right: 2.5rem; }

.__stab td {
  padding: 5px 0 5px 0; }
  @media (max-width: 991.98px) {
    .__stab td {
      display: block; } }

.mH.__center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.__pricetab {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc; }
  @media (max-width: 767.98px) {
    .__pricetab th {
      border-bottom: none;
      width: 100%;
      display: block; }
      .__pricetab th.__sp-none {
        padding: 10px 0 0; } }
  @media (min-width: 576px) {
    .__pricetab th {
      width: 80%; } }
  .__pricetab td {
    text-align: right; }
    @media (max-width: 767.98px) {
      .__pricetab td {
        border-top: none;
        padding-top: 0;
        width: 100%;
        display: block; } }
    @media (min-width: 576px) {
      .__pricetab td {
        vertical-align: middle;
        width: 20%;
        border-left: 1px solid #ccc; } }

.__iframe {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden; }

.__iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.p-pagelink {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding-left: 0;
  margin-bottom: 0; }
  @media (min-width: 768px) {
    .p-pagelink {
      gap: 5%;
      grid-template-columns: repeat(4, 1fr); } }
  .p-pagelink li {
    list-style: none;
    padding-bottom: 15px;
    border-bottom: solid 1.5px #0075BF; }
    @media (max-width: 767.98px) {
      .p-pagelink li:nth-child(n+3) {
        margin-top: 10px; } }
    .p-pagelink li a {
      color: #222222;
      position: relative;
      padding-right: 10px;
      font-weight: 700;
      font-size: 1.125rem;
      display: block; }
      .p-pagelink li a::after {
        content: '';
        width: 10px;
        height: 10px;
        display: block;
        border-top: solid 2px;
        border-right: solid 2px;
        border-color: #0075BF;
        transform: rotate(135deg);
        position: absolute;
        right: 5px;
        top: 6px; }
      .p-pagelink li a:hover {
        color: #0075BF;
        text-decoration: none; }

.p-era {
  text-align: center;
  font-weight: 600;
  font-size: 2.25rem;
  line-height: 1 !important;
  margin-bottom: 0 !important; }
  @media (min-width: 768px) {
    .p-era {
      font-size: 3rem; } }
  @media (min-width: 992px) {
    .p-era {
      font-size: 4.375rem; } }

@media (min-width: 768px) {
  .p-chronology {
    background: linear-gradient(90deg, #ECF9FF 0%, #ECF9FF 50%, #F7FFE5 50%, #F7FFE5 100%); } }

@media only screen and (max-width: 767px) {
  .p-chronology__tab {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

.p-chronology__tab table {
  width: 100%; }
  @media only screen and (max-width: 767px) {
    .p-chronology__tab table {
      width: 500px;
      background: linear-gradient(90deg, #ECF9FF 0%, #ECF9FF 50%, #F7FFE5 50%, #F7FFE5 100%); } }
  .p-chronology__tab table tr:first-child th, .p-chronology__tab table tr:first-child td {
    border-top: none; }
  .p-chronology__tab table th, .p-chronology__tab table td {
    border-top: 1px solid #ccc;
    font-weight: 600;
    padding: 20px 10px; }
    @media (min-width: 992px) {
      .p-chronology__tab table th, .p-chronology__tab table td {
        padding: 30px 20px; } }
  .p-chronology__tab table th {
    vertical-align: middle;
    width: 45%;
    text-align: center;
    font-size: 1.5rem; }
    @media (min-width: 768px) {
      .p-chronology__tab table th {
        width: 43%; } }
    @media (max-width: 1199.98px) {
      .p-chronology__tab table th {
        font-size: 1.25rem; } }
    .p-chronology__tab table th.__left {
      color: #0075BF; }
    .p-chronology__tab table th.__right {
      color: #8FC323; }
  .p-chronology__tab table td {
    vertical-align: top;
    text-align: left; }
    @media (min-width: 768px) and (max-width: 1199.98px) {
      .p-chronology__tab table td:first-child {
        padding-left: 0; }
      .p-chronology__tab table td:last-child {
        padding-right: 0; } }
    .p-chronology__tab table td ul {
      margin-bottom: 0; }
    .p-chronology__tab table td li + li {
      margin-top: 8px; }
  .p-chronology__tab table .__center {
    vertical-align: middle;
    background-color: #343a40;
    color: #fff;
    text-align: center;
    width: 10%;
    font-weight: 700;
    line-height: 1.3;
    letter-spacing: .05em; }
    @media (min-width: 768px) {
      .p-chronology__tab table .__center {
        font-size: 1.25rem;
        width: 14%; } }
    @media (min-width: 992px) {
      .p-chronology__tab table .__center {
        font-size: 1.75rem; } }

.p-top-news .rss_contents {
  display: none; }

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active,
.nav-tabs .nav-link:hover {
  color: #fff;
  background-color: #222;
  border-color: #222 #222 #fff; }

@media (min-width: 576px) {
  .nav-tabs .nav-item + .nav-item {
    margin-left: 2px; } }

@media (max-width: 767.98px) {
  .nav-tabs .nav-item {
    width: 100%; }
    .nav-tabs .nav-item .nav-link {
      border-radius: 0; } }

.nav-tabs .nav-link {
  border: 1px solid #222;
  border-bottom: 0;
  color: #333;
  font-weight: 600; }
  @media (min-width: 992px) {
    .nav-tabs .nav-link {
      font-size: 1.375rem; } }

.p-top-mv {
  background: url("/dcms_media/image/top-mv-bg.jpg") no-repeat center;
  background-size: cover;
  position: relative;
  margin-left: auto;
  margin-right: auto; }
  .p-top-mv--content {
    padding: 10vw 0 13vw;
    position: relative;
    z-index: 2; }
    @media (min-width: 768px) {
      .p-top-mv--content {
        padding: 7vw 0; } }
    @media (min-width: 1200px) {
      .p-top-mv--content {
        padding: 4vw 0; } }
    .p-top-mv--content::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      background-image: linear-gradient(90deg, rgba(0, 117, 191, 0.8), rgba(46, 218, 135, 0.8));
      clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
      top: 0;
      left: 0; }
      @media (min-width: 768px) {
        .p-top-mv--content::after {
          width: 80%;
          clip-path: polygon(0 0, 100% 0%, 85% 100%, 0% 100%); } }
      @media (min-width: 1200px) {
        .p-top-mv--content::after {
          width: 70%; } }
    .p-top-mv--content .__title {
      font-weight: 600;
      line-height: 1.4;
      margin: 10px 0;
      color: #fff;
      text-shadow: 2px 3px 3px rgba(0, 0, 0, 0.15); }
      @media (max-width: 767.98px) {
        .p-top-mv--content .__title {
          font-size: 1.5rem; }
          .p-top-mv--content .__title br {
            display: none; } }
      @media (min-width: 576px) {
        .p-top-mv--content .__title {
          font-size: 2rem; } }
      @media (min-width: 992px) {
        .p-top-mv--content .__title {
          font-size: 2.25rem; } }
      @media (min-width: 1200px) {
        .p-top-mv--content .__title {
          font-size: 2.5rem; } }
    .p-top-mv--content .__sub {
      font-weight: 500;
      color: #fff !important;
      margin-bottom: 0;
      text-shadow: 2px 3px 3px rgba(0, 0, 0, 0.15); }
      @media (max-width: 767.98px) {
        .p-top-mv--content .__sub {
          font-size: .875rem; } }
    .p-top-mv--content .__inner {
      max-width: 1120px;
      position: relative;
      z-index: 1; }
      @media (max-width: 991.98px) {
        .p-top-mv--content .__inner {
          width: 90%;
          margin-left: auto;
          margin-right: auto; } }
      @media (min-width: 992px) {
        .p-top-mv--content .__inner {
          margin-left: calc((100% - 960px) / 2); } }
      @media (min-width: 1200px) {
        .p-top-mv--content .__inner {
          margin-left: calc((100% - 1120px) / 2); } }
    .p-top-mv--content .__link {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 5px;
      max-width: 460px;
      padding-left: 0;
      margin-bottom: 0;
      margin-top: 20px; }
      @media (min-width: 576px) {
        .p-top-mv--content .__link {
          gap: 10px;
          margin-top: 30px; } }
      @media (min-width: 768px) {
        .p-top-mv--content .__link {
          margin-top: 40px;
          max-width: 620px;
          grid-template-columns: repeat(3, 1fr); } }
      .p-top-mv--content .__link li {
        list-style: none; }
        .p-top-mv--content .__link li a {
          display: flex;
          align-items: center;
          background-color: #fff;
          border-radius: 100vh;
          padding: 10px;
          font-weight: 600;
          border: 1.5px solid #ccc;
          transition: all .2s;
          font-size: .875rem; }
          @media (min-width: 576px) {
            .p-top-mv--content .__link li a {
              font-size: 1rem;
              padding: 10px 15px 10px 20px; } }
          .p-top-mv--content .__link li a:hover {
            color: #fff !important;
            text-decoration: none; }
            .p-top-mv--content .__link li a:hover::after {
              border-top-color: #fff !important;
              border-right-color: #fff !important; }
          .p-top-mv--content .__link li a::after {
            content: "";
            width: 8px;
            height: 8px;
            min-width: 8px;
            margin-left: auto;
            border-top: 2px solid #222;
            border-right: 2px solid #222;
            transform: rotate(45deg); }
        .p-top-mv--content .__link li.__showcase a {
          color: #C61723;
          border-color: #C61723; }
          .p-top-mv--content .__link li.__showcase a::after {
            border-top-color: #C61723;
            border-right-color: #C61723; }
          .p-top-mv--content .__link li.__showcase a:hover {
            background-color: #C61723; }
        .p-top-mv--content .__link li.__connect a {
          color: #0075BF;
          border-color: #0075BF; }
          .p-top-mv--content .__link li.__connect a::after {
            border-top-color: #0075BF;
            border-right-color: #0075BF; }
          .p-top-mv--content .__link li.__connect a:hover {
            background-color: #0075BF; }
        .p-top-mv--content .__link li.__yourlive a {
          color: #90C322;
          border-color: #90C322; }
          .p-top-mv--content .__link li.__yourlive a::after {
            border-top-color: #90C322;
            border-right-color: #90C322; }
          .p-top-mv--content .__link li.__yourlive a:hover {
            background-color: #90C322; }
        .p-top-mv--content .__link li.__studio a {
          color: #DD540F;
          border-color: #DD540F; }
          .p-top-mv--content .__link li.__studio a::after {
            border-top-color: #DD540F;
            border-right-color: #DD540F; }
          .p-top-mv--content .__link li.__studio a:hover {
            background-color: #DD540F; }
        .p-top-mv--content .__link li.__truck a {
          color: #656766;
          border-color: #656766; }
          .p-top-mv--content .__link li.__truck a::after {
            border-top-color: #656766;
            border-right-color: #656766; }
          .p-top-mv--content .__link li.__truck a:hover {
            background-color: #656766; }
        .p-top-mv--content .__link li.__flumo a {
          color: #E42187;
          border-color: #E42187; }
          .p-top-mv--content .__link li.__flumo a::after {
            border-top-color: #E42187;
            border-right-color: #E42187; }
          .p-top-mv--content .__link li.__flumo a:hover {
            background-color: #E42187; }
  .p-top-mv--img {
    width: 70%;
    margin: auto 0 auto auto; }
    @media (max-width: 991.98px) {
      .p-top-mv--img {
        max-width: 400px;
        position: relative;
        top: -60px;
        margin-bottom: -60px; } }
    @media (min-width: 768px) {
      .p-top-mv--img {
        position: absolute;
        top: 0;
        right: 0;
        text-align: right;
        height: 100%;
        width: 50%; } }
    @media (min-width: 992px) {
      .p-top-mv--img {
        width: 40%; } }
    .p-top-mv--img img {
      width: 100%;
      height: auto; }
      @media (min-width: 768px) {
        .p-top-mv--img img {
          width: auto;
          height: 100%;
          object-fit: contain; } }
