/*!
 * Bootstrap v4.5.0 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

 @import 'functions';
 @import 'variables';
 // Bootstrap
 // グリッド数とガターサイズ、レスポンシブサイズ
 $grid-columns: 12;
 $grid-gutter-width: 30px;
 $grid-breakpoints: ( // Extra small screen / phone
 xs: 0, // Small screen / phone
 sm: 576px, // Medium screen / tablet
 md: 768px, // Large screen / desktop
 lg: 992px, // Extra large screen / wide desktop
 xl: 1200px);
 $container-max-widths: ( sm: 540px, md: 720px, lg: 960px, xl: 1150px, );
 // color 拡張
 // 変数
 $main: #222222;
 $accent-01: #8FC323;
 $accent-02: #0075BF;
 $accent-03: #ECF9FF;
 $color-01: #F5F5F5;
 $color-02: #CCCCCC;
 $color-03: #9CCC65;
 // .btn-xxx, .border-xxx, .bg-xxx
 $theme-colors: ( 'main': $main, 'accent-01': $accent-01, 'accent-02': $accent-02, 'accent-03': $accent-03, 'color-01': $color-01, 'color-02': $color-02, 'color-03': $color-03, 'gray-333': #333,'gray-100': #f8f9fa, 'gray-200': #e9ecef, 'gray-300': #dee2e6, 'gray-400': #ced4da, 'gray-500': #adb5bd, 'gray-600': #6c757d, 'gray-700': #495057, 'gray-800': #343a40, 'gray-900': #212529, 'twitter': #1da1f2, 'white': #ffffff, );
 // body, p color の色を指定する変数
 $color-body: #333;
 $color-p: #333;
 // linkの色
 $link-color: theme-color("main");
 // btn hover時 スタイルの切り替え変数 boolean
 // hover時 アウトライン化   true
 // hover時 従来の暗色化     false
 $enable-btn-hover-border-style: true;
 // color-yiq のしきい値 -> $theme-colors で指定した色に対してボタンの文字色の判定をする
 // color-yiq は _function.scss にあり $yiq-text-dark か $yiq-text-light を返します
 $yiq-contrasted-threshold: 200;
 $yiq-text-dark: #212529;
 $yiq-text-light: #fff;
 // aタグの配色
 a, .btn-link {
     color: $accent-01;
     &:hover {
         color: darken($accent-01, 10%);
         text-decoration: underline;
     }
 }

 // aタグのwrap用記述 基本的には変更しない
 .text-main a {
     color: $main;
     text-decoration: underline;
    &:hover{
        text-decoration: none;
    }
 }
 .tel a,
 .text-main .tel a{
    text-decoration: none;
    color: $main;
 }

 .text-accent-01 a {
     color: $accent-01;
 }

 .text-accent-02 a {
     color: $accent-02;
 }

 .text-accent-03 a {
     color: $accent-03;
 }

 .text-color-01 a {
     color: $color-01;
 }

 .text-color-02 a {
     color: $color-02;
 }

 .text-color-03 a {
     color: $color-03;
 }

 // instagram btn拡張
 // aタグにはclassをつけられないためdiv.btn-XXX>aで記述する
 .btn-instagram {
     color: #fff !important;
     background: linear-gradient(-183deg, #a512e6 0%, #faa55d 100%);
     border: none !important;
     transition: box-shadow 0.3s ease;
     &:hover {
         color: #fff;
         box-shadow: 0px 0px 3rem 0px rgba(0, 0, 0, 0.3) inset;
     }
 }

 // メイングラデーションの配色
 .bg-grad-01 {
     background: linear-gradient(96deg, #0089ff 0%, #00baff 48%, #7be1ff 100%);
 }

 .bg-grad-02 {
     background: linear-gradient(180deg, #0089ff 0%, #00baff 48%, #7be1ff 100%);
 }

// btn 全体記述
.btn {
    // すべて角丸へ　不要の場合はコメントアウトで対応
     border-radius: 50rem !important;
    a {
        font-weight: bold;
        letter-spacing: .1em;
    }
    &.btn-white {
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        a {
            color: $main !important;
        }
        &:hover{
            background-color: $main;
            a{
                color: #fff !important;
            }
        }
    }
    &.btn-trial {
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    }
}

.btn-white.__arr{
    a{
        position: relative;
        padding-left: 25px;
        padding-right: 35px;
        &::after {
            transition: all .2s;
            content: "";
            position: absolute;
            right: 15px;
            top: calc(50% - 4px);
            width: 10px;
            height: 10px;
            border-top: 2px solid $main;
            border-right: 2px solid $main;
            transform: rotate(45deg);
        }
        &:hover{
            &::after{
                border-top-color: #fff;
                border-right-color: #fff;
            }
        }
    }
}

.bg-accent-01 .btn.btn-white{
    a{
        color: $accent-01 !important;
    }
    &:hover a{
        color: #fff !important;
    }
}

 // btn arrow mixin
 // $size = width & height, $margiin-top = top, $border-width = border-width, $border-color = border-color, $rotate = XXdeg
 @mixin btn-arrow($size: 8px, $margiin-top: -4px, $border-width: 2px, $border-color: #000000, $rotate: 45deg) {
     content: '';
     position: relative;
     top: 50%;
     margin-top: $margiin-top;
     display: inline-block;
     width: $size;
     height: $size;
     min-width: 8px;
     margin-right: 0.5rem;
     border-top: $border-width solid $border-color;
     border-right: $border-width solid $border-color;
     transform: rotate($rotate);
 }

 @mixin btn-arrow-right($size: 8px, $margiin-top: -4px, $border-width: 2px, $border-color: #000000, $rotate: 45deg) {
     content: '';
     position: relative;
     top: 50%;
     margin-top: $margiin-top;
     display: inline-block;
     width: $size;
     height: $size;
     min-width: 8px;
     margin-left: 0.5rem;
     border-top: $border-width solid $border-color;
     border-right: $border-width solid $border-color;
     transform: rotate($rotate);
 }

 // テキストリンクの矢印
 // arrow link before,after
 // ひとつ上のmixinを使っています
 .arrow-link-before-main {
     a {
         &::before {
             @include btn-arrow($size: 8px, $margiin-top: -4px, $border-width: 2px, $border-color: $main, $rotate: 45deg);
         }
     }
 }

 .arrow-link-before-accent-01 {
     a {
         &::before {
             @include btn-arrow($size: 8px, $margiin-top: -4px, $border-width: 2px, $border-color: $accent-01, $rotate: 45deg);
         }
     }
 }

 .arrow-link-before-accent-02 {
     a {
         &::before {
             @include btn-arrow($size: 8px, $margiin-top: -4px, $border-width: 2px, $border-color: $accent-02, $rotate: 45deg);
         }
     }
 }

 .arrow-link-before-accent-03 {
     a {
         &::before {
             @include btn-arrow($size: 8px, $margiin-top: -4px, $border-width: 2px, $border-color: $accent-03, $rotate: 45deg);
         }
     }
 }

 // arrow link after
 .arrow-link-after-main {
     a {
         &::after {
             @include btn-arrow($size: 8px, $margiin-top: -4px, $border-width: 2px, $border-color: $main, $rotate: 45deg);
         }
     }
 }

 .arrow-link-after-accent-01 {
     a {
         &::after {
             @include btn-arrow($size: 8px, $margiin-top: -4px, $border-width: 2px, $border-color: $accent-01, $rotate: 45deg);
         }
     }
 }

 .arrow-link-after-accent-02 {
     a {
         &::after {
             @include btn-arrow($size: 8px, $margiin-top: -4px, $border-width: 2px, $border-color: $accent-02, $rotate: 45deg);
         }
     }
 }

 .arrow-link-after-accent-03 {
     a {
         &::after {
             @include btn-arrow($size: 8px, $margiin-top: -4px, $border-width: 2px, $border-color: $accent-03, $rotate: 45deg);
         }
     }
 }

 // sitemap用 配色を変える場合$border-colorを変更する
 // テキストが回り込むので修正する
 .btn-arrow-link {
     a {
         display: flex;
         align-items: center;
         justify-content: left;
         &::before {
             @include btn-arrow($size: 8px, $margiin-top: 0px, $border-width: 2px, $border-color: $accent-01, $rotate: 45deg);
             position: static;
         }
     }
     &.justify-content-center {
         a {
             justify-content: center;
         }
     }
 }

 // 白ボタンに表示するメインカラーの矢印
 .arrow-link {
     a {
         display: inline-block;
         padding-left: 1rem;
         text-indent: -1rem;
         &::before {
             @include btn-arrow($size: 8px, $margiin-top: -2px, $border-width: 2px, $border-color: $main, $rotate: 45deg);
             vertical-align: middle;
         }
     }
 }

.btn {
    // 削除予定
    // &.btn-arrow-white-pos {
    //   a {
    //     position: relative;
    //     padding-right: 20px;
    //     &::after {
    //       @include btn-arrow($size: 8px, $margiin-top: -4px, $border-width: 2px, $border-color: #fff, $rotate: 45deg);
    //       position: absolute;
    //       top: 15px;
    //       right: 10px;
    //     }
    //   }
    // }
    &.btn-accent-01 {
        a:hover {
            color: $accent-01 !important;
        }
    }
    &.btn-accent-02 {
        a:hover {
            color: $accent-02 !important;
        }
    }
    &.btn-arrow-before-main {
        a {
            &::before {
                @include btn-arrow($size: 8px, $margiin-top: -4px, $border-width: 2px, $border-color: $main, $rotate: 45deg);
                margin-left: 0.5rem;
                left: -0.5rem;
            }
        }
        &.small {
            a {
                &::before {
                    margin-right: 0.5rem;
                    left: -0.25rem;
                }
            }
        }
    }
    &.btn-arrow-after-main {
        a {
            position: relative;
            padding-right: 46px;
            padding-left: 46px;
            &::after {
                @include btn-arrow($size: 8px, $margiin-top: -4px, $border-width: 2px, $border-color: $main, $rotate: 45deg);
                margin-right: -0.5rem;
                // left: 0.5rem;
                position: absolute;
                right: 24px;
                // top: 15px;
            }
        }
        &.small {
            a {
                &::after {
                    margin-right: -0.5rem;
                    left: 0.25rem;
                }
            }
        }
        &.btn-white {
        &:hover {
            background-color: $main;

            a {
                color:#ffffff !important;
                &::after {
                    border-color: #ffffff;
                }
            }
        }
        }
    }
    &.btn-arrow-after-white {
        a {
            position: relative;
            padding-right: 26px;
            padding-left: 26px;
            &::after {
                @include btn-arrow($size: 8px, $margiin-top: -4px, $border-width: 2px, $border-color: #fff, $rotate: 45deg);
                margin-right: -0.5rem;
                // left: 0.5rem;
                position: absolute;
                right: 24px;
                // top: 15px;
            }
        }
        &.small {
            a {
                &::after {
                    margin-right: -0.5rem;
                    left: 0.25rem;
                }
            }
        }
        &.btn-accent-01 {
        a:hover::after {
            border-color: $accent-01 !important;
        }
        }
        &.btn-accent-02 {
        a:hover::after {
            border-color: $accent-02 !important;
        }
        }

    }
    &.btn-arrow-before-01 {
        a {
            &::before {
                @include btn-arrow($size: 8px, $margiin-top: -4px, $border-width: 2px, $border-color: $accent-01, $rotate: 45deg);
                margin-left: -0.5rem;
                right: 0.5rem;
            }
            &:hover {
                &::after {
                    border-color: #fff;
                }
            }
        }
    }
    &.btn-arrow-before-02 {
        a {
            &::before {
                @include btn-arrow($size: 8px, $margiin-top: -4px, $border-width: 2px, $border-color: $accent-02, $rotate: 45deg);
                margin-left: -0.5rem;
                right: 0.5rem;
            }
            &:hover {
                &::after {
                    border-color: #fff;
                }
            }
        }
    }
    &.btn-arrow-after-01 {
        a {
            &::after {
                @include btn-arrow($size: 8px, $margiin-top: -4px, $border-width: 2px, $border-color: $accent-01, $rotate: 45deg);
                margin-right: -0.5rem;
                left: 0.5rem;
            }
            &:hover {
                &::after {
                    border-color: #fff;
                }
            }
        }
    }
    &.btn-arrow-after-02 {
        a {
            &::after {
                @include btn-arrow($size: 8px, $margiin-top: -4px, $border-width: 2px, $border-color: $accent-02, $rotate: 45deg);
                margin-right: -0.5rem;
                left: 0.5rem;
            }
            &:hover {
                &::after {
                    border-color: #fff;
                }
            }
        }
    }
    &.btn-anchor-link {
    //  text-indent: -0.5rem;
    //  padding-left: 1rem !important;
    border-radius: 100vh;
    background-color: #fff;
        a {
        padding-left: 12%;
        padding-right: 12%;
            &::after {
                @include btn-arrow-right($size: 8px, $border-width: 2px, $border-color: $main, $rotate: 135deg);
                top: calc(50% - 2px);
            right: 10%;
            position: absolute;
            }
        }
        &:hover a {
            &::after {
                border-color: #fff !important;
            }
        }
    }
}

 // font family
 $font-family-sans-serif: 'Noto Sans JP',
 'Noto Sans',
 'YuGothic',
 'Yu Gothic',
 '游ゴシック',
 'ヒラギノ角ゴ Pro W3',
 'Hiragino Kaku Gothic Pro',
 'メイリオ',
 'Meiryo',
 -apple-system,
 BlinkMacSystemFont,
 'Segoe UI',
 Roboto,
 'Helvetica Neue',
 Arial,
 sans-serif,
 'Apple Color Emoji',
 'Segoe UI Emoji',
 'Segoe UI Symbol',
 'Noto Color Emoji' !default;
 $font-family-monospace: SFMono-Regular,
 Menlo,
 Monaco,
 Consolas,
 'Liberation Mono',
 'Courier New',
 monospace !default;
 $font-family-base: $font-family-sans-serif !default;
 $font-family-noto: "Noto Sans JP", sans-serif;
 body {
    font-family:$font-family-noto;
     color: $color-body;
 }

 ul {
     padding-inline-start: 20px;
 }

 // size 拡張
 // .w-XX
 $sizes: ( ( 10: 10%, 20: 20%, 25: 25%, 30: 30%, 40: 40%, 50: 50%, 60: 60%, 70: 70%, 75: 75%, 80: 80%, 90: 90%, 100: 100%, auto: auto, ));
 // badge size
 $badge-font-size: 14px;
 // !important がデフォルトで入るので不要
 $box-shadow-sm: 0 0.125rem 0.25rem rgba(#000000, 0.075);
 $box-shadow: 0 0.25rem 0.5rem rgba(#000000, 0.15);
 $box-shadow-lg: 0 0.5rem 1rem rgba(#000000, 0.175);
 .shadow {
     box-shadow: $box-shadow;
     transition: box-shadow 0.2s ease;
     &:hover {
         box-shadow: $box-shadow-lg;
     }
 }

 // margin,padding　拡張
 $spacer: 1rem;
 $spacers: ();
 $spacers: map-merge( ( 6: ( $spacer * 4, ), 7: ( $spacer * 5, ), 8: ( $spacer * 6.25, ), 9: ( $spacer * 9.25, ), 10: ( $spacer * 12.5, ), ), $spacers);
 // bootstrap overwrite
 @import 'mixins';
 @import 'buttons';
 @import 'mixins/breakpoints';
 @import 'grid';
 @import 'utilities/text';
 @import 'utilities/background';
 @import 'utilities/borders';
 @import 'utilities/sizing';
 @import 'utilities/shadows';
 @import 'utilities/spacing';
 @import 'badge';
 // ----------------------------------------------------------------------
 // ----------------------------------------------------------------------
 // btn customize overwrite
 // ボタン反転mixin
 @mixin button-outline-variant-re($color, $color-hover: color-yiq($color), $active-background: $color, $active-border: $color) {
     color: $color-hover;
     background-color: $active-background;
     border-color: $active-border;
     @include hover() {
         color: $color;
         border-color: $color;
         background-color: #fff;
     }
     &:focus,
     &.focus {
         color: color-yiq($active-background);
         @include gradient-bg($active-background);
         border-color: $active-border;
         @if $enable-shadows {
             @include box-shadow($btn-box-shadow, 0 0 0 $btn-focus-width rgba(mix(color-yiq($color), $active-border, 15%), .5));
         }
         @else {
             // Avoid using mixin so we can pass custom focus shadow properly
             box-shadow: 0 0 0 $btn-focus-width rgba(mix(color-yiq($color), $active-border, 15%), .5);
         }
     }
     &.disabled,
     &:disabled {
         color: color-yiq($color);
         background-color: $color;
         border-color: $active-border;
         // Remove CSS gradients if they're enabled
         @if $enable-gradients {
             background-image: none;
         }
     }
     &:not(:disabled):not(.disabled):active,
     &:not(:disabled):not(.disabled).active,
     .show>&.dropdown-toggle {
         color: color-yiq($active-background);
         background-color: $active-background;
         @if $enable-gradients {
             background-image: none; // Remove the gradient for the pressed/active state
         }
         border-color: $active-border;
         &:focus {
             @if $enable-shadows and $btn-active-box-shadow !=none {
                 @include box-shadow($btn-active-box-shadow, 0 0 0 $btn-focus-width rgba(mix(color-yiq($color), $active-border, 15%), .5));
             }
             @else {
                 // Avoid using mixin so we can pass custom focus shadow properly
                 box-shadow: 0 0 0 $btn-focus-width rgba(mix(color-yiq($color), $active-border, 15%), .5);
             }
         }
     }
 }

 // hover時の配色の条件分岐
 @if $enable-btn-hover-border-style {
     // hover時にアウトライン化
     @each $color,
     $value in $theme-colors {
         .btn-#{$color} {
             @include button-outline-variant-re($value);
         }
     }
 }

 @else {
     // hover時に従来の暗色化
     @each $color,
     $value in $theme-colors {
         .btn-#{$color} {
             @include button-variant($value, $value);
         }
     }
 }

 // ----------------------------------------------------------------------
 // font size
 $font-size-base: 1rem; // Assumes the browser default, typically `16px`
 $h1-font-size: $font-size-base * 3.1; // 2.5
 $h2-font-size: $font-size-base * 2; // 2
 $h3-font-size: $font-size-base * 1.75; // 1.75
 $h4-font-size: $font-size-base * 1.5; // 1.5
 $h5-font-size: $font-size-base * 1.25; // 1.25
 $h6-font-size: $font-size-base;
 p {
     // line-height: 2rem;
 }

 #contact-form {
     section p {
         line-height: 2rem;
     }
     .dcms_error_area {
         p {
             line-height: 2rem !important;
             &.error_text {
                 display: block;
             }
         }
         &+div {
             float: left;
             margin-bottom: 1rem;
             width: 100%;
             height: 0;
         }
     }
     input[type='text'] {
         width: 100%;
         &.family_name,
         &.first_name {
             width: 45%;
             @include media-breakpoint-down(md) {
                 width: 100%;
             }
         }
         &.family_name_yomi,
         &.first_name_yomi {
             width: 43%;
             @include media-breakpoint-down(md) {
                 width: 100%;
             }
         }
     }
     textarea {
         max-width: 100%;
         width: 100%;
     }
     #table_address {
         tr {
             td {
                 padding: 8px 0;
                 &:first-child {
                     min-width: 6rem;
                     line-height: 1.4;
                 }
             }
         }
     }
 }

 // 管理画面に影響があるため囲う
 #dcms_layoutPageBlockPasteArea {
     p {
         line-height: 1.75;
         color: $color-p;
     }
     .text-white {
         p {
             color: #fff;
         }
     }
 }

 #dcms_global_header .dropdown-toggle::after {
     display: none;
 }

 // type レスポンシブ時のサイズ対応
h1,.h1 {
    margin-bottom: 1rem;
}

h2,.h2 {
    margin-bottom: 1.25rem;
    line-height: 1.5 !important;
    @include media-breakpoint-up(sm) {
        margin-bottom: 1.5rem;
    }
    @include media-breakpoint-up(lg) {
        margin-bottom: 1.5rem;
    }
}
h3, .h3 {
    margin-bottom: 1.25rem;
    line-height: 1.5 !important;
}
h4,.h4 {
    margin-bottom: 1rem;
    line-height: 1.5 !important;
}
h5,.h5 {
    margin-bottom: 1rem;
    line-height: 1.5 !important;
}
h6,.h6 {
    margin-bottom: 1rem;
    line-height: 1.5;
}
small,.small {
    font-size: 90%;
}

 // md
 @include media-breakpoint-up(md) {
     h1,
     .h1 {
         @include font-size($h1-font-size * 1);
     }
     h2,
     .h2 {
         @include font-size($h2-font-size * 1);
     }
     h3,
     .h3 {
         @include font-size($h3-font-size * 1);
     }
     h4,
     .h4 {
         @include font-size($h4-font-size * 1);
     }
     h5,
     .h5 {
         @include font-size($h5-font-size * 1);
     }
     h6,
     .h6 {
         @include font-size($h6-font-size * 1);
     }
 }

 // md
 @include media-breakpoint-down(md) {
     h1,
     .h1 {
         @include font-size($h1-font-size * 0.9);
     }
     h2,
     .h2 {
         @include font-size($h2-font-size * 0.9);
     }
     h3,
     .h3 {
         @include font-size($h3-font-size * 0.9);
     }
     h4,
     .h4 {
         @include font-size($h4-font-size * 0.9);
     }
     h5,
     .h5 {
         @include font-size($h5-font-size * 0.95);
     }
     h6,
     .h6 {
         @include font-size($h6-font-size * 1);
     }
 }

 // sm
 @include media-breakpoint-down(sm) {
     h1,
     .h1 {
         @include font-size($h1-font-size * 0.6);
     }
     h2,
     .h2 {
         @include font-size($h2-font-size * 0.7);
     }
     h3,
     .h3 {
         @include font-size($h3-font-size * 0.75);
     }
     h4,
     .h4 {
         @include font-size($h4-font-size * 0.85);
     }
     h5,
     .h5 {
         @include font-size($h5-font-size * 0.95);
     }
     h6,
     .h6 {
         @include font-size($h6-font-size * 1);
     }
 }

// title 共通クラス
.dlb_title-h1 {
}

.dlb_title-h2 {
}

.dlb_title-h3 {
}

.dlb_title-h4 {
}

.dlb_title-h5 {
}

.dlb_title-h6 {}

 // メインイメージ用のテキスト
 .dlb_title-copy {
     font-size: $h1-font-size;
     // size h1
     @include media-breakpoint-up(md) {
         @include font-size($h1-font-size * 1);
     }
     @include media-breakpoint-down(md) {
         @include font-size($h1-font-size * 0.7);
     }
     @include media-breakpoint-down(sm) {
         @include font-size($h1-font-size * 0.6);
     }
 }

 // 線の入ったタイトルなどに使用
 // | xxxxxxxxxxxxxxxxx
 // -------------------
 .dlb_title-sub-01 {
     border-left-color: $main;
     &::after {
         background-color: #ddd;
     }
 }

 // 線の入ったタイトルなどに使用 2
 // | xxxxxxxxxxxxxxxxx
 .dlb_title-sub-02 {
     border-bottom-color: $main;
     border-left-color: $main;
 }

 // 先頭に細いボーダーのタイトル
 .dlb_title-sub-03 {
     border-left-color: $main;
 }

 // 先頭に長方形のタイトル
 .dlb_title-sub-04 {
     &::before {
         background-color: $main;
     }
 }

 // 下ボーダーのタイトル
.dlb_title-sub-05 {
    border-bottom-color: $main;
}

 // 下に短い線のタイトル
 .dlb_title-sub-06 {
     &::after {
         background-color: $main;
     }
 }

 // 塗りつぶしのタイトル
 .dlb_title-sub-07 {
     background-color: $main;
     color: $white;
 }

 // ブログ用のタイトル
 .dlb_title-blog {
     // color
     color: $main;
     // size h2
     @include media-breakpoint-up(md) {
         @include font-size($h2-font-size * 1);
     }
     @include media-breakpoint-down(md) {
         @include font-size($h2-font-size * 0.9);
     }
     @include media-breakpoint-down(sm) {
         @include font-size($h2-font-size * 0.7);
     }
 }

 // 削除予定
 .title-03 {
     position: relative;
     border-bottom: 1px solid #ddd;
     padding-left: 19px;
     padding-bottom: 22px;
     margin-bottom: 2rem;
     font-size: 1.625rem;
     padding-top: 2px;
     color: #777;
     &::after {
         content: '';
         position: absolute;
         top: 0px;
         left: 0;
         display: block;
         width: 5px;
         height: 35px;
         background-color: $main;
     }
 }

 .border_custom-01 {
     background-color: $accent-02;
     padding-left: 1rem;
     .d-table-cell {
         background-color: #fff;
         border: 1px solid #ccc;
         border-left: none;
     }
 }

 .badge {
     padding: 0.3em 0.4em;
 }

 // stretched-link 拡張
 .stretched-link {
     // stretched-link 打ち消し
     &::after {
         content: none !important;
     }
     a {
         color: inherit !important;
         text-decoration: none;
         &::after {
             position: absolute;
             top: 0;
             right: 15px; // col right padding
             bottom: 0;
             left: 15px; // col left padding
             z-index: 1;
             // Just in case `pointer-events: none` is set on a parent
             pointer-events: auto;
             content: '';
             // IE10 bugfix, see https://stackoverflow.com/questions/16947967/ie10-hover-pseudo-class-doesnt-work-without-background-color
             background-color: rgba(0, 0, 0, 0);
         }
     }
 }

 // js-matchHeight 指定
 .js-matchHeight {
     .js-matchHeight-middle {
         display: flex;
         flex: 1 0 auto;
         align-items: center;
         flex-wrap: wrap;
         justify-content: center;
     }
     .js-matchHeight-top {}
 }

 // container
 .container {
     margin-top: 1.5rem;
     margin-bottom: 1.5rem;
 }

 section {
     overflow: visible;
 }

 // img
 // クラス削除のため
 .img-fluid {
     margin-bottom: 0;
     img {
         width: 100%;
         max-width: 100%;
         height: auto;
     }
 }

 .figure img {
     margin-bottom: 0.5rem;
     line-height: 1;
 }

 // a
 // クラス削除のため
 div.btn {
     padding: 0;
     a {
         display: block;
         padding-top: 8px;
         padding-bottom: 8px;
         padding-left: 24px;
         padding-right: 24px;
         background-color: transparent;
         border: none;
         color: inherit;
         line-height: 1.6;
         text-decoration: inherit;
     }
     &.btn-lg {
         a {
             padding-top: 16px;
             padding-bottom: 16px;
         }
     }
     &.d-table {
         a {
             display: table-cell;
             vertical-align: middle;
         }
     }
 }

 // rounded > img
 // imgのクラス削除のため
 .rounded-sm,
 .rounded,
 .rounded-top,
 .rounded-right,
 .rounded-bottom,
 .rounded-left,
 .rounded-lg,
 .rounded-circle,
 .rounded-pill {
     overflow: hidden;
 }

 .__rounded,
 .__img-rounded img{
    border-radius: 10px;
 }

 .__rounded100,
 .__img-rounded100 img{
    border-radius: 100vh;
 }



 // 横スクロール
 .x-scroll {
     display: block;
     width: 100%;
     overflow-x: auto;
     -webkit-overflow-scrolling: touch;
 }

 // collapsed 回転する
 .collapsed img {
     transform: rotate(180deg);
 }

 ol {
     margin-bottom: 0;
 }

 .disable-auto-tel a[href^='tel:'] {
     color: inherit;
     text-decoration: none;
     pointer-events: none;
 }

 // --------------------------------------------------
 // BM用カスタムクラス
 // 下記のみ@extend可能
 // .btn-xxx, .border-xxx, .bg-xxx, .w-XX
 // --------------------------------------------------
 // title　h1~h6相当 lp用
 .bm_title-01 {
     margin-bottom: 24px;
     text-align: center;
 }

 .bm_title-02 {
     margin-bottom: 16px;
     text-align: center;
 }

 .bm_title-03 {}

 .bm_title-04 {}

 .bm_title-05 {}

 .bm_title-06 {}

 // ナビゲーションバー　LP版
 .bm_navbar {
     @extend .bg-gray-300;
     padding-top: 0.25rem;
     padding-bottom: 0.25rem;
     // headerの高さ分を記述
     &+.bs_header-spacer {
         height: 52px;
     }
     // logo
     .bm_logo {
         padding: 0;
         img {
             // LP版ヘッダーのlogoの高さ制御
             max-height: 35px;
             width: auto;
             vertical-align: top;
         }
     }
     // 開閉するブロック
     .bm_navbar-toggle {
         @extend .w-100;
         @extend .bg-gray-800;
         ul {
             padding: 1.5rem;
             text-align: center;
         }
         // リンク
         a {
             display: block;
             padding: 0.5rem;
             color: #ffffff;
         }
     }
 }

 // IE11
 // ナビゲーションバーの高さ分記述
 // @media all and (-ms-high-contrast: none) {
 //   *::-ms-backdrop,
 //   .bm_header {
 //     // margin-top: 43px;
 //   }
 // }
 // ヘッダー　メインイメージ等
 .bm_header {
     @extend .bg-gray-600;
     text-align: center;
 }

 // フッター
 .bm_footer {
     @extend .bg-gray-300;
     padding-top: 1rem;
     padding-right: 1rem;
     padding-left: 1rem;
     padding-bottom: 3rem;
     text-align: center;
     .bm_footer-list {
         list-style: none;
         li {
             display: inline;
             a {
                 display: inline-block;
                 padding-right: 0.5rem;
                 padding-left: 0.5rem;
                 border-left: 1px solid #aaa;
             }
             &:first-child a {
                 border-left: none;
             }
         }
     }
     // コピーライト
     .bm_copy {
         display: block;
         margin-bottom: 1rem;
     }
 }

 // ページ下部 シェアボタン等
 .bm_shere {}

 // --------------------------------------------------
 // Bootstrap ヘッダーフッター　カスタム
 // --------------------------------------------------
 // stickey footer
 body {
     display: flex;
     flex-direction: column;
 }

 #wrapper {
     min-height: 100vh;
     display: flex;
     flex-direction: column;
 }

 #dcms_layoutPageBlockPasteArea,
 #container {
     flex: 1 0 auto;
 }

 // navbar-toggler > span 禁止対策
 .navbar-toggler {
     padding: 0;
     &.navbar-toggler-icon {
         // display: inline-block;
         // width: 2.75em;
         width: 2.85em;
         // height: 2em;
         height: 2.2em;
         vertical-align: middle;
         background: no-repeat center center;
         background-size: 30px 30px;
         background-position: 50% 0;
         .navbar-dark & {
             background-image: url("/dcms_media/other/sp-nav-btn.svg");
         }
         .navbar-light & {
             background-image: url("/dcms_media/other/sp-nav-btn.svg");
         }
         &::after {
             content: 'メニュー';
             display: block;
             font-size: 10px;
             font-weight: bold;
             margin-top: 24px;
             color: $color-p;
         }
     }
 }

 // テキストリンクの矢印
 .bs_text-link {
     a {
         text-indent: -16px;
         // color: $main !important;
         // color: $color-03 !important;
     }
     a::before {
         content: '';
         position: relative;
         top: -2px;
         display: inline-block;
         width: 8px;
         height: 8px;
         margin-right: 0.5rem;
         // border-top: 2px solid darken($main,10%);
         border-top: 2px solid $color-03;
         // border-right: 2px solid darken($main,10%);
         border-right: 2px solid $color-03;
         -webkit-transform: rotate(45deg);
         transform: rotate(45deg);
     }
     .text-white & a::before {
         border-top-color: #fff;
         border-right-color: #fff;
     }
     &.btn-link {
         text-align: left;
     }
 }

 // header tel
 .header-tel {
     white-space: nowrap;
     display: flex;
     align-items: center;
     @include media-breakpoint-down(md) {
         width: 100%;
         text-align: center;
         justify-content: center;
         margin-bottom: 10px;
     }
     @media screen and (min-width:992px) and (max-width:1320px) {
         padding-right: 0.5rem;
         .nav-btn {
             text-align: right;
         }
     }
     .btn {
         cursor: default !important;
     }
     .tel {
         display: inline-block;
         padding-left: 24px;
         background-position: 0 center;
         background-repeat: no-repeat;
         background-image: url(/dcms_media/image/common/telephone-fill.png);
         font-weight: bold;
         font-size: 20px;
         line-height: 20px;
         a {
             padding: 0;
             cursor: default !important;
         }
         @include media-breakpoint-down(md) {
             font-size: 30px;
             line-height: 40px;
             margin-bottom: 4px;
             padding-left: 24px;
         }
     }
     .small {
         display: block;
         font-size: 10px;
         color: #777;
         @include media-breakpoint-down(md) {
             font-size: .9rem;
         }
     }
 }

 .icon-mail {
   a {
     &:before {
       content: "";
       background: url(/dcms_media/image/common/icon-mail.png) 0 0 no-repeat;
       width: 24px;
       height: 24px;
       display: inline-block;
       // position: relative;
       // top: 2px;
       margin-right: 6px;
       // margin-top: -2px;
       vertical-align: middle;
     }
   }

   &:hover {
     a {
       &:before {
         background: url(/dcms_media/image/common/icon-mail-on.png) 0 0 no-repeat;
       }
     }
   }
 }

 .icon-download a {
     &:before {
         content: '';
         background: url(/dcms_media/image/common/icon-download.png) 0 0 no-repeat;
         width: 24px;
         height: 24px;
         display: inline-block;
         // position: relative;
         // top: 8px;
         margin-right: 6px;
         // margin-top: -8px;
         vertical-align: middle;
     }
 }

 // dropdown hover 対応 md以下は非対応

.dropdown-menu{
    top: 112px;
    left: 0;
    width: 100%;
}
.dropdown:hover {
    .nav-link {
        color: $accent-02 !important;
        &::after {
            width: 50% !important;
        }
    }
    &>.dropdown-menu {
        display: block;
        border: none;
        @include media-breakpoint-down(md) {
            display: none;
        }
    }
}




.dropdown-header{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 25px 0;
    .__title{
        font-size: 1.875rem;
        margin-right: 1em;
    }
    .btn{
        margin-left: auto;
    }
}

.dropdown-body{
    background-color: rgba($main, .9);;
    padding: 30px 0 50px;
    .rounded{
        border-radius: 10px;
        img{
            border-radius: 10px;
        }
    }
    .__dropdown-grid5{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 20px;
    }
    .__dropdown-grid32{
        display: grid;
        grid-template-columns: 60% 40%;
        gap: 20px;
        grid-template-areas:
            "box3 box2";
    }
    .box3{
        grid-area: box3;
    }
    .box2{
        grid-area: box2;
    }
    .__dropdown-grid3{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
    }
    .__dropdown-grid2{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }
}




 // Bootstrap-01
 .bs_header-01 {
     padding: 0;
     box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
     background-color: #fff;
     .navbar-brand {
         padding: 0;
         margin-right: 0;
         img {
             width: auto;
             height: 80px;
             transition: opacity 0.2s ease;
             @include media-breakpoint-down(lg) {
                 height: 41px;
             }
         }
         &:hover {
             img {
                 opacity: 0.8;
             }
         }
     }
     &.navbar-light .navbar-toggler {
         border: none;
     }
     .navbar-nav {
         // min-height: 70px;
     }
     &.navbar-light .navbar-nav a.nav-link {
         color: $color-p;
         font-weight: 600;
         display: flex;
         align-items: center;
        position: relative;
         // height: 100%;
         &::after{
            content: "";
            width: 0;
            height: 2px;
            background-color: #fff;
            position: absolute;
            bottom: 20px;
            left: 25%;
         }
         &:hover,
         &:active,
         &:focus {
             color: $accent-02 !important;
            &::after {
                    width: 50%;
                }
         }
         @include media-breakpoint-up(lg) {
             height: 72px;
         }
     }
    @include media-breakpoint-down(md) {
        .__spinfo{
            display: flex;
            flex-direction: column;
            .header-tel{
                order: 1;
            }
            .icon-book{
                order: 3;
                margin-top: 10px;
            }
            .icon-mail{
                order: 2;
            }
        }
    }
     .dropdown-item {
         &:hover,
         &:focus {
            color: $accent-02  !important;
         }
         &:active {
             color: $accent-02 !important;
             background-color: transparent;
         }
     }
     .nav-btn {
         // @include media-breakpoint-up(lg) {
         //   a {
         //     display: flex;
         //     flex-direction: column;
         //     justify-content: center;
         //     align-items: center;
         //     min-height: 87px;
         //   }
         // }
         img {
             @include media-breakpoint-down(md) {
                 display: none;
             }
         }
     }
     .nav-btn {
         img {
             @include media-breakpoint-down(md) {
                 display: none;
             }
         }
     }
     // headerの高さ分を記述
     &+.bs_header-spacer {
         height: 72px;
         @include media-breakpoint-down(md) {
             height: 44px;
         }
         // // Gナビ2段表示用
         // @media screen and (min-width: 992px) and (max-width: 1320px) {
         //   height: 113px;
         // }
     }
     // dropdown
     &.bs_header-01-drop {
         .navbar-nav {
             min-height: 87px;
         }
         &.navbar-light .navbar-nav a.nav-link {
             @include media-breakpoint-up(lg) {
                 height: 112px;
             }
         }
         &+.bs_header-spacer {
             height: 87px;
             @include media-breakpoint-down(md) {
                 height: 50px;
             }
             // Gナビ2段表示用
             @media screen and (min-width: 992px){
                 height: 112px;
             }
         }
         // rwd
         #navbar-01 {
             // Gナビ2段表示用
             @media screen and (min-width: 992px){
                 flex-flow: column;
                 .navbar-nav {
                     order: 2;
                     margin-right: 0;
                     padding: 0 !important;
                     min-height: 61px;
                     a.nav-link {
                         height: 61px;
                     }
                 }
                 &>div {
                     padding-top: 8px !important;
                     margin-left: auto;
                 }
             }
         }
     }
     @include media-breakpoint-up(xl) {
         .container-xl {
             max-width: 1300px;
         }
     }
     @include media-breakpoint-down(md) {
         .navbar-nav {
             flex-direction: row;
             flex-wrap: wrap;
             padding-bottom: 0 !important;
         }
         .nav-item {
             flex: 1 0 40%;
             max-width: 50%;
         }
     }
     @include media-breakpoint-down(sm) {
         .nav-item {
             flex: 0 0 100%;
             max-width: none;
         }
     }
 }

 .bs_footer-01 {
     .bs_footer-icon {
         img {
             @include media-breakpoint-down(sm) {
                 width: auto;
                 height: 38px;
             }
         }
     }
     .bs_footer-logo {
         flex: 0 0 auto;
         width: auto;
         &:hover {
             img {
                 opacity: 0.8;
             }
         }
         img {
             transition: opacity 0.2s ease;
         }
     }
     .bs_footer-link {}
     @include media-breakpoint-up(lg) {
         .bs_copyright {
             font-size: .9rem;
         }
     }
 }

 // Bootstrap-02
 .bs_header-02 {
     padding: 0;
     box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
     .navbar-brand {
         padding: 0;
         margin-right: 0;
         img {
             width: auto;
             height: 50px;
             transition: opacity 0.2s ease;
         }
         &:hover {
             img {
                 opacity: 0.8;
             }
         }
     }
     .nav-btn {
         img {
             @include media-breakpoint-down(md) {
                 display: none;
             }
         }
     }
     // headerの高さ分を記述
     &+.bs_header-spacer {
         height: 72px;
         @include media-breakpoint-down(md) {
             height: 50px;
         }
     }
     @include media-breakpoint-up(xl) {
         .container-xl {
             max-width: 1340px;
         }
     }
     @include media-breakpoint-down(md) {
         .navbar-nav {
             flex-direction: row;
             flex-wrap: wrap;
         }
         .nav-item {
             flex: 1 0 40%;
             max-width: 50%;
         }
     }
     @include media-breakpoint-down(sm) {
         .nav-item {
             flex: 0 0 100%;
             max-width: none;
         }
     }
 }

 .bs_footer-02 {
     .bs_footer-contact {
         .bs_footer_contact-title {
             display: flex;
             justify-content: center;
             align-items: center;
         }
     }
     .bs_footer-icon {
         img {
             @include media-breakpoint-down(sm) {
                 width: auto;
                 height: 48px;
             }
         }
     }
     .bs_footer-logo {
         &:hover {
             img {
                 opacity: 0.8;
             }
         }
         img {
             transition: opacity 0.2s ease;
         }
     }
     .bs_footer-link {}
 }

 // Bootstrap-03
 .bs_header-03 {
     padding: 1rem;
     &.sticky-top {}
     .container {
         box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
         border-radius: 6px;
     }
     @include media-breakpoint-up(xl) {
         .container {
             max-width: 1120px;
         }
     }
     .navbar-brand {
         padding: 0;
         margin-right: 0;
         img {
             width: auto;
             height: 50px;
             transition: opacity 0.2s ease;
         }
         &:hover {
             img {
                 opacity: 0.8;
             }
         }
     }
     // headerの高さ分を記述
     &+.bs_header-spacer {
         // sp
         height: 82px;
         @include media-breakpoint-up(md) {
             height: 82px;
         }
         @include media-breakpoint-up(lg) {
             height: 92px;
         }
     }
     .navbar-toggler {
         position: relative;
         z-index: 1000;
     }
     .navbar-collapse {
         // transition: opacity 0.5s ease;
         .navbar-collapse-brand {
             // display: none;
             margin-left: 1rem;
             img {
                 width: auto;
                 height: 50px;
             }
         }
         &.collapse {
             &.show {
                 // opacity: 1;
                 // position: fixed;
                 // top: 0;
                 // left: 0;
                 // z-index: 1;
                 // width: 100vw;
                 // height: 100vh;
                 // padding-top: 15px;
                 // background-color: #fff;
                 ul {
                     padding: 1rem;
                 }
                 .navbar-collapse-brand {
                     // display: block;
                 }
             }
         }
         &.collapsing {
             // opacity: 0;
             // position: fixed;
             // top: 0;
             // left: 0;
             // z-index: 1;
             // width: 100vw;
             // height: 100vh !important;
             // padding-top: 15px;
             background-color: #fff;
             ul {
                 padding: 1rem;
             }
         }
     }
     // ドロップダウン用
     .nav-overflow {
         max-width: 12rem;
         display: inline-block;
         width: auto;
         overflow: hidden;
         text-overflow: ellipsis;
         vertical-align: inherit;
         @include media-breakpoint-down(lg) {
             max-width: 8rem;
         }
         @include media-breakpoint-down(md) {
             max-width: 20rem;
         }
         @include media-breakpoint-down(sm) {
             max-width: 14rem;
         }
     }
     @include media-breakpoint-down(md) {
         .navbar-nav {
             flex-direction: row;
             flex-wrap: wrap;
         }
         .nav-item {
             flex: 1 0 40%;
             max-width: 50%;
         }
     }
     @include media-breakpoint-down(sm) {
         .nav-item {
             flex: 0 0 100%;
             max-width: none;
         }
     }
 }

 .bs_footer-03 {
     @include media-breakpoint-up(md) {
         .bs_footer-contact {
             &>div {
                 padding-bottom: 43px;
             }
             .bs_footer-btn {
                 position: absolute;
                 bottom: 0;
                 left: 15px;
                 right: 15px;
                 width: calc(100% - 30px);
                 a {
                     // overflow: hidden;
                     // white-space: nowrap;
                     // text-overflow: ellipsis;
                     height: 100%;
                     display: flex;
                     justify-content: center;
                     align-items: center;
                 }
                 .bs_footer_btn-inner {}
             }
         }
     }
     .bs_footer-icon {
         img {
             @include media-breakpoint-down(sm) {
                 width: auto;
                 height: 48px;
             }
         }
     }
     .bs_footer-logo {
         &:hover {
             img {
                 opacity: 0.8;
             }
         }
         img {
             transition: opacity 0.2s ease;
         }
     }
     .page_top {
         position: absolute;
         top: -56px;
         left: 50%;
         width: 122px;
         height: 56px;
         transform: translateX(-50%);
         text-align: center;
         text-decoration: none;
         overflow: hidden;
         a {
             display: block;
             height: 100%;
             padding-top: 30px;
         }
         &:before {
             position: absolute;
             top: 16px;
             left: 11px;
             content: '';
             display: block;
             width: 100px;
             height: 100px;
             border-radius: 15px;
             background-color: $color-02;
             transform: rotate(45deg);
             z-index: -1;
         }
     }
     .bs_footer-link {}
 }

 // --------------------------------------------------
 // BM ダウンロードブロック　カスタム
 // --------------------------------------------------
 //
 .dlb_contents_wrap-double {
     .dlb_contents_wrap-title {
         padding: 5rem 0 10rem;
     }
     .dlb_contents_wrap-data {
         margin-top: 0;
         border-radius: 20px;
         // 開閉アイコン表示周り
         .accodion-link {
             position: relative;
             border: none;
             outline: none;
             background-color: transparent;
             background-position: 0 0;
             background-repeat: no-repeat;
             width: 100%;
             text-align: left;
             margin: 20px 0 6px;
             padding: 0 0 0 40px;
             color: #333;
             &:hover,
             &:active,
             &:focus {
                 outline: none;
             }
             &::before {
                 position: absolute;
                 top: 0;
                 left: 0;
                 content: '';
                 display: inline-block;
                 width: 24px;
                 height: 24px;
                 background-color: $main;
                 border: 1px solid $main;
                 transition: 0.3s ease;
                 border-radius: 50%;
                 vertical-align: bottom;
                 // margin-right: 15px;
             }
             &:after {
                 @include btn-arrow($size: 8px, $margiin-top: -2px, $border-width: 2px, $border-color: #fff, $rotate: -45deg);
                 position: absolute;
                 top: 11px;
                 left: 8px;
                 margin-right: 0;
                 transition: 0.3s ease;
                 transform-origin: center center 0;
             }
             &.collapsed {
                 &::before {
                     background-color: #fff;
                     border: 1px solid $accent-02;
                 }
                 &:after {
                     transform: rotate(-225deg);
                     top: 8px;
                     left: 8px;
                     border-color: $main;
                 }
             }
         }
     }
 }

 // ダウンロードブロックの配色等
 .dlb_firstview-left {
     background-repeat: no-repeat;
     background-size: cover;
     margin-bottom: 3rem;
     .container {
         margin-top: 0;
         margin-bottom: 0;
     }
     .img-fluid {
         max-height: 100%;
         padding-bottom: 2rem;
         img {
             width: auto;
         }
     }
 }

 .dlb_firstview-center {
     .container {
         margin-top: 0;
         margin-bottom: 0;
     }
 }

 .dlb_contact_column {
     .btn-contact {
         a:before {
             content: '';
             display: inline-block;
             width: 15px;
             height: 16px;
             margin-right: 10px;
         }
     }
     .btn-icon-download {
         a:before {
             content: '';
             display: inline-block;
             width: 25px;
             height: 25px;
             position: relative;
             top: 6px;
             margin-top: -6px;
             margin-right: 10px;
         }
     }
 }

 .dlb_firstview-text {
     &:before {
         background-color: $color-01;
     }
     .bg-img {
         background-color: $color-02;
     }
 }

 // dlb_contents_boxlink-icon 市松配色
 .dlb_contents_boxlink-icon {
     .dlb_contents_boxlink-item {
         background-color: $color-01;
         &:nth-child(8n+1) {
             background-color: darken($color-01, 10%);
         }
         &:nth-child(8n+3) {
             background-color: darken($color-01, 10%);
         }
         &:nth-child(8n+6) {
             background-color: darken($color-01, 10%);
         }
         &:nth-child(8n+8) {
             background-color: darken($color-01, 10%);
         }
         @include media-breakpoint-down(sm) {
             &:nth-child(8n+3) {
                 background-color: $color-01;
             }
             &:nth-child(8n+4) {
                 background-color: darken($color-01, 10%);
             }
             &:nth-child(8n+5) {
                 background-color: darken($color-01, 10%);
             }
             &:nth-child(8n+6) {
                 background-color: $color-01;
             }
         }
     }
 }

 // dlb_contents_box_column-2 線と文字配色
 .dlb_contents_box_column-2 {
     .dlb_contents_box-title {
         border-bottom: 1px solid $main;
         color: $main;
         padding-bottom: 16px;
         @include media-breakpoint-up(sm) {
             border-bottom: none;
             border-right: 1px solid $main;
             padding-bottom: 0;
         }
     }
 }

 // dlb_contents_box_bg 市松配色
 .dlb_contents_box_bg {
     .dlb_contents_box_bg-item {
         background-color: $color-01;
         &:nth-child(4n+1) {
             background-color: darken($color-01, 10%);
         }
         &:nth-child(4n+4) {
             background-color: darken($color-01, 10%);
         }
         @include media-breakpoint-down(md) {
             &:nth-child(4n+3) {
                 background-color: darken($color-01, 10%);
             }
             &:nth-child(4n+4) {
                 background-color: $color-01;
             }
         }
         @include media-breakpoint-down(sm) {
             &:nth-child(4n+3) {
                 background-color: darken($color-01, 10%);
             }
             &:nth-child(4n+4) {
                 background-color: $color-01;
             }
         }
     }
 }

 // dlb_contents_overflow 背景色
 .dlb_contents_overflow {
     // 右
     .dlb_contents_overflow-right {
         &:before {
             background-color: $color-01;
         }
         .dlb_contents_overflow-data {
             @include media-breakpoint-down(sm) {
                 background-color: $color-01;
             }
         }
     }
     // 左
     .dlb_contents_overflow-left {
         &:before {
             background-color: $color-01;
         }
         .dlb_contents_overflow-data {
             @include media-breakpoint-down(sm) {
                 background-color: $color-01;
             }
         }
     }
 }

 // dlb_flow-1 下矢印の配色
 .dlb_flow-1 {
     .dlb_flow-item {
         &+.dlb_flow-item {
             &::before {
                 border-color: transparent transparent transparent $accent-02;
             }
         }
     }
 }

 // dlb_flow-2 下矢印の配色と線の色
 .dlb_flow-2 {
     .dlb_flow-item {
         &+.dlb_flow-item {
             .dlb_flow-title::before {
                 border-color: $accent-02 transparent transparent transparent;
             }
         }
         .dlb_flow-data {
             border-color: $color-02 !important;
         }
     }
 }

 // dlb_flow-3 下矢印部分の配色
 .dlb_flow-3 {
     .dlb_flow-item {
         .dlb_flow-title::before {
             background: linear-gradient(to bottom left, $main 49%, transparent 50%) top left/ 50% 100% no-repeat, linear-gradient(to bottom right, $main 49%, transparent 50%) top right / 50% 100% no-repeat !important;
         }
     }
 }

 // dlb_flow-4 下矢印の配色
 .dlb_flow-4 {
     .dlb_flow-item {
         &+.dlb_flow-item {
             .dlb_flow-title::before {
                 border-color: $accent-02 transparent transparent transparent;
             }
         }
         @include media-breakpoint-up(sm) {
             .dlb_flow-data {
                 border-color: $color-02 !important;
             }
         }
     }
 }

 // .dlb_flow-5
 .dlb_flow-5 {
     .dlb_flow-item {
         &+.dlb_flow-item {
             &::before {
                 border-color: $accent-02 transparent transparent transparent;
             }
         }
     }
     .dlb_flow-label {
         background-color: $color-02;
         &:before {
             border-color: transparent darken($color-02, 20%) transparent transparent;
         }
     }
 }

 // dlb_news_pickup
 .dlb_news_pickup {
     .dlb_news_pickup-figure {
         figcaption {
             background-color: $color-03;
             color: #fff;
         }
     }
 }

 // dlb_table_stack
 .dlb_table_stack {
     .dlb_table_stack-data {
         border-color: $color-01;
     }
 }

 // dlb_contact-area ボーダー、背景色、コメントエリア
 .dlb_contact-area {
     .col {
         border-color: $color-03;
         &+.col {
             @include media-breakpoint-down(md) {
                 border-left-color: $color-03;
             }
         }
     }
     .dlb_contact-list {
         li {
             border-bottom-color: $color-03;
         }
     }
     .dlb_contact-text {
         background-color: $color-01;
         &:after {
             border-color: transparent transparent transparent $color-01;
         }
     }
 }

 .dlb_text_wrap {
     .dlb_text_wrap-title {
         bottom: 20px;
         @include media-breakpoint-down(sm) {
             bottom: 8px;
         }
     }
 }

 .dlb_box-arrow {
     @include media-breakpoint-up(md) {
         position: relative;
         &::after {
             position: absolute;
             top: 50%;
             right: -20px;
             margin-top: -20px;
             z-index: 100;
             content: '';
             display: block;
             width: 0;
             height: 0;
             border-style: solid;
             border-width: 20px 0px 20px 20px;
             border-color: transparent transparent transparent $accent-02;
         }
     }
 }

 .dlb_news {
     .dlb_news-list {
         .list-group-item {
             background: none;
         }
     }
 }

 .dlb_news {
     .rss_date {
         @include media-breakpoint-down(xs) {
             padding: 4px 0;
         }
     }
     .rss_title {
         .rss_news {
             flex: 0 0 130px;
             overflow: hidden;
             text-align: center;
             @include media-breakpoint-down(xs) {
                 position: absolute;
                 top: -32px;
                 left: 100px;
                 width: 130px;
             }
         }
         @include media-breakpoint-down(sm) {
             flex-flow: column;
             justify-items: start;
         }
         &.stretched-link {
             a:after {
                 left: 0;
                 right: 0;
             }
         }
     }
 }

 .dlb_blog_column-4 .dlb_blog-data .dlb_title-h3 {
     max-height: 4rem;
 }

 article {
     padding: 0;
 }

 .list-indent {
     li {
         padding-left: 41px;
         text-indent: -41px;
         color: #777;
     }
     &.text-white {
         li {
             color: inherit;
         }
     }
 }

 // 中黒対応版
 .list-indent-01 {
     li {
         padding-left: 0.6rem;
         text-indent: -0.6rem;
         color: #777;
     }
     &.text-white {
         li {
             color: inherit;
         }
     }
 }

 .text-white {
     .list-indent,
     .list-indent-01 {
         li {
             color: inherit;
         }
     }
 }

 .privacy-title {
     padding-left: 1.1em;
     text-indent: -1.1em;
     @include media-breakpoint-down(sm) {
         padding-left: 1em;
         text-indent: -1em;
     }
 }

 // -----------------------------------------------------------------
 // -----------------------------------------------------------------
 //  既存ソースの移植
 // -----------------------------------------------------------------
 // -----------------------------------------------------------------
 // js
 .js_open,
 .js_morelink {
     display: none;
 }

 // media 装飾
 #locator_t_under,
 #locator,
 #locator_template_c2 {
     margin-top: 0;
 }

 // search
 .blog_search {
     margin-bottom: 24px;
     width: 100%;
     border-width: 2px;
     border-style: solid;
     input {
         display: block;
         padding: 0 4%;
         border-radius: 0;
         height: 48px;
         border: none;
         &.search_txt {
             float: left;
             line-height: 36px;
             width: 82%;
         }
         &.submit_btn {
             background-repeat: no-repeat;
             background-position: center;
             background-size: 24px auto;
             border: medium none;
             cursor: pointer;
             float: right;
             padding: 0;
             text-indent: -9999px;
             width: 18%;
         }
     }
 }

 // side
 .side {
     margin-bottom: 16px;
     li {
         list-style: none;
     }
     .title {
         font-size: 16px;
         line-height: 40px;
         text-align: center;
         margin-bottom: 0;
         padding: 10px;
     }
     .dlb_blog-item {
         position: relative;
     }
     // ul
     ul,
     .reco {
         padding-left: 0px;
     }
     .reco {
         margin-top: 20px;
         li {
             margin-bottom: 10px;
             &:after {
                 // @include clearfix;
             }
             .reco_thumbnail {
                 float: left;
                 width: 35%;
                 margin-bottom: 0;
                 img {
                     max-width: 100%;
                     height: auto;
                     width: auto;
                     display: block;
                     margin: auto;
                 }
             }
             .info {
                 float: right;
                 width: 62%;
                 font-size: 0.875rem;
                 font-weight: bold;
                 line-height: 1.3;
             }
             a {
                 display: block;
                 color: $main;
                 &:hover {
                     .info {
                         text-decoration: underline;
                     }
                 }
             }
             @media only screen and (min-width: 691px) and (max-width: 979px) {
                 width: 48%;
                 float: left;
                 margin-right: 4%;
                 margin-bottom: 0;
                 margin-top: 15px;
                 &:nth-child(2n) {
                     margin-right: 0;
                 }
             }
         }
         @media only screen and (min-width: 691px) and (max-width: 979px) {
             margin-top: 5px;
             margin-bottom: 20px;
             &:after {
                 // @include clearfix;
             }
         }
     }
     .sweep {
         padding: 20px 15px;
         // @include box_bd;
         li {
             display: inline-block;
             margin: 2px 2px 2px 0;
         }
     }
     .archive {
         li {
             display: block;
             margin: 6px 0 6px 0;
             &:first-child {
                 margin-top: 0;
             }
             &:last-child {
                 margin-bottom: 0;
             }
             a {
                 position: relative;
                 display: block;
                 font-size: 0.875rem;
                 font-weight: bold;
                color: $main;
                 &:before {
                     // @include m_arr;
                     width: 6px;
                     height: 6px;
                     display: none;
                 }
             }
             @media only screen and (min-width: 691px) and (max-width: 979px) {
                 display: inline-block;
                 margin-right: 10px;
             }
         }
     }
     .cate {
         li {
             a {
                 display: block;
                 font-size: 0.875rem;
                 line-height: 1.2;
                 // padding: 6px 12px 5px;
                 padding: 3px 6px 4px;
                 border-radius: 0.25rem;
                 font-weight: bold;
             }
         }
     }
     .tag {
         li {
             margin: 0 10px 6px 0;
             a {
                 display: block;
                 font-size: 0.875rem;
                 line-height: 1.2;
                 padding: 3px 6px 4px;
                 font-weight: bold;
                 border-radius: 0.25rem;
                 &:hover {
                     text-decoration: underline;
                 }
             }
         }
     }
     // ボタン
     .btn {
         width: 100%;
         // font-size: inherit;
         font-size: 0.875rem;
         position: relative;
         padding: 13px 2%;
         font-weight: bold;
         &:hover {
             text-decoration: none;
         }
         span {
             position: relative;
             &:before {
                 // @include m_arr;
                 width: 8px;
                 height: 8px;
                 margin-top: -4px;
             }
         }
     }
     .banner {
         li {
             margin: 3px 0;
             list-style: none;
             &:first-child {
                 margin-top: -3px;
             }
             img {
                 max-width: 100%;
                 height: auto;
                 width: auto;
                 margin: auto;
                 display: block;
             }
         }
     }
 }

 .fb-page {
     margin-bottom: 1.5rem;
 }

// sitemap
#sitemap li {
    margin: 10px 0;
    padding: 0 0 0 15px;
    list-style-type: none;
    position: relative;
}
#sitemap li::before {
    content: '';
    width: 8px;
    height: 8px;
    border-top: 2px solid $accent-01;
    border-right: 2px solid $accent-01;
    transform: rotate(45deg);
    position: absolute;
    top: 8px;
    left: 0;
}
#sitemap ul{
    padding-left: 5px;
    margin-top: 15px;
}


 .text-inherit {
     a {
         color: inherit;
     }
 }

 // article
 article {
     .info {
         time {
             font-size: 0.875rem;
         }
     }
     &#main>div:not(.catelist):not(.taglist) {
         margin-bottom: 0;
     }
 }

 .catelist,
 .taglist {
     a {
         font-size: 0.875rem;
     }
 }

 .pager {
     span,
     a {
         vertical-align: baseline;
         font-size: 1.125rem;
     }
 }

 .archivelist .list a {
     padding: 6px 15px 6px;
 }

 .sns_share.foot {
     padding-left: 0;
 }

 // Bootstrap custom
 .text-white {
     a {
         color: #fff !important;
     }
 }

 .text-black {
     a {
         color: #000 !important;
     }
 }

 .overflow-unset {
     overflow: unset;
 }

// custom
#locator,
#locator_template_c2 {
    font-size: 10px;
    line-height: 1.4;
    border-width: 1px 0 1px 0;
    border-style: solid;
    border-color: #f5f5f5;
    background-color: #f5f5f5;
    width: 100vw;
    width: 100%;
    scrollbar-width: thin;
    li {
        display: inline-block;
        list-style: none;
        margin-right: 8px;
        position: relative;
        &::after {
            content: '>';
            margin-left: 8px;
            font-size: .875rem;
            font-weight: 500;
        }
        &:last-child {
            &::after {
                display: none;
            }
        }
        a {
            color: $color-p;
            font-size: .875rem;
            font-weight: 500;
        }
        span {
            font-size: .875rem;
            font-weight: 500;
        }
    }
    .inner {
        width: 94%;
        margin-right: auto;
        margin-left: auto;
        max-width: 1120px;
        padding-left: 0;
        overflow: auto;
        white-space: nowrap;
        padding: 12px 0;
    }
}


 // main.inner {
 // width: 94%;
 // margin-right: auto;
 // margin-left: auto;
 // max-width: 1120px;
 // margin-bottom: 3rem;
 // }
 .container {
     #form_contact_main {
         width: 100%;
         margin-right: auto;
         margin-left: auto;
         max-width: 1120px;
         margin-bottom: 3rem;
         dl {
             padding: 16px 0;
             margin-bottom: 0;
             display: flex;
             @include media-breakpoint-down(sm) {
                 flex-wrap: wrap;
             }
         }
         dt {
             float: none;
             // min-width: 250px;
             flex: 0 0 250px;
             @include media-breakpoint-down(sm) {
                 flex: 1 0 100%;
             }
             span {
                 float: left;
                 padding: 0 8px;
                 margin-right: 8px;
                 font-size: 12px;
                 background: $accent-01;
                 color: #fff;
                 @include media-breakpoint-down(sm) {
                     margin-bottom: 8px;
                 }
                 // 任意
                 &.any {
                     background-color: #777;
                 }
             }
             @include clearfix;
         }
         dd {
             width: auto !important;
             float: none !important;
             flex: auto;
             // width: 100% !important;
             margin-bottom: 0;
             p {
                 display: inline-block;
             }
         }
         input[type='text'] {
             font-size: 1rem;
             background-color: #f5f5f5;
             border: 1px solid #ccc;
             color: #777;
             min-height: 39px;
         }
         select {
             padding: 0.5rem;
             font-size: 1rem;
             background-color: #f5f5f5;
             border: 1px solid #ccc;
             color: #777;
         }
         textarea {
             font-size: 1rem;
             background-color: #f5f5f5;
             border: 1px solid #ccc;
             color: #777;
         }
         label {
             margin-bottom: 0;
         }
         #submit_button {
             input {
                 @include media-breakpoint-down(sm) {
                     width: 44%;
                 }
             }
         }
     }
 }

 // right menu
 // column2 No.02
 #subnav.sb_col2_accordion {
     margin-bottom: 20px;
     ul {
         li {
             list-style: none;
             a {
                 width: 100%;
                 display: block;
                 border-width: 0 0 1px 0;
                 border-style: dotted;
                 padding: 10px;
                 &:hover {
                     text-decoration: none;
                 }
             }
             ul {
                 border-bottom: none;
                 li {
                     a {
                         padding: 5px 5px 5px 25px;
                     }
                 }
             }
         }
     }
     .sp_btn {
         display: none;
     }
 }

 #subnav_title.sb_col2_accordion a {
     display: block;
     text-align: center;
     padding: 8px 40px 8px 40px;
     font-size: 18px;
     &:hover {
         text-decoration: none;
     }
 }

 @media only screen and (max-width: 979px) {
     #subnav.sb_col2_accordion #subnav_title {
         position: relative;
     }
     #subnav.sb_col2_accordion .sp_btn {
         display: block;
         position: absolute;
         top: calc(50% - 15px);
         right: 10px;
         z-index: 2;
         width: 30px;
         height: 30px;
         border: 1px solid #cccccc;
     }
     #subnav.sb_col2_accordion .sp_btn::before,
     #subnav.sb_col2_accordion .sp_btn .in::after {
         position: absolute;
         top: calc(50% - 1px);
         left: 50%;
         content: '';
         display: inline-block;
         width: 10px;
         height: 10px;
         border-top: 2px solid #fff;
         -webkit-transform: translateX(-50%);
         -ms-transform: translateX(-50%);
         transform: translateX(-50%);
     }
     #subnav.sb_col2_accordion .sp_btn .in:after {
         top: 8px;
         left: 4px;
         -webkit-transform: rotate(90deg);
         -ms-transform: rotate(90deg);
         transform: rotate(90deg);
     }
     #subnav.sb_col2_accordion .sp_btn.active .in:after {
         display: none;
     }
     #subnav.sb_col2_accordion #subnav_list {
         display: none;
     }
     #subnav_title.sb_col2_accordion a {
         padding: 12px 40px 12px 40px;
     }
 }

 #subnav.sb_col2_accordion ul li a {
     padding: 8px 16px;
 }

 #subnav.sb_col2_accordion ul li.current ul li a {
     padding: 8px 16px;
 }

 #subnav.sb_col2_accordion ul li.current ul li.current ul li a {
     padding: 8px 16px 8px 32px;
 }

 #subnav.sb_col2_accordion ul li.current ul li.current ul li.current ul li a {
     padding: 8px 16px 8px 32px;
 }

 #subnav.sb_col2_accordion ul li.current ul li.current ul li.current ul li.current ul li a {
     padding: 8px 16px 8px 48px;
 }

 .sns_share.head {
     padding-left: 0;
 }

 #nextprev {
     padding-left: 0;
 }

 // -----------------------------------------------------------------
 // ぱんくず locator
 // -----------------------------------------------------------------
 #locator_t_under {
     font-size: 10px;
     line-height: 1.4;
     padding: 12px 0;
     border-width: 1px 0 1px 0;
     border-style: solid;
     width: 100vw;
     margin-right: calc(50% - 50vw);
     margin-left: calc(50% - 50vw);
     margin-bottom: 40px;
     margin-bottom: 0;
     width: 100%;
 }

 #locator_t_up {
     font-size: 10px;
     line-height: 1.4;
     padding: 12px 0;
     border-width: 1px 0 1px 0;
     border-style: solid;
     width: 100vw;
     margin-right: calc(50% - 50vw);
     margin-left: calc(50% - 50vw);
 }

 #locator_t_under ol {
     display: flex;
     align-items: center;
 }

 #locator_t_under li {
     list-style: none;
     position: relative;
 }

 #locator_t_under li+li::before {
     content: '>';
     margin-left: 10px;
     margin-right: 10px;
 }

 // blog media系
 // -----------------------------------------------------------------
 // _subnav.css
 // -----------------------------------------------------------------
 // column2
 #subnav.sb_col2 ul li a {
     border-color: #bbbbbb;
 }

 #subnav.sb_col2 ul li a:hover {
     color: $main;
     background-color: #f9f9f9;
 }

 #subnav.sb_col2 ul li.current a {
     border-color: #bbbbbb;
     color: $main;
     background-color: #f9f9f9;
 }

 #subnav.sb_col2 ul li.current ul {
     border-color: #bbbbbb;
 }

 #subnav_title.sb_col2 a {
     color: #ffffff;
 }

 // #subnav_title.sb_col2 a:hover{background-color: $btn-hover-color;}
 // column2 NO.02
 #subnav.sb_col2_accordion ul li a {
     border-color: #bbbbbb;
 }

 #subnav.sb_col2_accordion ul li a:hover {
     color: $main;
     background-color: #f9f9f9;
 }

 #subnav.sb_col2_accordion ul li.current a {
     border-color: #bbbbbb;
     color: $main;
     background-color: #f9f9f9;
 }

 #subnav.sb_col2_accordion ul li.current ul {
     border-color: #bbbbbb;
 }

 #subnav_title.sb_col2_accordion a {
     color: #ffffff;
 }

 // #subnav_title.sb_col2_accordion a:hover{background-color: $btn-hover-color;color: #ffffff;}
 // column1 01
 #subnav.sb_col1_1 ul li a:hover {
     background-color: #f7f7f7;
 }

 #subnav.sb_col1_1 ul li a::after {
     color: $main;
 }

 #subnav.sb_col1_1 ul li a {
     border-color: #f3f3f3;
 }

 #subnav.sb_col1_1 ul li.current a {
     background-color: #f7f7f7;
     &::after {
         color: $main;
     }
 }

 #subnav_title.sb_col1_1 a {
     background-color: #ffffff;
     color: $main;
 }

 #subnav_title.sb_col1_1 a::before {
     background-color: #dddddd;
 }

 @media only screen and (max-width: 690px) {
     #subnav.col1_1 ul li a::before,
     #subnav.col1_1 ul li.current a::before {
         color: $main;
     }
 }


 /* column1 02 ***/

 .sb_col1_2_wrap {
     background-color: #eeeeee;
 }

 #subnav_title {
     border-color: #cccccc;
     a:hover {
         color: $main;
     }
 }

 #subnav_list li {
     border-color: #cccccc;
     &.current a,
     a:hover {
         color: $main;
         text-decoration: none;
     }
 }

 // -----------------------------------------------------------------
 // blog_layout.css
 // -----------------------------------------------------------------

 /* common ***/

 time {
     color: #555555;
 }

 article#main {
     border: 0;
     padding: 0;
 }

 article#main a {
     color: $main;
     text-decoration: none;
     &:hover{
        text-decoration: underline;
     }
 }

 article#main .btn a,
 article#main .btn_bg a,
 article#main .catelist a {
     text-decoration: none;
 }

 article#main .taglist a {
     text-decoration: none;
     color: $main;
     &:hover {
         text-decoration: underline;
     }
 }

 article#main .btn_bg a,
 article#main .catelist a,
 article#main .taglist a,
 article#main .btn a:hover,
 article#main .btn_bg a:hover,
 article .catelist a:hover,
 article .taglist a:hover {
     // color: #ffffff;
 }

 article#main .dlb_btn a {
     // color: #ffffff;
 }


 /* article list ***/

 article {
     padding: 16px;
     border: 1px solid #d2d2d2;
     &+article {
         margin-top: 20px;
         &.relationlist {
             margin-top: 0;
         }
     }
     .info time {
         color: #000000;
     }
 }

 .cover img {
     max-width: none;
     width: 100%;
 }


 /* SNS ***/

 .sns_share {
     /*&.head{	border-color: #f5f5f5;}*/
     &.foot {
         border-color: #dddddd;
     }
     .line a {
         background-color: #00c300;
         color: #ffffff !important;
         text-decoration: none !important;
     }
 }


 /* next prev ***/

 #nextprev li a:hover {
     color: $accent-01;
 }


 /* relation ***/

 #relation .title {
    font-weight: bold;
    position: relative;
    border-top: 4px solid theme-color("gray-600");
    border-bottom: 1px solid #dddddd;
    padding-top: 10px;
    padding-bottom: 10px;
    box-sizing: content-box;
    font-weight: 700;
    &::before {
        content: "";
        position: absolute;
        width: 60px;
        height: 4px;
        background-color: $main;
        left: 0;
        top: -4px;
    }
 }

 .relationlist time {
     color: #777777;
 }


 /* article main title ***/


 /*BMの見出しとあわせてください。*/

 // article#main{
 // 	h2{border-color: $title-color;}
 // 	h3{border-color: $title-color;}
 // }

 /*------------------------------------------------

  blog_navi.css

  -------------------------------------------------*/


 /* category navi common header ***/

 .catenavi {
     border-color: #eeeeee;
     .catenavi_in li a {
         &:after {
             background-color: $main;
         }
         &:hover {
             color: $main;
         }
     }
 }


 /* search ***/

 .blog_search {
     border-color: #eeeeee;
     input {
         &.submit_btn {
             background-image: url(../image/common/icon_search.png);
             background-color: $main;
         }
     }
 }

 // -----------------------------------------------------------------
 // side
 // -----------------------------------------------------------------
 .side {
     .title {
         background-color: $main;
         color: #ffffff;
     }
     .cate li a {
         color: #ffffff;
         background-color: $accent-02;
         transition: 0.2s ease;
         &:hover {
             background-color: darken($accent-02, 10%);
         }
     }
     .tag li a {
         // background-image: url(../image/common/icon_sharp.png) ;
         color: $main;
         transition: 0.2s ease;
     }
     .btn {
        //  background-color: #fafafa;
        border: 1px solid $main;
        border-radius: 100vh;
         color: $main;
         &:hover {
             background-color: $main;
             color: #ffffff;
             span:before {
                 border-color: #ffffff;
             }
         }
     }
     .sweep {
         border-color: #ccc;
     }
 }


 /* blog list category ***/

 .catelist a {
     // border: 1px solid $color-03;
 }

 .taglist a {
     // color: $accent-01;
 }

 .taglist a {
     display: block;
     font-size: 0.875rem;
     line-height: 1.2;
     padding: 3px 6px 4px;
     border-radius: 0.25rem;
     font-weight: bold;
     transition: 0.2s ease;
     text-decoration: none;
     color: $main;
     &:hover {
         text-decoration: underline;
     }
 }

 .catelist a {
     display: block;
     font-size: 0.875rem;
     line-height: 1.2;
     padding: 3px 6px 4px;
     border-radius: 0.25rem;
     font-weight: bold;
     transition: 0.2s ease;
     color: #fff !important;
     background-color: $accent-02;
     &:hover {
         background-color: darken($accent-02, 10%);
     }
     &:before {
         color: $color-02;
     }
 }


 /* archive year month tag category list ***/

 .archivelist {
     .list {
         // &.tag {}
         // &.cate {}
         a {
             border-color: $color-01;
             color: #555555;
             &:hover,
             &.current {
                 background-color: $accent-03;
                 color: #ffffff;
             }
         }
     }
 }


 /* pager new old ***/

 .newold a,
 .pager a {
     color: $main;
 }

 .pager span {
     background-color: $accent-03;
     color: #ffffff;
 }

 // -----------------------------------------------------------------
 // common.css
 // -----------------------------------------------------------------

/* ぱんくず locator ***/

#locator_t_under,
#locator,
#locator_template_c2,
#locator_t_up {
    background-color: #f5f5f5;
    border-color: $color-01;
}


 // -----------------------------------------------------------------
 // sub.css
 // -----------------------------------------------------------------

 /* 特徴　強み　選ばれる理由 企業理念 など ***/

 // .col_vm_area{background-color: #f9f9f9;}
 // @media only screen and (max-width: 979px) {
 // 	.col_vm_area .col_vm_txt{background-color: #f9f9f9;}
 // }

 /* 企業理念 ***/

 .rinen {
     >p {
         background-color: #ffffff;
         &::after,
         &::before {
             background-color: #999999;
         }
     }
 }


 /*　faq QA　よくある質問 ***/

 .qa_q {
     color: #fff;
     background-color: $main;
 }

 .qa_click_q {
     background-color: #f5f5f5;
 }

 .qa_click_q::before {
     background-color: $main;
 }

 .qa_click_q::after {
     border-color: #ffffff;
 }

 .qa_click_q.active::after {
     border-color: #ffffff;
 }

 .qa_q_simple,
 .qa_a_simple {
     .init {
         color: $main;
         border-color: $main;
     }
 }

 .qa_a_simple {
     .init {
         color: #ffffff;
         border-color: $accent-01;
         background-color: $accent-01;
     }
 }

 @media only screen and (max-width: 690px) {
     .qa_click_q::after {
         border-color: $main;
     }
     .qa_click_q.active::after {
         border-color: $main;
     }
 }


 /*　サイトマップ ***/

 #sitemap a {
     color: #333333;
 }

 #sitemap li::before {
     color: #aaaaaa;
 }


 /* page contact ***/

 .colcontact {
     background-color: $color-01;
 }


 /* side contact ***/

 .sidecontact_in {
     background-color: $color-02;
 }


 /* form contact ***/

 .formflow li:after {
     border-color: $main;
 }

 .formflow li.prev {
     background-color: #dddddd;
 }

 .formflow li.age {
     background-color: $main;
 }

 .formflow li.next {
     background-color: $accent-01;
 }


 /* side search ***/

 .sub_search {
     .search_txt {
         border-color: #eeeeee;
     }
     .submit_btn {
         background-image: url('../image/common/icon_search.png');
     }
 }

 .sub_search input {
     &.search_txt {
         background-color: #f5f5f5;
         color: #777777;
     }
     &.submit_btn {
         background-color: #555555;
         background-image: url('../image/common/icon_search.png');
     }
 }

 .sm_b_block {
     background-color: rgba(162, 188, 14, 0.6);
 }


 /* submain area C ***/

 // .sm_c_area_txt{
 // 	background-color: #ffffff;
 // 	box-shadow: 5px 5px 10px -3px rgba(0, 0, 0, 0.15);
 // }

 /* submain area D ***/

 .col2_vm_v2 {
     background-color: #f5f5f5;
     .flt_vm {
         background-color: #ffffff;
         box-shadow: 5px 5px 10px -3px rgba(0, 0, 0, 0.15);
     }
 }


 /* sub area C TOPari ***/

 .sub_c_block:hover .sub_c_image::after {
     background-color: rgba(0, 0, 0, 0.7);
 }

 .sub_c_caption {
     color: #ffffff;
 }

 .sub_c_area .flt,
 .sub_c_area .flr {
     border-color: #ffffff;
 }

 @media only screen and (max-width: 979px) {
     .sub_c_caption {
         color: #000000;
     }
     .sub_c_block:hover .sub_c_image::after {
         background-color: rgba(0, 0, 0, 0);
     }
 }

 @media only screen and (max-width: 690px) {
     .sub_c_area .flt {
         border-color: #ffffff;
     }
 }


 /* sub area D ***/

 .sub_d_area .col {
     figcaption {
         background-color: #f9f9f9;
     }
     &::before {
         border-color: rgba(255, 255, 255, 0);
     }
     &.bl-hover::before {
         border-color: $main;
     }
 }

 .sub_d_area figcaption {
     .txt_link a {
         color: #000000;
     }
 }

 .sub_d_area_spyoko .col {
     figcaption {
         background-color: #f9f9f9;
     }
     &::before {
         border-color: rgba(255, 255, 255, 0);
     }
     &.bl-hover::before {
         border-color: $main;
     }
 }

 .sub_d_area_spyoko figcaption {
     .txt_link a {
         color: #000000;
     }
 }


 /* sub area U ***/

 // .sub_u_txt{
 // 	background-color: #f9f9f9;
 // 	border: 1px solid #ffffff;
 // 	box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.1);
 // }

 /* sub area W ***/

 // .sub_w_area{border: 2px solid #eeeeee;}
 // .sub_w_area.bl-hover{border-color: $main;}

 /* interview ***/

 .interview_txt {
     background-color: #ffffff;
     border-color: $main;
 }

 .interview_section>div.bl-hover .interview_txt {
     background-color: $main;
     color: #ffffff;
     border-bottom-color: #282f3a;
     a {
         color: #ffffff;
     }
 }

 .interview_section>div.bl-hover .img {
     &::before {
         background-color: $main;
     }
 }


 /* tab switch sub_i_area ***/

 // .tabswitch_list{
 // 	li{
 // 		a{
 // 			background-color: $main;
 // 			color: #ffffff;
 // 			border-color: $main;
 // 		}
 // 	}
 //     li.active a{
 // 		background-color: #ffffff;
 // 		color: $main;
 // 	}
 // }
 // .tabswitch_con{border-color: $main;}

 /* sub area K ***/

 // .sub_k_list li a:hover::before{border-color: $main;}

 /* sub area L ***/

 .sub_l_list li a:hover {
     &::before {
         border-color: $main;
     }
 }


 /* sub area O ***/

 .sub_o_area .col {
     border-color: #cccccc;
     &::before {
         border-color: $main;
     }
 }

 .sub_o_dl dt {
     color: $main;
     border-color: #dddddd;
 }

 @media only screen and (max-width: 690px) {
     .sub_o_dl dt {
         color: $main;
     }
 }


 /* sub area P ***/

 .sub_p_area_y>.col2_m>.col,
 .sub_p_area_t>.col2_m>.col {
     background-color: #f9f9f9;
     border-color: #cccccc;
     &::before {
         border-color: $main;
     }
 }


 /* sub area R ***/

 // .sub_r_area{
 // 	background-color: #ffffff;
 // 	&::after{box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);}
 // }
 // .sub_r_area_2{
 // 	background-color: #ffffff;
 // 	&::after,
 // 	&::before{box-shadow: 0 0 5px 10px rgba(0, 0, 0, 0.1);}
 // }
 // @media only screen and (max-width: 690px) {
 // 	.sub_r_area_2{
 // 		&::after,
 // 		&::before{box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);}
 // 	}
 // }

 /* sub area S ***/

 .sub_s_area {
     .col {
         background-color: rgba($main, 0.1);
     }
     .col:nth-child(2),
     .col:nth-child(3),
     .col:nth-child(6),
     .col:nth-child(7) {
         background-color: rgba($main, 0.3);
     }
 }

 @media only screen and (max-width: 690px) {
     .sub_s_area {
         .col,
         .col:nth-child(3),
         .col:nth-child(7) {
             background-color: rgba($main, 0.1);
         }
         .col:nth-child(2n) {
             background-color: rgba($main, 0.3);
         }
     }
 }

 .sub_s_num {
     background-color: $main;
     color: #ffffff;
 }


 /* point 01 ***/

 .point01 .point_name {
     background-color: $main;
     color: #ffffff;
 }


 /*** submain area G 1 ***/

 .sm_g_area {
     background-color: #f5f5f5;
 }

 .sm_g_block {
     background-color: #ffffff;
     border-top: 5px solid;
     border-image: linear-gradient(to right, #ff8008, #ffc837);
     border-image-slice: 100%;
     border-image-slice: 100%;
     border-image-slice: 1;
 }


 /*** submain area G 2 ***/

 .sm_g_area2 {
     background-color: #f5f5f5;
 }

 .sm_g_block2:before,
 .sm_g_block2:after {
     background: linear-gradient(linear, left right, #f00, #00f);
 }

 .sm_g_block2_in {
     background-color: rgba(255, 255, 255, 0.5);
     border-left: 1px solid #ff0000;
     border-right: 1px solid #0000ff;
 }

 @media only screen and (max-width: 690px) {
     .sub_h_area {
         .col,
         .col:nth-child(3),
         .col:nth-child(7) {
             background-color: rgba($main, 0.1);
         }
         .col:nth-child(2n) {
             background-color: rgba($main, 0.3);
         }
     }
 }


 /*** submain area i ***/

 .sm_i_area .col {
     &.bl-hover {
         background-color: #f7f7f7;
     }
     &::before {
         border-color: #dddddd;
     }
     &.bl-hover::before {
         border-color: $main;
     }
 }


 /* ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
  ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

      top new corporate 2

  ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
  ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ */

 .top_title_center_underbar_en,
 .top_title_left_underbar_en,
 .top_title_s_left_underbar_en {
     color: $main;
 }

 // -----------------------------------------------------------------
 // 製品詳細テンプレ
 // -----------------------------------------------------------------

 /*-- template item product btn -----------------------------------------*/

 .templateitem_btn_area {
     background-color: #eef7fc;
 }


 /* btn */

 .templateitem_btn li a {
     border: 1px solid #999999;
     background-color: #ffffff;
     &::after {
         border-top: solid 3px #58afe2;
         border-right: solid 3px #58afe2;
     }
     &:hover {
         border-color: #dddddd;
     }
 }


 /*-- template item product link -----------------------------------------*/


 /* link */

 .templateitem_link li a {
     background-color: #8a9395;
     border-right: solid 1px rgba(255, 255, 255, 0.5);
     border-bottom: solid 1px rgba(255, 255, 255, 0.5);
     color: #ffffff;
     &::after {
         border-top: solid 2px #ffffff;
         border-right: solid 2px #ffffff;
     }
     &:hover {
         border-color: #dddddd;
     }
 }


 /*-- template item product contents title -----------------------------------------*/

 .templateitemcon_title::after {
     background-color: #58afe2;
 }

 .templateitemcon_lead {
     color: #374252;
 }


 /*-- template item product contents 01 -----------------------------------------*/

 .templateitemcon01_area {
     background-color: #f7f8fb;
 }

 .templateitemcon01_catch {
     color: #374252;
 }


 /*-- template item product contents 10 -----------------------------------------*/

 .templateitemcon10_area {
     background-color: #c9d2d4;
 }

 .templateitemcon10_title {
     color: #4e5759;
 }

 .templateitemcon10_box::after {
     background-color: rgba(0, 0, 0, 0.5);
 }

 .templateitemcon10_txt {
     color: #ffffff;
     a {
         color: #ffffff;
         &[target='_blank']::before {
             // background: url(../image/icon_target.png) no-repeat center top / 20px auto;
         }
     }
 }

 .templateitemcon10_box:hover::after {
     background-color: rgba(0, 0, 0, 0.2);
 }

 @media only screen and (max-width: 979px) {
     .templateitemcon10_txt a[target='_blank']::after {
         // background: url(../image/icon_target.png) no-repeat center top / 14px auto;
     }
 }


 /*-- template item product contents 11 -----------------------------------------*/

 .templateitemcon11_box::after {
     background-color: rgba(0, 0, 0, 0.6);
 }

 .templateitemcon11_title {
     color: #ffffff;
 }

 .templateitemcon11_txt {
     color: #ffffff;
 }

 .templateitemcon11_btn a {
     color: #ffffff;
     border: 1px solid #ffffff;
     &::after {
         border-top: solid 2px #ffffff;
         border-right: solid 2px #ffffff;
     }
     &:hover {
         background-color: #ffffff;
         color: #374252;
         &::after {
             border-color: #374252;
         }
     }
 }


 /*-- header current -----------------------------------------*/

 .is-main_current>a {
     color: $main;
 }

 .navbar {
     .navbar-nav {
         .nav-item.is-main_current>a {
             color: $main;
         }
     }
 }
  /*-- 2023/06 テンプレートのページ追加改修 -----------------------------------------*/
  .tmpl_01_kv {
   &_slide {
     min-height: 700px;
     position: relative;
     display: flex;
     align-items: center;
     margin-bottom: 0;
     @media only screen and (max-width: 979px) {
       min-height: 600px;
     }
   }

   &_bg {
     img {
       width: 100%;
       height: 100%;
       object-fit: cover;
       position: absolute;
       top: 50%;
       left: 50%;
       transform: translate(-50%, -50%);
       z-index: -1;
     }
   }

   .swiper-pagination {
     bottom: 30px;
     left: 50%;
     transform: translate(-50%, -50%);
   }

   .swiper-wrapper {
     width: 100%;
     overflow: hidden
   }

   .swiper-slide {
     width: 100%;
     height: 100%
   }

   .swiper-pagination-bullet {
     width: 12px !important;
     height: 12px !important;
     margin: 0 0 0 10px !important;
     background-color: #fff;
     opacity: 1;

     &-active {
       background-color: #606060;
     }
   }

   .swiper-pagination-bullet::first-child {
     margin: 0 !important
   }
   .btn{
     @media only screen and (max-width: 979px) {
       margin-bottom:10px;
       margin-right: 0 !important;
       width: 100%
     }
   }

 }
 .tmpl_01_new{
   .bs_text-link {
     a{
       &::before{
         border-top: 1px solid $main;
         border-right: 1px solid $main;
       }
     }
   }
 }

 .tmpl_01_newlist dl {
   display: flex;
   border-left: 0;
   border-right: 0;
   border-bottom: solid 1px #dee2e6;
 }

.tmpl_01_newlist dl {
    flex-wrap: wrap;
    @include media-breakpoint-down(sm) {
        position: relative;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}

 .tmpl_01_newlist dl:first-child {
   border-top: solid 1px #dee2e6
 }

 .tmpl_01_newlist dl .rss_date {
   padding: 15px 0 0;
   max-width: 110px;
   color: #6c757d;
   text-align: left;
   -webkit-box-flex: 1;
   -ms-flex: auto;
   flex: auto
 }


.tmpl_01_newlist dl .rss_date {
    @include media-breakpoint-up(sm) {
        width: 15%;
    }
    @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
        flex: 0 0 6rem
    }
}
.tmpl_01_newlist dl .rss_title{
    display: flex;
    align-items: flex-start;
    @include media-breakpoint-up(sm) {
        width: 85%;
    }
 }
 .tmpl_01_newlist dl .rss_title a {
   color: $main;
 }

 .tmpl_01_newlist dl .rss_title a:hover {
//    opacity: .8;
   -webkit-transition: all ease .5s;
   transition: all ease .5s;
   text-decoration: none;
   color: $accent-02;
 }

 .tmpl_01_newlist dl .rss_title span {
   display: inline-block;
   margin-right: 20px;
   padding: 5px;
   min-width: 80px;
   color: #fff;
   text-align: center;
   background: $accent-02;
   border: solid 1px $accent-02;
   border-radius: 5px;
   line-height: 1;
 }


.tmpl_01_newlist dl .rss_title span {
    @include media-breakpoint-down(sm) {
        margin-right: 4%;
        min-width: 80px;
        position: absolute;
        top: 15px !important;
        left: 100px !important;
        font-size: 14px;
    }
}

 .tmpl_01_newlist dl .rss_title span:hover {
   color: $accent-02;
   border: solid 1px $accent-02;
   background: #fff;
   text-decoration: none;
   -webkit-transition: all ease .5s;
   transition: all ease .5s
 }

 .tmpl_01_newlist .rss_entire dl .rss_contents {
   padding-left: 7rem !important;
   width: 100%;
   display: block;
   margin-top: 0 !important;
 }


.tmpl_01_newlist .rss_entire dl .rss_contents {
    @include media-breakpoint-down(sm) {
        width: 100%;
        padding-left: 0 !important
    }
}

 .tmpl_01_newlist dl dd {
   padding: 12px 0
 }

 .bs_footer-04 .bs_footer-icon>div:nth-child(2) {
   margin-left: 2.5em
 }
 @include media-breakpoint-down(sm) {
   .bs_footer-04 .bs_footer-logo {
       margin:auto
   }
 }

 @media(max-width: 1120px) {
   .bs_footer-04 .bs_footer-icon>div:nth-child(2) {
       font-size:.875rem;
       margin-left: 2em
   }
 }

 @include media-breakpoint-down(sm) {
   .bs_footer-04 .bs_footer-icon>div:nth-child(2) {
       width:100%;
       margin: 10px auto 0;
       text-align: center;
       font-size: 1rem;
       justify-content: center;
   }
 }
 .bs_footer-04 .bs_footer-icon>div:nth-child(3) {
   margin-left: auto;
 }

 @media(max-width: 1199.98px) {
   .bs_footer-04 .bs_footer-icon>div:nth-child(3) {
       font-size:.875rem
   }
 }

 @media(max-width: 979px) {
   .bs_footer-04 .bs_footer-icon>div:nth-child(3) {
       width:100%;
       margin: 10px auto 0;
       font-size: 1rem
   }
 }

 .bs_footer-04 .bs_footer-logo {
   -webkit-box-flex: 0;
   -webkit-flex: 0 0 auto;
   -ms-flex: 0 0 auto;
   flex: 0 0 auto;
   width: auto
 }
.tmpl_01_page-hero{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-image: url("/dcms_media/image/pagetitle_bg.jpg");
    background-size: cover;
    background-position: 20% center;
     text-align: center;
    background-repeat: no-repeat;
    margin-bottom: 40px;
    font-weight: 700;
    @include media-breakpoint-up(md) {
        margin-bottom: 60px;
        min-height: 160px;
        background-position: left center;
    }
    @include media-breakpoint-up(lg) {
        margin-bottom: 80px;
        min-height: 220px;
    }
    &.__showcase,&.__edit{
        background-image: url("/dcms_media/image/pagetitle_bg-showcase.jpg");
    }
    &.__liveconnect,&.__system{
        background-image: url("/dcms_media/image/pagetitle_bg-live-connect.jpg");
    }
    &.__flumo{
        background-image: url("/dcms_media/image/pagetitle_bg-flumo.jpg");
    }
    &.__relaytruck{
        background-image: url("/dcms_media/image/pagetitle_bg-relay-truck.jpg");
    }
    &.__studio,&.__encode{
        background-image: url("/dcms_media/image/pagetitle_bg-studio.jpg");
    }
    &.__yourlive,&.__hosting{
        background-image: url("/dcms_media/image/pagetitle_bg-yourlive.jpg");
    }
}
.tmpl_01_page-hero_text{
    margin-bottom: 0;
}

 .dlb_list-decimal ol {
     padding: 0;
     list-style: none;
     counter-reset: number 0
 }
 .dlb_list-decimal ol>li {
     counter-increment: number 1;
     position: relative;
     padding-left: 20px;
 }
 .dlb_list-decimal ol>li::before {
     content: counter(number) ".";
     margin-right: 8px;
     position: absolute;
     left: 0;
     top: 0;
 }


 .tmpl_01_form #form_contact_main {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: 1150px;
  margin-bottom: 3rem;
  margin-top: 50px;
  & dl {
    padding: 16px 0;
    margin-bottom: 0;
    display: flex;
    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
    }
  }
  dt {
    float: none;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 250px;
    flex: 0 0 250px;

    @include media-breakpoint-down(md) {
      -webkit-box-flex:1;
      -ms-flex: 1 0 100%;
      flex: 1 0 100%
    }
    span {
      float: left;
      padding: 0 8px;
      margin-right: 8px;
      font-size: 12px;
      background: #ea3c3c;
      color: #fff;
      @include media-breakpoint-down(md) {
        margin-bottom:8px;
      }
      &.any {
        background-color: #777
      }
    }
    &::after {
      display: block;
      clear: both;
      content: ""
    }
  }
  dd {
    width: auto !important;
    float: none !important;
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
    margin-bottom: 0;
    p {
      display: inline-block;
      &+p {
        margin-left:10px;
       }
    }
  }
  input[type="text"] {
    font-size: 1rem;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    color: #777;
    min-height: 39px
  }
  select {
    padding: 0.5rem;
    font-size: 1rem;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    color: #777;
    width: 100%;
  }
  textarea {
    font-size: 1rem;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    color: #777
  }
  label {
    margin-bottom: 0
  }
    #submit_button {
      input {
        @include media-breakpoint-down(md) {
          width: 44%;
        }
      }

      .btn-secondary {
        color: #fff;
        background-color: #6c757d;
        border-color: #6c757d;
        border: 1px solid #6c757d;

        &:hover {
          color: #6c757d;
          background-color: #fff;
        }
      }
    }
 }

 .tmpl_01_form #form_contact_main dl.__last {
     border-top: 0;
     border-bottom: 1px solid #ccc;
     padding-bottom: 15px !important;
 }

.icon-bookmark {
    a {
        &:before {
            content: '';
            background: url(/dcms_media/image/common/icon-bookmark.png) 0 0 no-repeat;
            width: 24px;
            height: 24px;
            display: inline-block;
            margin-right: 6px;
            vertical-align: middle;
            background-size: contain;
        }
    }
    &:hover {
        a {
            &:before {
                background: url(/dcms_media/image/common/icon-bookmark-on.png) 0 0 no-repeat;
            }
        }
    }
}
.icon-book {
    a {
        &:before {
            content: '';
            background: url(/dcms_media/other/icon-book-white.svg) 0 center no-repeat;
            width: 24px;
            height: 24px;
            display: inline-block;
            margin-right: 6px;
            vertical-align: middle;
            background-size: contain;
        }
    }
    &:hover {
        background-color: #fff;
        a {
            color: $accent-01;
            &:before {
                background-image: url(/dcms_media/other/icon-book-green.svg);
            }
        }
    }
}
.icon-mail {
    a {
        &:before {
            content: '';
            background: url(/dcms_media/other/icon-mail-white.svg) 0 center no-repeat;
            width: 24px;
            height: 24px;
            display: inline-block;
            margin-right: 6px;
            vertical-align: middle;
            background-size: contain;
        }
    }
    &:hover {
        background-color: #fff;
        a {
            color: $accent-02;
            &:before {
                background-image: url(/dcms_media/other/icon-mail-blue.svg);
            }
        }
    }
}

 .max-760{
  max-width: 760px;
 }
 #form_contact_main dt{
  font-weight: normal;
 }
 @media(max-width: 979px) {
  .tmpl_01_strike .table-striped {
    min-width: 900px;
  }
 }
 .bs_footer-logo {
  a{
    &:hover{
      opacity: 0.8;
    }
  }
 }
 .share_top-link{
  overflow: visible;
 }
 .tmpl_01_fax{
  pointer-events: none;
  color: $color-body;
  a{
    pointer-events: none;
    color: $color-body;
  }
 }
 /*-- 2023/06 テンプレートのページ追加改修 -----------------------------------------*/
/*-- 2023/09 サイト内検索改修 -----------------------------------------*/
.sub_search {
    max-width: 690px;
    input {
    &.search_txt {
        background-color: #fff;
        color: #777;
        border: 1px solid #ced4da;
        padding: 0.5em;
        width: calc(100% - 120px - 15px);
        height: 40px;
        line-height: 40px;
        border-radius: 5px;
        margin-right: 15px;
        &::placeholder {
            color:#979797;
        }
        @include media-breakpoint-down(sm) {
            width: calc(100% - 120px - 5px);
            margin-right: 5px;
        }
    }
    &.submit_btn {
        background-color: #F8F9FA;
        padding: 0.5em 10px;
        border: 1px solid #CED4DA;
        border-radius: 5px;
        background-image: none;
        color: #333!important;
        height: 40px;
        width: 120px;
        float: right;
        &:hover {
            opacity:0.7;
        }

    }
}
}
/*-- 2023/09 サイト内検索改修 -----------------------------------------*/
/*-- 2023/10 QC指摘修正 -----------------------------------------*/

.table-responsive {
    table {
        min-width: 100%;
        @include media-breakpoint-down(md) {
            width: 750px !important;
        }
    }
}
.dlb_news_pickup {
    .rss_date {
        font-size: 14px;
        @include media-breakpoint-up(sm) {
                width: 15%;
            }
        @include media-breakpoint-down(sm) {
            padding: 10px 0 0 0 !important;
        }
        @include media-breakpoint-down(sm) {
            text-align: left !important;
        }
        @include media-breakpoint-down(lg) {
            font-size: 13px;
        }
    }


    .dlb_news-list {
        dl{
            @include media-breakpoint-down(md) {
               border-radius: 0;
            }
        }
        .rss_title{
            a{
                align-items: flex-start;
            }
            @include media-breakpoint-down(sm) {
               min-width: 100%;
             }
             @include media-breakpoint-up(sm) {
                width: 85%;
            }
        }
        .list-group-item{
            .rss_title{
                 span{
                    &.rss_news{
                        @include media-breakpoint-down(sm) {
                            top:-23px !important;
                        }
                    }
                 }
            }
        }
    }
}
.dlb_news_blog {
    .rss_date {
        @include media-breakpoint-down(sm) {
            padding: 15px 0 0 0 !important;
        }
        @include media-breakpoint-down(sm) {
            text-align: left !important;
        }
    }

    .dlb_news-list {
        dl{
            @include media-breakpoint-down(md) {
               border-radius: 0;
            }
        }
        .rss_title{
            @include media-breakpoint-down(sm) {
               min-width: 100%;
             }
        }

    }
}
.dlb_news_column{
    .rss_title{
        margin-top: 5px;
    }
    .dlb_news-list dl {
        flex-wrap: wrap;
    }
}
.dlb_news-list{
    .rss_title{
        a{
            &:hover{
                text-decoration: none;
                opacity: 0.7;
            }
        }
    }
}
.btn-dark {
    color: #fff !important;
    background-color: $gray-900 !important;
    border-color: $gray-900 !important;

    &:hover {
        color: $gray-900 !important;
        background-color: #fff !important;
    }
}

.btn-outline-dark {
    color: $gray-900 !important;
    border-color: $gray-900 !important;

    &:hover {
        color: #fff !important;
        background-color: $gray-900 !important;
    }
}
.dlb_news_blog {
    .dlb_news-blog {
        .dlb_blog-item:hover {
            opacity: 0.7;
        }
    }
}
.dlb_blog_column-4 {
    .dlb_blog-data {
        a:hover {
            opacity: 0.7;
        }
    }
}

.dlb_blog_column_image-left {
    // .dlb_blog-data.stretched-link:hover {
    //     opacity: 0.7;
    // }
    .dlb_blog-item {
        .dlb_blog-data{
            a{
                &:hover{
                    text-decoration: none;
                    .dlb_title-h3 {
                        color: $accent-02;
                    }
                }
                >*{
                    color: $color-p;
                }
            }
        }
        .dlb_title-h3{
            max-height: none !important;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            font-size: 1.125rem !important;
            margin-bottom: 10px !important;
            color: $color-p;
        }
    }
}
.dlb_news_blog .dlb_news-list .dlb_blog-data div {
    @include media-breakpoint-up(lg) {
        position: relative !important;
        margin-left: 1rem;
        margin-bottom: 1rem;
    }
}
.text-shadow {
    text-shadow: 2px 2px 8px rgba(#000000, 0.3) ,
    -2px 2px 8px rgba(#000000, 0.3) ,
    2px -2px 8px rgba(#000000, 0.3) ,
    -2px -2px 8px rgba(#000000, 0.3);
}
.dlb_table_stack {
    table {
        th, td {
            @include media-breakpoint-down(sm) {
                display: block;
                width: 100%;
            }
        }
    }
}
.dlb_btn_card_column-4 {
    .shadow:hover {
        box-shadow:0 0.25rem 0.6rem rgba(0,0,0,.4) !important;
    }
}
.dlb_btn_link-text {
    .shadow:hover {
        box-shadow: 0 0.25rem 0.5rem rgba(0,0,0,.4) !important;
    }
    .stretched-link{
        background-color: #fff;
        a{
            justify-content: space-between;
        }
    }
}

.dlb_contents_float {
    figure.float-sm-right {
        @media only screen and (min-width: 576px) {
            margin-left:1.5rem !important;
        }
    }
}
 .dlb_blog_column-4 .dlb_blog-data p {
  max-height: none !important;
 }

 .dlb_btn_link_list{
    .btn-white {
        &:hover {
           background-color: $main;
           a {
           color:#ffffff;
           }
        }

    }
 }

 .dlb_news-list dl {
   display: flex;
   border-left: 0;
   border-right: 0;
   border-bottom: solid 1px #dee2e6;
 }

.dlb_news-list dl {
    @include media-breakpoint-down(sm) {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        position: relative;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}

 .dlb_news-list dl:first-child {
   border-top: solid 1px #dee2e6
 }

 .dlb_news-list dl .rss_date {
   padding: 15px 0 0;
   max-width: 110px;
//    color: #6c757d;
   text-align: left;
   -webkit-box-flex: 1;
   -ms-flex: auto;
   flex: auto
 }


.dlb_news-list dl .rss_date {
    @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
        flex: 0 0 6rem
    }
}
.dlb_news-list dl .rss_title{
    display: flex;
    align-items: flex-start;
    position: relative;
 }
 .dlb_news-list dl .rss_title a {
   color: $main;
   display: flex;
   align-items: flex-start;
   color: $accent-02;
 }

 .dlb_news-list dl .rss_title a:hover {
//    opacity: .8;
   transition: all ease .2s;
   text-decoration: underline;
   color: $accent-02;
 }

 .dlb_news-list dl .rss_title span {
   display: inline-block;
   margin-right: 20px;
   padding: 5px;
   min-width: 80px;
   color: #fff;
   text-align: center;
   background: $accent-02;
   border: solid 1px $accent-02;
   border-radius: 5px;
   line-height: 1;
 }


.dlb_news-list dl .rss_title span {
    @include media-breakpoint-down(sm) {
        margin-right: 0;
        min-width: 80px;
        position: absolute;
        top: -25px !important;
        left: 90px !important;
        font-size: 14px;
    }
}

 .dlb_news-list dl .rss_title span:hover {
   color: $accent-02;
   border: solid 1px $accent-02;
   background: #fff;
   text-decoration: none;
   -webkit-transition: all ease .5s;
   transition: all ease .5s
 }

 .dlb_news-list .rss_entire dl .rss_contents {
   padding-left: 7rem !important;
   width: 80%;
   display: block;
   margin-top: 0 !important;
 }


.dlb_news-list .rss_entire dl .rss_contents {
    @include media-breakpoint-down(sm) {
        width: 100%;
        padding-left: 0 !important
    }
}

 .dlb_news-list dl dd {
   padding: 12px 0;
 }

/*-- 2023/10 QC指摘修正 -----------------------------------------*/

.rss_paging_container {
  display: flex;
  align-items: center;
  justify-content: right;
  flex-wrap: wrap;
}
.js-rss_paging_text{
  width: 100%;
  margin-bottom: 10px;
  letter-spacing: .1em;
}
.rss_paging_container span {
  padding: 5px;
  display: block;
  margin: 0 5px;
  text-align: right;
}
.rss_paging_container span.rss_paging_button {
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 1;
  color: #000000;
}
.rss_paging_container span:not(.rss_paging_button_unclickable).rss_paging_button:hover,
.rss_paging_button_current_page {
  color: #fff !important;
  background-color: #000000;
}
.js-rss_paging_text{
    margin-bottom: 10px;
}



.img-fluid-max{
    img{
        @include media-breakpoint-down(md) {
            width: 100%;
            height: auto;
        }
    }
}

.dlb_list-decimal li,
.dlb_list-disc li {
    margin-top: 8px;
    &:first-child {
        margin-top: 0;
    }
}

.__footernav{
    li a{
        font-size: .875rem;
        padding-left: 30px;
    }
    @include media-breakpoint-up(lg) {
        .__sho{
            flex: 0 0 23%;
            max-width: 23%;
        }
        .__mid{
            flex: 0 0 27%;
            max-width: 27%;
        }
        .__long{
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
    .bs_text-link{
        a{
            padding-right: 0;
        }
    }
}

.__cta{
    .btn{
        a{
            padding: 15px 5px;
        }
    }
    .img-fluid-max{
        img{
            max-width: 108px;
        }
    }
    .__num a{
        @include media-breakpoint-down(lg) {
            font-size: 2.2em;
            font-weight: 700;
            line-height: 1;
            color: $color-p;
        }
    }
    .__num span{
        @include media-breakpoint-up(lg) {
            font-size: 2em;
            font-weight: 700;
            line-height: 1;
            color: $color-p;
        }
    }
}

.__mb-0{
    margin-bottom: -3rem;
    @include media-breakpoint-up(lg) {
        margin-bottom: -5rem;
    }
}


.__stab{
    td:first-child{
        font-weight: 700;
        padding-right: 2.5rem;
    }
    td{
        padding: 5px 0 5px 0;
        @include media-breakpoint-down(md) {
            display: block;
        }
    }
}


.mH.__center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.__pricetab{
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    th{
        @include media-breakpoint-down(sm) {
            border-bottom: none;
            width: 100%;
            display: block;
            &.__sp-none{
                padding: 10px 0 0;
            }
        }
        @include media-breakpoint-up(sm) {
            width: 80%;
        }
    }
    td{
        text-align: right;
        @include media-breakpoint-down(sm) {
            border-top: none;
            padding-top: 0;
            width: 100%;
            display: block;
        }
        @include media-breakpoint-up(sm) {
            vertical-align: middle;
            width: 20%;
            border-left: 1px solid #ccc;
        }
    }
}

.__iframe {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
}
.__iframe iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}



// original -------------------------

.p-pagelink{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding-left: 0;
    margin-bottom: 0;
    @include media-breakpoint-up(md) {
        gap: 5%;
        grid-template-columns: repeat(4, 1fr);
    }
    li{
        list-style: none;
        padding-bottom: 15px;
        border-bottom: solid 1.5px $accent-02;
        @include media-breakpoint-down(sm) {
            &:nth-child(n+3){
                margin-top: 10px;
            }
        }
        a{
            color: $main;
            position: relative;
            padding-right: 10px;
            font-weight: 700;
            font-size: 1.125rem;
            display: block;
            &::after{
                content: '';
                width: 10px;
                height: 10px;
                display: block;
                border-top: solid 2px;
                border-right: solid 2px;
                border-color: $accent-02;
                transform: rotate(135deg);
                position: absolute;
                right: 5px;
                top: 6px;
            }
            &:hover{
                color: $accent-02;
                text-decoration: none;
            }
        }
    }
}

.p-era{
    text-align: center;
    font-weight: 600;
    font-size: 2.25rem;
    line-height: 1 !important;
    margin-bottom: 0 !important;
    @include media-breakpoint-up(md) {
        font-size: 3rem;
    }
    @include media-breakpoint-up(lg) {
        font-size: 4.375rem;
    }
}

.p-chronology{
    @include media-breakpoint-up(md) {
        background: linear-gradient(90deg, $accent-03 0%, $accent-03 50%, #F7FFE5 50%, #F7FFE5 100%);
    }
    &__tab{
        @media only screen and (max-width: 767px) {
            display: block;
            width: 100%;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
        }
        table{
            width: 100%;
            @media only screen and (max-width: 767px) {
                width: 500px;
                background: linear-gradient(90deg, $accent-03 0%, $accent-03 50%, #F7FFE5 50%, #F7FFE5 100%);
            }
            tr{
                &:first-child{
                    th,td{
                        border-top: none;
                    }
                }
            }
            th,td{
                border-top: 1px solid #ccc;
                font-weight: 600;
                padding: 20px 10px;
                @include media-breakpoint-up(lg) {
                    padding: 30px 20px;
                }
            }
            th{
                vertical-align: middle;
                width: 45%;
                text-align: center;
                font-size: 1.5rem;
                @include media-breakpoint-up(md) {
                    width: 43%;
                }
                @include media-breakpoint-down(lg) {
                    font-size: 1.25rem;
                }
                &.__left{
                    color: $accent-02;
                }
                &.__right{
                    color: $accent-01;
                }
            }
            td{
                vertical-align: top;
                text-align: left;
                @include media-breakpoint-between(md, lg){
                    &:first-child{
                        padding-left: 0;
                    }
                    &:last-child{
                        padding-right: 0;
                    }
                }
                ul{
                    margin-bottom: 0;
                }
                li+li{
                    margin-top: 8px;
                }
            }
            .__center{
                vertical-align: middle;
                background-color: theme-color("gray-800");
                color: #fff;
                text-align: center;
                width: 10%;
                font-weight: 700;
                line-height: 1.3;
                letter-spacing: .05em;
                @include media-breakpoint-up(md) {
                    font-size: 1.25rem;
                    width: 14%;
                }
                @include media-breakpoint-up(lg) {
                    font-size: 1.75rem;
                }
            }
        }
    }
}

.p-top-news{
    .rss_contents{
        display: none;
    }
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active,
.nav-tabs .nav-link:hover {
    color: #fff;
    background-color: #222;
    border-color: #222 #222 #fff;
}
.nav-tabs .nav-item {
    &+.nav-item{
        @include media-breakpoint-up(sm) {
            margin-left: 2px;
        }
    }
    @include media-breakpoint-down(sm) {
        width: 100%;
        .nav-link{
            border-radius: 0;
        }
    }
}
.nav-tabs .nav-link {
    border: 1px solid #222;
    border-bottom: 0;
    color: $color-p;
    font-weight: 600;
    @include media-breakpoint-up(lg) {
        font-size: 1.375rem;
    }
}

.p-top-mv{
    background: url("/dcms_media/image/top-mv-bg.jpg") no-repeat center;
    background-size: cover;
    position: relative;
    // max-width: 2000px;
    margin-left: auto;
    margin-right: auto;
    &--content{
        padding: 10vw 0 13vw;
        position: relative;
        z-index: 2;
        @include media-breakpoint-up(md) {
            padding: 7vw 0;
        }
        @include media-breakpoint-up(xl) {
            padding: 4vw 0;
        }
        &::after{
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            background-image: linear-gradient(90deg, rgba(0, 117, 191, 0.8), rgba(46, 218, 135, 0.8));
            clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);;
            top: 0;
            left: 0;
            @include media-breakpoint-up(md) {
                width: 80%;
                clip-path: polygon(0 0, 100% 0%, 85% 100%, 0% 100%);
            }
            @include media-breakpoint-up(xl) {
                width: 70%;
            }
        }
        .__title{
            font-weight: 600;
            line-height: 1.4;
            margin: 10px 0;
            color: #fff;
            text-shadow: 2px 3px 3px rgba(0, 0, 0, .15);
            @include media-breakpoint-down(sm) {
                font-size: 1.5rem;
                br{display: none;}
            }
            @include media-breakpoint-up(sm) {
                font-size: 2rem;
            }
            @include media-breakpoint-up(lg) {
                font-size: 2.25rem;
            }
            @include media-breakpoint-up(xl) {
                font-size: 2.5rem;
            }
        }
        .__sub{
            font-weight: 500;
            color: #fff !important;
            margin-bottom: 0;
            text-shadow: 2px 3px 3px rgba(0, 0, 0, .15);
            @include media-breakpoint-down(sm) {
                font-size: .875rem;
            }
        }
        .__inner{
            max-width: 1120px;
            position: relative;
            z-index: 1;
            @include media-breakpoint-down(md) {
                width: 90%;
                margin-left: auto;
                margin-right: auto;
            }
            @include media-breakpoint-up(lg) {
                margin-left: calc((100% - 960px) / 2);
            }
            @include media-breakpoint-up(xl) {
                margin-left: calc((100% - 1120px) / 2);
            }
        }
        .__link{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 5px;
            max-width: 460px;
            padding-left: 0;
            margin-bottom: 0;
            margin-top: 20px;
            @include media-breakpoint-up(sm) {
                gap: 10px;
                margin-top: 30px;
            }
            @include media-breakpoint-up(md) {
                margin-top: 40px;
                max-width: 620px;
                grid-template-columns: repeat(3, 1fr);
            }
            li{
                list-style: none;
                a{
                    display: flex;
                    align-items: center;
                    background-color: #fff;
                    border-radius: 100vh;
                    padding: 10px;
                    font-weight: 600;
                    border: 1.5px solid #ccc;
                    transition: all .2s;
                    font-size: .875rem;
                    @include media-breakpoint-up(sm) {
                        font-size: 1rem;
                        padding: 10px 15px 10px 20px;
                    }
                    &:hover{
                        color: #fff !important;
                        text-decoration: none;
                        &::after {
                            border-top-color: #fff !important;
                            border-right-color: #fff !important;
                        }
                    }
                    &::after{
                        content: "";
                        width: 8px;
                        height: 8px;
                        min-width: 8px;
                        margin-left: auto;
                        border-top: 2px solid #222;
                        border-right: 2px solid #222;
                        transform: rotate(45deg);
                    }
                }
                &.__showcase a{
                    color: #C61723;
                    border-color: #C61723;
                    &::after {
                        border-top-color: #C61723;
                        border-right-color: #C61723;
                    }
                    &:hover{
                        background-color: #C61723;
                    }
                }
                &.__connect a{
                    color: #0075BF;
                    border-color: #0075BF;
                    &::after {
                        border-top-color: #0075BF;
                        border-right-color: #0075BF;
                    }
                    &:hover{
                        background-color: #0075BF;
                    }
                }
                &.__yourlive a{
                    color: #90C322;
                    border-color: #90C322;
                    &::after {
                        border-top-color: #90C322;
                        border-right-color: #90C322;
                    }
                    &:hover{
                        background-color: #90C322;
                    }
                }
                &.__studio a{
                    color: #DD540F;
                    border-color: #DD540F;
                    &::after {
                        border-top-color: #DD540F;
                        border-right-color: #DD540F;
                    }
                    &:hover{
                        background-color: #DD540F;
                    }
                }
                &.__truck a{
                    color: #656766;
                    border-color: #656766;
                    &::after {
                        border-top-color: #656766;
                        border-right-color: #656766;
                    }
                    &:hover{
                        background-color: #656766;
                    }
                }
                &.__flumo a{
                    color: #E42187;
                    border-color: #E42187;
                    &::after {
                        border-top-color: #E42187;
                        border-right-color: #E42187;
                    }
                    &:hover{
                        background-color: #E42187;
                    }
                }
            }
        }
    }
    &--img{
        width: 70%;
        margin: auto 0 auto auto;
        @include media-breakpoint-down(md) {
            max-width: 400px;
            position: relative;
            top: -60px;
            margin-bottom: -60px;
        }
        @include media-breakpoint-up(md) {
            position: absolute;
            top: 0;
            right: 0;
            text-align: right;
            height: 100%;
            width: 50%;
        }
        @include media-breakpoint-up(lg) {
            width: 40%;
        }
        img{
            width: 100%;
            height: auto;
            @include media-breakpoint-up(md) {
                width: auto;
                height: 100%;
                object-fit: contain;
            }
        }
    }
}